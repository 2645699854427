import React from 'react';
import './CustomToolTip.css';
export default function CustomToolTip({
  children,
  element,
  style,
  position,
  tooltipContainerStyle,
}) {
  return (
    <div id={'custom-toolTip-container'} style={tooltipContainerStyle}>
      {element && (
        <div className={'custom-toolTip ' + position} style={style || {}}>
          {element}
          <div className={'custom-toolTip-index ' + position}></div>
        </div>
      )}
      <span className={'custom-toolTip-content'}>{children} </span>
    </div>
  );
}
