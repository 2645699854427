import { API_URL, CLIENT_ID_HUBSPOT, DASHBOARD_URL, headers, TOKEN } from '../../constants';
import { convertToJsonAndHandleErrors, getToken } from '../utils';

export async function integrationAuthConnect({ code, apiKey, integrationName, domain }) {
  const auth = {};
  if (code) auth.code = code;
  else auth.apiKey = apiKey;
  return fetch(`${API_URL}/integrations/${integrationName}`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN()}`,
    },
    body: JSON.stringify({
      ...auth,
      clientId: CLIENT_ID_HUBSPOT,
      redirectUri: `${DASHBOARD_URL}/socialAuth/${integrationName}/`,
      domain: domain,
    }),
    json: true,
  }).then(convertToJsonAndHandleErrors);
}

export const integrationDeleteAccount = ({ integrationName }) => {
  return fetch(`${API_URL}/integrations/${integrationName}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getIntegrationProperties = ({ integrationName, sObject }) => {
  let url = `${API_URL}/integrations/${integrationName}/properties`;
  if (sObject) url += '?sObject=' + sObject;
  return fetch(url, {
    method: 'Get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const mappingIntegrationProperties = ({ mappedProperties, integrationName, sObject }) => {
  return fetch(`${API_URL}/integrations/${integrationName}/mapping`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ properties: mappedProperties, sObject }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const resetMappingIntegration = ({ integrationName }) => {
  return fetch(`${API_URL}/integrations/${integrationName}/mapping/reset`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getIntegrationsLists = ({ integrationName, getAll }) => {
  return fetch(`${API_URL}/integrations/${integrationName}/lists/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ getAll }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const addLeadsIntegration = (lead) => {
  return fetch(`${API_URL}/integrations/${lead.integrationName}/leads`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(lead),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getEmails = ({ integrationName }) => {
  return fetch(`${API_URL}/integrations/${integrationName}/getmessages`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const getAccounts = () => {
  return fetch(`${API_URL}/integrations/accounts`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export async function getIntegrationKey(integrationName) {
  return fetch(`${API_URL}/integrations/${integrationName}/getKey`, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN()}`,
    },
    json: true,
  }).then(convertToJsonAndHandleErrors);
}

export const updateIntegrationKey = (integrationName) => {
  return fetch(`${API_URL}/integrations/${integrationName}/updateKey`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const updateZap = (zap) => {
  return fetch(`${API_URL}/integrations/zapier/zap`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ zap }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteZap = (zap) => {
  return fetch(`${API_URL}/integrations/zapier/zap`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ zap }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const createEmailTemplate = ({ template, subject, title }) => {
  return fetch(`${API_URL}/integrations/emailTemplate/create`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ template, subject, title }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getEmailTemplates = ({}) => {
  return fetch(`${API_URL}/integrations/emailTemplate/list`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const updateEmailTemplate = ({ _id, template, subject, title }) => {
  return fetch(`${API_URL}/integrations/emailTemplate/update`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ _id, template, subject, title }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteEmailTemplate = ({ template }) => {
  return fetch(`${API_URL}/integrations/emailTemplate/delete`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ template }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const sendEmail = (emailOptions, lead) => {
  return fetch(`${API_URL}/integrations/gmail/sendEmail`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ emailOptions, lead }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const saveEmailSignature = ({ signature }) => {
  return fetch(`${API_URL}/integrations/emailTemplate/signatures`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ signature }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getEmailSignature = () => {
  return fetch(`${API_URL}/integrations/emailTemplate/signatures`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
