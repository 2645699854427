import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import { LAUNCHDARKLY_CLIENT_SIDE_ID } from './constants';

const LDProvider = ({ children, userEmail }) => {
  const LDProviderWithConfig = withLDProvider({
    clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    context: {
      kind: 'user',
      key: userEmail,
      anonymous: false,
    },
  })(() => <>{children}</>);

  return <LDProviderWithConfig />;
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.profile?.email || null, // get user email from redux state
  };
};

export default connect(mapStateToProps)(LDProvider);
