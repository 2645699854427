import { convertToJsonAndHandleErrors, getToken } from '../utils';
import { API_URL } from '../../constants';

export const getSynchronizedAccount = () => {
  return fetch(`${API_URL}/linkedin/sessions/getSynchronizedAccount`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const desynchronizeLinkedinAccount = () => {
  return fetch(`${API_URL}/linkedin/sessions/desynchronizeAccount`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
