import React, { useEffect, useState } from 'react';
import { createWorkflow, getTemplates, getWorkflow } from '../../../../services/api/workflows.api';
import { InfoToolTip, Tooltip } from '../../../../components';
import { OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import allIntegrations from '../allIntegrations.json';
import {
  addLeadsIntegration,
  getAccounts,
  getIntegrationsLists,
} from '../../../../services/api/integrations.api';
import { checkPermission } from '../../../../services/utils';
import { toast } from 'react-toastify';

function Branch({
  blockOptions,
  onClick,
  active,
  showSetTimeText,
  textSwitch,
  isMessage,
  isFirst,
}) {
  return (
    <div className="workflow-tree-lines ">
      <div
        className={
          'workflow-loader-icon timer-ui ' +
          (active ? 'active ' : '') +
          (isFirst ? 'attach-column' : '')
        }
        style={{ zIndex: active ? 9 : 8 }}
        onClick={() => onClick(blockOptions)}
      >
        <span className="loader-icon-inner">
          <img src="/assets/images/ic_time_limit.svg" alt="" />
        </span>
      </div>
      {showSetTimeText && (
        <span className="time-duration">
          <FormattedMessage id={'workflow.setTimeDuration'} />
        </span>
      )}
      {textSwitch ? (
        <div className="branch-ui" style={{ left: 15 }}>
          <div className="branch-text">
            <span className="icon check-icon">
              <img src="/assets/images/ic_done_statement.svg" alt="" />
            </span>
            {isMessage ? (
              <FormattedMessage id={'workflow.response'} />
            ) : (
              <FormattedMessage id={'workflow.accepted'} />
            )}
          </div>
          <div className="branch-text">
            {isMessage ? (
              <FormattedMessage id={'workflow.noResponse'} />
            ) : (
              <FormattedMessage id={'workflow.notAccepted'} />
            )}
            <span className="icon cross-icon">
              <img src="/assets/images/ic_cross_statement.svg" alt="" />
            </span>
          </div>
        </div>
      ) : (
        <div className="branch-ui">
          <div className="branch-text">
            {isMessage ? (
              <FormattedMessage id={'workflow.noResponse'} />
            ) : (
              <FormattedMessage id={'workflow.notAccepted'} />
            )}
            <span className="icon cross-icon">
              <img src="/assets/images/ic_cross_statement.svg" alt="" />
            </span>
          </div>
          <div className="branch-text">
            <span className="icon check-icon">
              <img src="/assets/images/ic_done_statement.svg" alt="" />
            </span>
            {isMessage ? (
              <FormattedMessage id={'workflow.response'} />
            ) : (
              <FormattedMessage id={'workflow.accepted'} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function Block({ active, onClick, hideLimit, icon, blockOptions }) {
  const steps = {
    1: 1,
    5: 2,
    8: 3,
    17: 4,
    9: 5,
    11: 6,
  };
  return (
    <div className="one-workflow-block">
      <div className="workflow-container">
        <div className="workflow-line"></div>
        <div
          className={'workflow-content ' + (active ? 'active' : '')}
          onClick={() => onClick(blockOptions)}
          style={!blockOptions.active ? { opacity: 0.4, background: 'rgb(255,150,150)' } : {}}
        >
          <div className="icon-block">
            <img src={'/assets/images/' + icon} alt="" />
          </div>
          <div className="content-block">
            <p>{blockOptions && blockOptions.name} </p>
            {hideLimit ? (
              <a href="#">
                <FormattedMessage id={'workflow.addMessageAndLimit'} />
              </a>
            ) : (
              <span>
                <FormattedMessage id={'workflow.dailyLimit'} />{' '}
                {blockOptions && blockOptions.options.batchLimit}{' '}
              </span>
            )}
          </div>
          <div className="tooltip-box">
            <p>
              <FormattedMessage id={'step'} /> {blockOptions && steps[blockOptions.id]} ·{' '}
              <FormattedMessage id={'editThis'} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AttachBlock({ active, blockOptions, onClick }) {
  const [attachedBlock, setAttachedBlock] = useState(null);
  const [icon, setIcon] = useState(null);
  useEffect(() => {
    if (blockOptions.options && blockOptions.options.workflowId) {
      getWorkflow(blockOptions.options.workflowId).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          if (data.workflow && data.workflow.type === 'enrichment') {
            const block = data.workflow.blocks.find((block) => block.action === 'linkedin-enrich');
            block.title = 'Enrichment';
            block.attached = 'attached-block';
            setAttachedBlock(block);
            setIcon('ic_workflow_active.png');
          } else if (data.workflow && data.workflow.type === 'integration') {
            const block = data.workflow.blocks.find((block) => block.type === 'flow_action');
            if (block) {
              block.title = 'Integration';
              block.attached = 'attached-block';
              setAttachedBlock(block);
              setIcon('ic_workflow_active.png');
            }
          }
        }
      });
    } else {
      setAttachedBlock(null);
    }
  }, [blockOptions.options && blockOptions.options.workflowId]);

  if (attachedBlock) {
    return (
      <div className="one-workflow-block">
        <div className="workflow-container">
          <div className="workflow-line"></div>
          <div
            className={'workflow-content ' + (active ? 'active' : '')}
            onClick={() => onClick(attachedBlock)}
          >
            <img style={{ marginRight: 10, width: 25 }} src={'/assets/images/' + icon} alt="" />
            <div className="content-block">
              <p>{attachedBlock && attachedBlock.title} </p>
              <span>{attachedBlock && attachedBlock.name} </span>
            </div>
            <div className="tooltip-box">
              <p>
                {' '}
                <FormattedMessage
                  id={'workflow.stepEditThis'}
                  values={{ value: blockOptions && blockOptions.id }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="one-workflow-block">
      <div className="workflow-container">
        <div className={'workflow-line hide-arrow'}></div>
        <div
          className={'attach-more ' + (active ? 'active' : '')}
          onClick={() => onClick(blockOptions)}
        >
          <span className={'attach-icon'}>
            <img src="/assets/images/ic_add_workflow.png" alt="" />
          </span>
          <p>
            <FormattedMessage id={'workflow.attachMore'} />
          </p>
        </div>
      </div>
    </div>
  );
}

function NumberField({ value, onChange }) {
  const [currentValue, setCurrentValue] = useState(1);
  const handleOnChange = (e) => {
    onChange(currentValue);
  };
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, []);
  const handleNext = () => {
    if (currentValue < 70) {
      setCurrentValue(currentValue + 1);
      onChange(currentValue + 1);
    } else {
      setCurrentValue(currentValue);
    }
  };
  const handlePrevious = () => {
    if (currentValue > 1) {
      setCurrentValue(currentValue - 1);
      onChange(currentValue - 1);
    } else {
      setCurrentValue(currentValue);
    }
  };
  return (
    <div className="daily-invitaion-ui">
      <div className="quantity-input form-item">
        <button className="quty-btn sub" onClick={handlePrevious}>
          <span className="icon-i_minus">
            <img src="/assets/images/ic_minus.svg" alt="" />
          </span>
        </button>
        <input
          type="number"
          id="2"
          value={currentValue}
          onChange={(e) => {
            if (parseInt(e.target.value) <= 70 || !e.target.value) {
              onChange(parseInt(e.target.value));
              setCurrentValue(parseInt(e.target.value));
            } else {
              onChange(50);
              setCurrentValue(50);
            }
          }}
          min={1}
          max={70}
        />
        <button className="quty-btn add" onClick={handleNext}>
          <span className="icon-ic_add">
            <img src="/assets/images/ic_add.svg" alt="" />
          </span>
        </button>
      </div>
      <p className="info-text">
        <FormattedMessage id={'workflow.recommended50'} />
      </p>
    </div>
  );
}

function TimerField({ value, onChange, label }) {
  const [currentValue, setCurrentValue] = useState(0);
  const intl = useIntl();
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, []);

  const handleNext = () => {
    let max = 31;
    switch (label) {
      case intl.formatMessage({ id: 'time.days' }):
        max = 31;
        break;
      case intl.formatMessage({ id: 'time.hours' }):
        max = 23;
        break;
      case 'minutes':
        max = 59;
        break;
      default:
        max = 31;
    }
    if (currentValue < max) {
      setCurrentValue(currentValue + 1);
      onChange(currentValue + 1);
    } else {
      setCurrentValue(label === intl.formatMessage({ id: 'time.days' }) ? 2 : 0);
    }
  };
  const handlePrevious = () => {
    let max = 31;
    switch (label) {
      case intl.formatMessage({ id: 'time.days' }):
        max = 31;
        break;
      case intl.formatMessage({ id: 'time.hours' }):
        max = 23;
        break;
      case 'minutes':
        max = 59;
        break;
      default:
        max = 31;
    }
    if (currentValue >= (label === intl.formatMessage({ id: 'time.days' }) ? 3 : 1)) {
      setCurrentValue(currentValue - 1);
      onChange(currentValue - 1);
    } else {
      setCurrentValue(max);
    }
  };
  const handleOnChange = (value) => {
    let max = 31;
    switch (value) {
      case intl.formatMessage({ id: 'time.days' }):
        max = 31;
        break;
      case intl.formatMessage({ id: 'time.hours' }):
        max = 23;
        break;
      case 'minutes':
        max = 59;
        break;
      default:
        max = 31;
    }
    if (parseInt(value) <= max || !value) {
      onChange(parseInt(value));
      setCurrentValue(parseInt(value));
    } else {
      onChange(0);
      setCurrentValue(0);
    }
  };
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="quantity-btn-ui">
        <button type="button" className="quty-btn sub" onClick={handlePrevious} />
        <input
          type="number"
          value={currentValue}
          onChange={(e) => handleOnChange(e.target.value)}
        />
        <button type="button" className="quty-btn add" onClick={handleNext} />
      </div>
    </div>
  );
}

function SelectVars(props) {
  const vars = ['firstName', 'lastName', 'company', 'job', 'location'];
  const [selectToggle, setSelectToggle] = useState(false);

  return (
    <div className="textarea-footer-ui">
      <div className="dropdown select-container">
        <a
          className="btn btn-secondary dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="border-plus-icon">
            <img src="/assets/images/ic_insert_variable.svg" alt="" />
          </span>
          Variables
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          {vars.map((item, i) => (
            <div key={i} className="dropdown-item" onClick={() => props.onSelectVar(item)}>
              <FormattedMessage id={'leads.properties.' + item} />
            </div>
          ))}
        </div>
      </div>
      <p className="info-text">({props.chars} chars)</p>
      {props.type === 'invitation' && (
        <InfoToolTip
          text={
            'If the message exceeds the maximum length after replacing the variables, ' +
            'the invitation will be sent without the message attached'
          }
        />
      )}
    </div>
  );
  /*  return (
    <div className={'selectvars-container'}>
      <span
        className={'slcheck'}
        style={props.disabled ? {color: '#999'} : {}}
        onClick={() => (props.disabled ? false : setSelectToggle(true))}
      >
        Insérer Variables
      </span>
      {selectToggle && (
        <div className={'slcheck select-container'}>
          {vars.map((variable, i) => (
            <p key={i} onClick={() => props.onSelectVar(variable)}>
              {variable}
            </p>
          ))}
        </div>
      )}
    </div>
  );*/
}

function TextArea({
  onChange,
  val,
  textAreaRef,
  type,
  disabled,
  chars_limit,
  checkVarsValidity,
  intl,
}) {
  const [value, setValue] = useState(val || '');
  const [varNotValid, setVarNotValid] = useState(null);
  const CHARS_LIMIT = chars_limit || 200;
  const handleOnchange = (e) => {
    if (e.target.value.length < CHARS_LIMIT) {
      let typedVars = e.target.value.match(/{{(.*?)}}/g);
      if (typedVars) {
        typedVars = typedVars.map((typedVar) => {
          return typedVar.replace(/\{{|}}/g, '');
        });
        typedVars.forEach((variable) => {
          if (
            variable == 'firstName' ||
            variable == 'lastName' ||
            variable == 'job' ||
            variable == 'location' ||
            variable == 'company'
          ) {
            setVarNotValid(null);
            checkVarsValidity(true);
          } else {
            setVarNotValid('{{' + variable + '}}');
            checkVarsValidity(false);
          }
        });
      } else {
        setVarNotValid(null);
        checkVarsValidity(true);
      }
      setValue(textAreaRef.current.value);
      onChange(textAreaRef.current.value);
    }
  };
  const onVariableSelect = (variable) => {
    const startPosition = textAreaRef.current.selectionStart;
    const endPosition = textAreaRef.current.selectionEnd;
    const text = textAreaRef.current.value;

    const before = text.substring(0, startPosition);
    const after = text.substring(endPosition, text.length);
    textAreaRef.current.value = before + `{{${variable}}} ` + after;
    textAreaRef.current.selectionStart =
      textAreaRef.current.selectionEnd + startPosition + variable.length;
    textAreaRef.current.focus();

    setValue(`${value}{{${variable}}} `);
    onChange(`${value}{{${variable}}} `);
  };
  return (
    <div
      className={'text-area-container'}
      style={!value && type == 'message' ? { borderColor: 'red' } : {}}
    >
      <OverlayTrigger
        key={'var-not-valid'}
        placement={'top'}
        show={varNotValid}
        overlay={(props) => Tooltip({ ...props, data: varNotValid + ' is not valid' })}
      >
        <div></div>
      </OverlayTrigger>
      <textarea
        rows="10"
        onChange={handleOnchange}
        value={value}
        ref={textAreaRef}
        placeholder={intl.formatMessage({
          id: 'workflow.helloFirstNameISawThat',
        })}
        disabled={disabled}
        className="textarea-box"
        required
      />
      <div className={'text-area-footer'}>
        <SelectVars
          onSelectVar={onVariableSelect}
          text={value}
          disabled={disabled}
          chars={CHARS_LIMIT - value.length}
          type={type}
        />
      </div>
    </div>
  );
}

function AttachIntegration({ selectIntegrationList, setSelectedIntegration, selectedIntegration }) {
  const [integrations, setIntegrations] = useState([]);
  const [integrationList, setIntegrationList] = useState(null);
  useEffect(() => {
    const loadData = async () => {
      const _integrations = allIntegrations.filter(
        (intg) => intg.active && intg.name !== 'linkedin',
      );

      let { data, statusCode } = await getAccounts();
      if (data && data.accounts)
        _integrations.map((intg) => {
          intg.isConnected = data.accounts[intg.name];
          return intg;
        });
      setIntegrations(_integrations);
    };
    loadData();
  }, []);
  const handleSelectIntegration = async (integrationName) => {
    switch (integrationName) {
      case 'sendinblue':
        const { data: sendinBlueData, statusCode: sendinblueStatus } = await getIntegrationsLists({
          integrationName,
        });
        if (sendinblueStatus == 200) {
          // setIntegrationList(sendinBlueData);
          setIntegrationList({
            name: integrationName,
            lists: sendinBlueData.lists,
          });
        }
        selectIntegrationList(null);
        break;
      case 'zapier':
        const { data: zapierData, statusCode: zapierStatus } = await getIntegrationsLists({
          integrationName,
        });
        if (zapierStatus === 200) {
          zapierData.lists.push({ name: 'Export to all zaps' });
          setIntegrationList({
            name: integrationName,
            lists: zapierData.lists,
          });
        }
        selectIntegrationList(null);
        break;
      case 'lemlist':
        const { data: lemlistData, statusCode: lemlistStatus } = await getIntegrationsLists({
          integrationName,
        });
        if (lemlistStatus == 200) {
          setIntegrationList({
            name: integrationName,
            lists: lemlistData.lists,
          });
        }
        selectIntegrationList(null);
        break;
    }
    setSelectedIntegration(integrationName);
  };

  return (
    <div className="create-workflow-wrap">
      <label className="sub-title2">
        <FormattedMessage id={'settings.integration.selectAnIntegration'} />{' '}
      </label>
      <div className="select-integration" style={{ overflow: 'auto', height: 380 }}>
        <ul>
          {integrations.map((integration, i) => (
            <Integration
              key={i}
              name={integration.name}
              image={integration.name + '-icon.png'}
              onSelect={(name) => integration.isConnected && handleSelectIntegration(name)}
              isConnected={integration.isConnected}
              selectedIntegration={selectedIntegration}
              integrationList={integrationList}
              selectList={selectIntegrationList}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

function Integration({
  name,
  image,
  onSelect,
  isConnected,
  selectedIntegration,
  integrationList,
  selectList,
}) {
  const intl = useIntl();
  return (
    <div className={'wk-integration-container'}>
      <li onClick={isConnected ? () => onSelect(name) : () => {}}>
        <div className="radio-btn">
          <input
            type="radio"
            name="selectintegration"
            defaultChecked={selectedIntegration == name}
            disabled={isConnected ? false : true}
          />
          <span className="radio-icon"></span>
          <div className="radio-text">
            <div className="img-block">
              <img src={'/assets/images/' + image} alt="" />
            </div>
            <div className="content-block">
              <p>{name}</p>
              {isConnected ? (
                <span className="connected-tag">
                  <FormattedMessage id={'connected'} />
                </span>
              ) : (
                <span className="not-connected-tag">
                  <FormattedMessage id={'notConnected'} />
                </span>
              )}
            </div>
          </div>
        </div>
      </li>
      {integrationList && integrationList.name == name && (
        <div className={'custom-integration-list'}>
          <select id="" onChange={(e) => selectList(e.target.value)}>
            <option>
              {intl.formatMessage({ id: 'settings.select' }) +
                (selectedIntegration === 'zapier'
                  ? 'zap'
                  : selectedIntegration === 'lemlist'
                    ? intl.formatMessage({ id: 'settings.campaign' })
                    : intl.formatMessage({ id: 'settings.list' }))}
            </option>

            {integrationList.lists.map((list) => (
              <option value={list.id || list.name || list.link}>{list.name || list.link}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
TextArea = injectIntl(TextArea);
TimerField = injectIntl(TimerField);
export { Branch, Block, AttachBlock, NumberField, TimerField, TextArea, AttachIntegration };
