export default [
  'custom_public_api',
  'api12000',
  'APIHelloMarsell',
  'API500ANNUAL',
  'plan_public_api',
  'api9000',
  'API24K',
  'API24000',
  'API8000',
  'api_datainvest',
  'Almapay',
  'Javelo',
  'cremedelacreme',
  'Api8000',
  'Efrontech',
  'CollaborationCapital',
  'API6000',
  'Hoggo',
  'Qobra',
  'mytraffic',
  'Popchef',
  'Jimhoa',
  'Hero',
  'API_3000_Monthly',
  'API1000',
  'API2000',
  'CustomApiPlan',
  'API',
  'APIPAYHAWK',
];
