import React, { useEffect, useState, useContext } from 'react';
import CurrencyDisplay from '../../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { BillingContext } from '../../../../store/billing.store';
import './FixPriceInfo.css';

export default function FixPriceInfo({
  selectedPlan,
  addOnsPrice,
  organizationCredits,
  user,
  planType,
  nbLicenses,
}) {
  const { state, dispatch } = useContext(BillingContext);
  const [plan, setPlan] = useState({});
  const [action, setAction] = useState(null);

  // Effect to determine the current plan and action (upgrade, downgrade, current)
  useEffect(() => {
    if (state.plans) {
      const plans = state.plans.filter((plan) => plan.planId === selectedPlan.planId);
      setPlan(plans[0]);
    }
    const currentPlanNb = organizationCredits.plan.planId.slice(-1);
    const selectedPLanNb = selectedPlan.planId.slice(-1);

    if (selectedPLanNb > currentPlanNb) {
      setAction('upgrade');
    } else if (selectedPLanNb < currentPlanNb) {
      setAction('downgrade');
    } else {
      setAction('current');
    }
  }, [selectedPlan.planId]);

  const formatPrice = (price) => {
    const formattedPrice = price.toFixed(2);
    return formattedPrice.endsWith('.00') ? formattedPrice.slice(0, -3) : formattedPrice;
  };

  // Organization plan : yearly
  // Plan type selected : yearly
  // Display : Monthly plan type amount
  if (
    organizationCredits.plan &&
    organizationCredits.plan.numberOfMonths === 12 &&
    selectedPlan.yearly &&
    planType === 'monthly'
  ) {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(plan.pricePerSeat * selectedPlan.numberOfSeats)}
        />
      </div>
    );
  }

  // Organization plan : yearly
  // Plan type selected : yearly
  // Display : Yearly plan type amount
  if (selectedPlan.yearly && planType === 'yearly') {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(
            selectedPlan.subscriptionTotal / (selectedPlan.remainingMonths || 12) / 100,
          )}
        />
      </div>
    );
  }

  // Organization plan : monthly
  // Plan type selected : monthly
  // Display : Monthly plan type amount
  if (!selectedPlan.yearly && planType === 'monthly') {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(selectedPlan.subscriptionTotal / 100)}
        />
      </div>
    );
  }

  // Organization plan : monthly
  // Plan type selected : yearly
  // Display : Monthly plan type amount
  // Action : current or upgrade
  if (
    selectedPlan.yearly &&
    planType === 'monthly' &&
    (action === 'current' || action === 'upgrade')
  ) {
    let price;
    const currentPlanPrice =
      organizationCredits.plan.pricePerSeat * organizationCredits.plan.numberOfSeats;
    const selectedPlanTotalPrice = selectedPlan.pricePerSeat * selectedPlan.numberOfSeats;
    price = selectedPlanTotalPrice - currentPlanPrice;

    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(price < 0 ? 0 : price)}
        />
      </div>
    );
  }

  // Organization plan : yearly
  // Plan type selected : monthly
  // Display : Monthly plan type amount
  // Action : current or downgrade
  if (selectedPlan.yearly && planType === 'monthly' && action === 'downgrade') {
    const currentPlanNbSeats = organizationCredits.plan.numberOfSeats;
    const selectedPlanNbSeats = selectedPlan.numberOfSeats;

    let price;
    const toDeduce = currentPlanNbSeats * plan.pricePerSeat;

    const currentPlanPrice =
      organizationCredits.plan.pricePerSeat * organizationCredits.plan.numberOfSeats;
    const selectedPlanTotalPrice = selectedPlan.pricePerSeat * selectedPlan.numberOfSeats;

    if (selectedPlanTotalPrice < currentPlanPrice) {
      price = 0;
    } else {
      price = selectedPlanTotalPrice - (toDeduce || 0);
    }

    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={formatPrice(price < 0 ? 0 : price)}
        />
      </div>
    );
  }

  // Organization plan : monthly
  // Plan type selected : yearly
  // Display : Yearly plan type amount
  if (!selectedPlan.yearly && planType === 'yearly') {
    return (
      <div>
        <CurrencyDisplay
          user={user}
          organizationCredits={organizationCredits}
          amount={plan.yearlyPricePerSeat * nbLicenses}
        />
      </div>
    );
  }

  return <div>NaN</div>;
}
