import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../../../../components';
import { injectIntl, useIntl } from 'react-intl';
function isValidPhoneNumber() {
  return true;
}

function Field({
  label,
  onChange,
  className,
  type,
  value,
  passwordRef,
  formType,
  element,
  disabled,
  hideCheck,
  id,
  inputValidationId,
  setInputValidation,
  hideCheckIcon,
  intl,
}) {
  const [validation, setValidation] = useState('');
  useEffect(() => {
    if (inputValidationId === id) {
      setValidation('error');
    } else {
      setValidation('');
    }
  }, [inputValidationId]);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  const handleOnChange = useCallback((e) => {
    if (type === 'password') {
      if (e.target.value && formType !== 'signin') {
        if (passwordRegex.test(e.target.value)) {
          setValidation('success');
        } else {
          setValidation('error');
        }
      } else {
        setValidation('');
      }
    }
    if (e.target.value) {
      if (setInputValidation) setInputValidation('');
      if (type !== 'password') setValidation('');
    } else {
      // setValidation(id);
      setValidation('error');
    }
    onChange(e.target.value);
  }, []);
  return (
    <div
      className={
        'floating-effect  form-group ' + (formType !== 'signin' && !hideCheckIcon ? validation : '')
      }
    >
      <input
        type={type ? type : 'text'}
        className={className}
        placeholder={' '}
        onChange={handleOnChange}
        value={value}
        ref={passwordRef}
        disabled={disabled}
        id={id}
      />
      {inputValidationId === id && formType !== 'signin' && type !== 'password' ? (
        <OverlayTrigger
          placement="bottom"
          show={true}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: intl.formatMessage({ id: 'auth.thisFieldIsMandatory' }),
            })
          }
        >
          <div />
        </OverlayTrigger>
      ) : (
        validation === 'error' &&
        type === 'password' &&
        formType !== 'signin' &&
        !hideCheck && (
          <OverlayTrigger
            placement="bottom"
            show={true}
            overlay={(props) =>
              Tooltip({
                ...props,
                data: [
                  intl.formatMessage({ id: 'auth.atLeast8Chars' }),
                  intl.formatMessage({ id: 'auth.atLeastOneLowercase' }),
                  intl.formatMessage({ id: 'auth.atLeastOneUppercase' }),
                  intl.formatMessage({ id: 'auth.atLeastOneNumber' }),
                ],
              })
            }
          >
            <div />
          </OverlayTrigger>
        )
      )}
      <label>{label}</label>
      {formType !== 'signin' && !hideCheckIcon && (
        <span className="checked-icon">
          <img src="/assets/images/ic-checked.svg" alt="" />
        </span>
      )}
      {element}
    </div>
  );
}
function PhoneField({ onChange, propsValue, id, inputValidationId, setInputValidation }) {
  const [value, setValue] = useState();
  const [internationalValue, setInternationalValue] = useState(null);
  const phoneRef = useRef(null);
  const intl = useIntl();
  useEffect(() => {
    const inputRef = document.querySelector('.PhoneInputInput');
    const label = document.createElement('label');
    label.style.marginLeft = '80px';
    label.innerHTML = intl.formatMessage({ id: 'leads.properties.phone' });
    inputRef.parentNode.insertBefore(label, inputRef.nextSibling);
    phoneRef.current = inputRef;
    setValue(propsValue);
  }, [propsValue]);

  const handleValue = useCallback((tel) => {
    setInternationalValue('');
    if (tel) {
      setInputValidation('');
      let international = formatPhoneNumberIntl(tel);
      setValue(tel);
      const isValidPhone = isValidPhoneNumber(international);
      /*if (phoneRef.current) {
        if (!isValidPhone) {
          phoneRef.current.reportValidity();
          phoneRef.current.setCustomValidity("Invalid field.");
        } else {
          phoneRef.current.setCustomValidity("");
        }
      }*/
      setInternationalValue(international);
      let phone = formatPhoneNumber(tel);
      let phoneArray = international.split(' ');
      const countryCode = phoneArray[0];
      phoneArray = phoneArray.filter((chunk) => chunk !== countryCode);
      onChange(countryCode, phoneArray.join(''), isValidPhone);
    }
  }, []);
  const handleOnBlur = () => {
    setValue(internationalValue);
  };
  const phoneValidated = isValidPhoneNumber(value) ? 'success' : 'error';
  const isPhoneNotEmpty = inputValidationId == id ? 'error' : '';

  return (
    <div className={'floating-effect  form-group ' + (isPhoneNotEmpty && phoneValidated)}>
      <PhoneInput
        placeholder=" "
        value={value}
        defaultCountry={intl.locale == 'en' ? 'US' : 'FR'}
        onBlur={handleOnBlur}
        onChange={handleValue}
        error={'invalid'}
        id={id}
      />
      {value && !isValidPhoneNumber(value) && (
        <OverlayTrigger
          placement="bottom"
          show={true}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: intl.formatMessage({ id: 'auth.invalidPhone' }),
            })
          }
        >
          <div />
        </OverlayTrigger>
      )}
      {inputValidationId == id && !value && (
        <OverlayTrigger
          placement="bottom"
          show={true}
          overlay={(props) =>
            Tooltip({
              ...props,
              data: 'Invalid phone',
            })
          }
        >
          <div />
        </OverlayTrigger>
      )}
    </div>
  );
}
PhoneField = injectIntl(PhoneField);
Field = injectIntl(Field);

Field = React.memo(Field);
PhoneField = React.memo(PhoneField);

export { Field, PhoneField };
