import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
export default function KnowledgeBaseSlick() {
  function SampleArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style, display: 'none' }} onClick={onClick} />;
  }
  const settings = {
    class: 'carousel-content',
    centerMode: true,
    infinite: true,
    centerPadding: '90px',
    slidesToShow: 3,
    initialSlide: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    nextArrow: <SampleArrow />,
    prevArrow: <SampleArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className={'info-card p-20 '}>
      <h4>
        <FormattedMessage id={'home.knowledgeBase'} />
      </h4>
      <div className={'slider-carousel-container'}>
        <Slider {...settings}>
          <a
            href="https://help.kaspr.io/en/articles/8800425-how-to-get-unlimited-b2b-emails-in-kaspr"
            target={'_blank'}
          >
            <img src="/assets/images/carousel-1.png" alt="" />
            <p>
              <FormattedMessage id={'home.slider.howToGetUnlimitedB2b'} />
            </p>
          </a>
          <a href="https://help.kaspr.io/en/collections/8028464-sales-automation" target={'_blank'}>
            <img src="/assets/images/carousel-2.png" alt="" />
            <p>
              <FormattedMessage id={'home.slider.howToLaunchAnAutomation'} />
            </p>
          </a>
          <a href="https://help.kaspr.io/en/collections/7317002-getting-started" target={'_blank'}>
            <img src="/assets/images/carousel-3.png" alt="" />
            <p>
              <FormattedMessage id={'home.slider.howToUseKasprTheBestWay'} />
            </p>
          </a>
          <a
            href="https://help.kaspr.io/en/collections/7427405-connect-your-tools"
            target={'_blank'}
          >
            <img src="/assets/images/carousel-4.png" alt="" />
            <p>
              <FormattedMessage id={'home.slider.howToConnectKasprWithCrm'} />
            </p>
          </a>
        </Slider>
      </div>
    </div>
  );
}
