import { CLIENT_ID_SALESFORCE } from '../../../../constants';

const getAuthorizationUrl = (redirectUri) => {
  const params = new URLSearchParams({
    response_type: 'code', // or 'token' for implicit grant
    client_id: CLIENT_ID_SALESFORCE,
    redirect_uri: redirectUri,
  });

  return `https://login.salesforce.com/services/oauth2/authorize?${params.toString()}`;
};

function GetOauthUrl(clientId, redirectUri, scopes, responseType, oauthUrl, onLogin, text, type) {
  if (text === 'Outlook') {
    return `${oauthUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scopes.join(
      ' ',
    )}&redirect_uri=${redirectUri}`;
  }

  switch (type) {
    case 'salesforce':
      return getAuthorizationUrl(redirectUri);

    case 'hubspot':
      return `${oauthUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scopes.join(
        ' ',
      )}&redirect_uri=${encodeURIComponent(redirectUri)}`;

    case 'zohocrm':
      return `${oauthUrl}?client_id=${clientId}&response_type=code&scope=${scopes.join(
        ',',
      )}&access_type=offline&redirect_uri=${encodeURIComponent(redirectUri)}`;

    default:
      throw new Error("Erreur dans la creation de l'URL");
  }
}
export default GetOauthUrl;
