import React, { useState, useEffect, useRef } from 'react';
import AircallPhone from 'aircall-everywhere';
import { FormattedMessage } from 'react-intl';
import { Animation } from '../../../../../components';
import { ToastContainer } from 'react-toastify';
import { createPhoneActivity } from '../../../../../services/api/organization.api';

function Aircall({ phones, leadId }) {
  const [numbersToggle, setNumbersToggle] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState('');

  const aircallPhone = useRef(null);

  // Effect hook to initialize and manage the Aircall phone lifecycle
  useEffect(() => {
    // Setup the Aircall phone with initial configuration
    aircallPhone.current = new AircallPhone({
      onLogin: () => {
        if (phones && phones[0] && aircallPhone.current) {
          aircallPhone.current.send('dial_number', { phone_number: phones[0] });
          setSelectedNumber(phones[0]);
        }
      },
      domToLoadPhone: '#phone', // Specifies the DOM element to load the Aircall iframe
      size: 'big',
    });

    // Event listener for outgoing calls to log call activity
    if (aircallPhone.current) {
      aircallPhone.current.on('outgoing_call', async (data) => {
        await createPhoneActivity({
          leadId,
          template: 'leadCalled',
          data: {
            callId: data.call_id,
            from: data.from,
            to: data.to,
            answered: false,
          },
          clientDate: Date.now(),
        });
      });

      // Event listener for when calls end to log call activity
      aircallPhone.current.on('call_ended', async (data) => {
        if (data.duration !== '00:00') {
          await createPhoneActivity({
            leadId,
            template: 'leadCallEnded',
            data: {
              callId: data.call_id,
              from: data.from,
              to: data.to,
              answered: true,
              duration: data.duration,
            },
            clientDate: Date.now(),
          });
        }
      });
    }

    // Cleanup function to remove event listeners and reset reference on component unmount
    return () => {
      if (aircallPhone.current) {
        aircallPhone.current.removeListener('outgoing_call');
        aircallPhone.current.removeListener('call_ended');
        aircallPhone.current = null;
      }
    };
  }, [leadId, phones]);

  // Function to dial a number using the Aircall API
  const dial = (number) => {
    aircallPhone.current &&
      aircallPhone.current.send('dial_number', { phone_number: number }, (success, data) => {
        console.log('Dialing number:', number);
      });
  };

  return (
    <>
      <div>
        <div className="cstm-dropdown add-to-list" style={{ paddingBottom: '10px' }}>
          <div className={'custom-title'}>
            <FormattedMessage id={'leads.properties.phones'} />
          </div>
          <div className="dropdown">
            <button
              className="dropdown-toggle select-button"
              onClick={() => setNumbersToggle(!numbersToggle)}
              style={{ minWidth: 'max-content', height: 36, fontSize: 14 }}
            >
              <p style={{ width: 'max-content', fontSize: 14 }}>
                {selectedNumber ? selectedNumber : phones && phones[0]}
              </p>
            </button>
            <Animation.SlideDown
              className={'dropdown-menu show'}
              toggle={numbersToggle}
              setToggle={setNumbersToggle}
            >
              <ul>
                {phones.map((number, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setSelectedNumber(number);
                      dial(number);
                      setNumbersToggle(false);
                    }}
                  >
                    <a
                      href="#"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '1s0px 0',
                      }}
                    >
                      <span className="name_ui">{number}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </Animation.SlideDown>
          </div>
        </div>
        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </div>

      <div id="phone"></div>
    </>
  );
}

export default Aircall;
