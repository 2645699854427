import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import AircallIframe from './AircallIframe';

function Aircall({ handleToggle, phones, leadId }) {
  return (
    <div style={{ paddingLeft: '35px' }}>
      <button className="close" onClick={() => handleToggle(false)}>
        <img src="/assets/images/ic-cross.png" alt="" />
      </button>
      <h4 className="title" style={{ fontSize: '20px' }}>
        <FormattedMessage id={'leads.callUsingAircall'} />
      </h4>
      <AircallIframe phones={phones} leadId={leadId} />
    </div>
  );
}

export default Aircall;
