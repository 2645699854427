import React, { useReducer, useMemo, useEffect } from 'react';
import { billingReducer, billingInitialState } from './store/billing.store';
import BillingProvider from './store/billing.provider';
import { Header, ContentWrapperUi, SideNav, PreLoader } from '../../components';
import { Col } from 'react-bootstrap';
import Plans from './Plans/Plans';
import {
  getPlans,
  getPaymentHistory,
  getPromoPlan,
  getSubscriptionInfos,
} from '../../services/api/credits.api';
import actions from './store/billing.actions';
import Checkout from './Checkout/Checkout';
import BillingInfos from './BillingInfos/BillingInfos';
import { useState } from 'react';
import {
  getALLInvitations,
  getNewInvitations,
  getOrganization,
} from '../../services/api/organization.api';
import queryString from 'query-string';

import { FormattedMessage } from 'react-intl';
import './Billing.css';
import { ToastContainer } from 'react-toastify';
import Unpaid from '../Unpaid/Unpaid';
import {
  dataDrive,
  formatSubscribeInfo,
  getExtraCreditsFromPlan,
} from '../../services/utils/tools';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import DataNotFound from '../../components/DataNotFound/DataNotFound';
export default function Billing(props) {
  const [state, dispatch] = useReducer(billingReducer, billingInitialState);
  const [updatePaymentToggle, updatePaymentInfos] = useState(false);
  const [customPlan, setCustomPlan] = useState(false);
  const [currentTab, setCurrentTab] = useState('plans');
  const [numberOfInvitations, setNumberOfInvitations] = useState(0);
  const [workspaceInvitations, setWorkSpaceInvitations] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addMembersFromOtherWorkspaceLoading, setAddMembersFromOtherWorkspaceLoading] =
    useState(true);
  const [minSeats, setMinSeats] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    if (props.urlSegment) {
      setCurrentTab(props.urlSegment);
    }
    getNewInvitations().then(({ data }) => {
      setWorkSpaceInvitations(data.invitations);
    });
    const invitees = dataDrive({ key: 'invitees', from: /home|workspace/g });
    const numberOfLicences = dataDrive({
      key: 'licences',
      from: /home|workspace/g,
    });

    if (invitees && invitees.length) {
      let plan =
        props.redux.credits &&
        props.redux.credits.organizationCredits &&
        props.redux.credits.organizationCredits.plan;
      getSubscriptionInfos({
        numberOfSeats: numberOfLicences + plan.numberOfSeats,
        numberOfMonths: plan.numberOfMonths,
        planId: plan.planId,
        extraCredits: getExtraCreditsFromPlan(plan),
      }).then(({ data, statusCode }) => {
        dispatch({
          type: actions.GET_SUBSCRIPTION_INFOS,
          payload: {
            ...plan,
            ...formatSubscribeInfo(data),
            invitees,
            numberOfSeats: numberOfLicences + plan.numberOfSeats,
          },
        });
        setAddMembersFromOtherWorkspaceLoading(false);
      });
    } else {
      setAddMembersFromOtherWorkspaceLoading(false);
    }
  }, []);
  useEffect(() => {
    getPlans().then(async ({ data, statusCode }) => {
      if (!props.redux.credits || !props.redux.organization) return;
      if (statusCode === 200) {
        const { data: invitationsData, statusCode: invitationsStatusCode } =
          await getALLInvitations();
        let dataInvitationsLength =
          invitationsStatusCode === 200 ? invitationsData.invitations.length : 0;
        setNumberOfInvitations(dataInvitationsLength);
        const maxOfSeats =
          props.redux.credits.organizationCredits &&
          props.redux.credits.organizationCredits.plan &&
          props.redux.credits.organizationCredits.plan.planId == '0'
            ? Math.max(
                props.redux.organization && props.redux.organization.organization.members
                  ? props.redux.organization.organization.members.length + dataInvitationsLength
                  : dataInvitationsLength,
                1,
              )
            : Math.max(
                props.redux.credits.organizationCredits &&
                  props.redux.credits.organizationCredits.plan &&
                  props.redux.credits.organizationCredits.plan.numberOfSeats,
                props.redux.organization && props.redux.organization.members
                  ? props.redux.organization.members.length + dataInvitationsLength
                  : 1,
              );
        setMinSeats(maxOfSeats);
        let plans = data.plans;
        plans = plans.filter((plan) => plan.version !== 1);
        plans = plans.map((plan) => {
          plan.numberOfSeats = maxOfSeats;
          plan.currentSeats = maxOfSeats;
          if (plan.planId == 'plan_3') {
            plan.numberOfSeats = Math.max(maxOfSeats, 5);
            plan.onlyAnnual = true;
            plan.yearly = true;
          }

          plan.phoneCredits = parseInt(plan.phoneCreditsPerSeat) * plan.numberOfSeats;
          plan.personalEmailCredits =
            parseInt(plan.personalEmailCreditsPerSeat) * plan.numberOfSeats;
          plan.exportCredits = parseInt(plan.exportCreditsPerSeat) * plan.numberOfSeats;
          return plan;
        });
        //CUSTOM PLAN
        const {
          plan: customPlan,
          minUsers: customMinUsers,
          minSeats: customMinSeats,
          numberOfMonths: customNumberOfMonths,
        } = queryString.parse(window.location.search);

        if (customPlan) {
          const { data, statusCode } = await getPromoPlan({
            code: customPlan,
            minUsers: parseInt(customMinUsers),
            numberOfMonths: parseInt(customNumberOfMonths),
          });
          if (statusCode == 200) {
            const customFromUrl = {
              ...data.plan,
              isFromParams: true,
            };
            setMinSeats(customMinUsers || customMinSeats || customFromUrl.minSeats);
            customFromUrl.numberOfSeats = parseInt(
              customMinUsers || customMinSeats || customFromUrl.minSeats,
            );
            customFromUrl.currentSeats = maxOfSeats;
            customFromUrl.minUsers = parseInt(customMinUsers || customFromUrl.minSeats);
            customFromUrl.numberOfMonths = parseInt(
              customNumberOfMonths || customFromUrl.numberOfMonths,
            );
            customFromUrl.yearly = customNumberOfMonths == 12;

            customFromUrl.phoneCredits =
              parseInt(customFromUrl.phoneCreditsPerSeat) * customFromUrl.numberOfSeats;
            customFromUrl.personalEmailCredits =
              parseInt(customFromUrl.personalEmailCreditsPerSeat) * customFromUrl.numberOfSeats;
            customFromUrl.exportCredits =
              parseInt(customFromUrl.exportCreditsPerSeat) * customFromUrl.numberOfSeats;
            customFromUrl.isCustom = true;
            setCustomPlan(customFromUrl);
          } else {
            setCustomPlan(null);
            // window.history.pushState(null, null, '/billing');
            navigate('/billing');
          }
        }
        dispatch({ type: actions.GET_PLANS, payload: plans });
      }
    });
  }, [props.redux.credits, props.redux.organization]);

  useEffect(() => {
    if (props.redux.profile.email) {
      dispatch({ type: actions.GET_PROFILE, payload: props.redux.profile });
    }
    getPaymentHistory().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        dispatch({ type: actions.GET_PAYMENT_HISTORY, payload: data.invoices });
      }
    });
    getOrganization().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        data.organization.member = data.member;
        dispatch({
          type: actions.GET_ORGANIZATION,
          payload: data.organization,
        });
      }
      setLoading(false);
    });
    const { code } = queryString.parse(window.location.search);
    if (code) {
      getPromoPlan(code).then(({ data }) => {
        setCustomPlan(data.plan);
      });
    }
  }, []);
  useEffect(() => {
    if (props.redux.profile.email) {
      dispatch({ type: actions.GET_PROFILE, payload: props.redux.profile });
    }
  }, [props.redux.profile]);

  useEffect(() => {
    let { credits, price } = queryString.parse(window.location.search);
    if (credits && price) {
      dispatch({ type: actions.ON_CREDIT_PACKS, payload: { credits, price } });
    }
  }, []);
  useEffect(() => {
    if (state.selectedPlan) {
      navigate('?p=checkout', { replace: true });
    }
  }, [state.selectedPlan]);
  const checkPermission = () => {
    const org = state.organization;
    return org && org && (org.member.type === 'owner' || org.member.type === 'admin');
  };
  if (!props.redux.credits.personalCredits) {
    return <PreLoader />;
  }
  if (loading || addMembersFromOtherWorkspaceLoading) {
    return <PreLoader />;
  }
  /*  if (!state.organization) {
    return <PreLoader />;
  }*/
  let isVersion2 = props.redux.credits && props.redux.credits.isVersion(2);
  if (state.selectedPlan || updatePaymentToggle) {
    return (
      <BillingProvider store={store}>
        <ToastContainer />
        <Checkout
          organizationCredits={props.redux.credits.organizationCredits}
          plan={state.selectedPlan}
          pack={state.pack}
          updatePaymentToggle={updatePaymentToggle}
          user={props.redux.profile}
        />
      </BillingProvider>
    );
  }
  if (window.location.pathname == '/checkout') {
    return (
      <BillingProvider store={store}>
        <Unpaid {...props.redux} />
        <Plans
          credits={props.redux.credits}
          organizationCredits={props.redux.credits.organizationCredits}
          checkPermission={checkPermission()}
          urlSegment={props.urlSegment}
          numberOfInvitations={numberOfInvitations}
          minSeats={minSeats}
          setMinSeats={setMinSeats}
          customPlan={customPlan}
          setCustomPlan={setCustomPlan}
          workspaceInvitations={workspaceInvitations}
          resetRedux={props.redux.resetRedux}
          user={props.redux.profile}
          isVersion2={isVersion2}
        />
      </BillingProvider>
    );
  }

  if (!(props.redux.organization && props.redux.organization.organization)) {
    return (
      <BillingProvider store={store}>
        <ToastContainer />
        <Header
          setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
          navToggle={props.redux.globalReducer.navToggle}
          currentPage={<FormattedMessage id={'sidenav.billing'} />}
          profile={props.redux.profile}
          credits={props.redux.credits}
          remaining={props.redux.credits.remaining}
          organization={props.redux.organization}
        />
        <ContentWrapperUi>
          <SideNav
            navToggle={props.redux.globalReducer.navToggle}
            checkPermission={checkPermission()}
          />
          <BillingWrapper>
            <DataNotFound
              image={'/assets/images/img_empty_no_leads.png'}
              elements={
                <div
                  style={{ marginTop: '0' }}
                  className="join-organization-inner join-organization-page"
                >
                  {props.redux.profile.joinRequestSent &&
                  props.redux.profile.joinRequestSent.joinReqs &&
                  props.redux.profile.joinRequestSent.joinReqs.length > 0 ? (
                    <>
                      <h4>
                        <FormattedMessage id={'organization.youArentPartOfAWkYet'} />
                      </h4>
                      <p className="desc">
                        <FormattedMessage id={'organization.youArentPartOfAWkYetText'} />
                      </p>
                      <button className={'btn1'}>
                        <NavLink
                          to={'/workspace'}
                          style={{ color: 'white' }}
                          data-tracking-id={'leads.createOrganization'}
                        >
                          <FormattedMessage id={'organization.viewPendingRequest'} />
                        </NavLink>
                      </button>
                    </>
                  ) : (
                    <>
                      <h4>
                        <FormattedMessage id={'organization.youHavetoCreateAnOrganization'} />
                      </h4>
                      <p className="desc">
                        <FormattedMessage id={'organization.youHavetoCreateAnOrganization.text'} />
                      </p>
                      <button className={'btn1'}>
                        <NavLink
                          to={'/workspace'}
                          style={{ color: 'white' }}
                          data-tracking-id={'leads.createOrganization'}
                        >
                          <FormattedMessage id={'organization.showWorkspaces'} />
                        </NavLink>
                      </button>
                    </>
                  )}
                </div>
              }
            />
          </BillingWrapper>
        </ContentWrapperUi>
      </BillingProvider>
    );
  }

  return (
    <BillingProvider store={store}>
      <ToastContainer />
      <Header
        setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
        navToggle={props.redux.globalReducer.navToggle}
        currentPage={<FormattedMessage id={'sidenav.billing'} />}
        profile={props.redux.profile}
        credits={props.redux.credits}
        remaining={props.redux.credits.remaining}
        organization={props.redux.organization}
      />
      <ContentWrapperUi>
        <SideNav
          navToggle={props.redux.globalReducer.navToggle}
          checkPermission={checkPermission()}
        />
        <BillingWrapper>
          <BillingTabs
            organizationCredits={props.redux.credits.organizationCredits}
            checkPermission={checkPermission()}
            urlSegment={props.urlSegment}
            credits={props.redux.credits}
            profile={props.redux.profile}
            customPlan={customPlan}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setCustomPlan={setCustomPlan}
          />
          <div className="tab-content">
            {props.redux.credits.organizationCredits &&
              (props.redux.credits.organizationCredits.subscription ||
                props.redux.credits.organizationCredits.customer) &&
              checkPermission() &&
              !customPlan &&
              currentTab == 'info' && (
                <BillingInfos
                  credits={props.redux.credits}
                  updatePaymentInfos={updatePaymentInfos}
                  isVersion2={isVersion2}
                  organization={props.redux.organization}
                  user={props.redux.profile}
                />
              )}
            {currentTab == 'plans' && (
              <Plans
                credits={props.redux.credits}
                organizationCredits={props.redux.credits.organizationCredits}
                checkPermission={checkPermission()}
                urlSegment={props.urlSegment}
                numberOfInvitations={numberOfInvitations}
                minSeats={minSeats}
                setMinSeats={setMinSeats}
                customPlan={customPlan}
                setCustomPlan={setCustomPlan}
                workspaceInvitations={workspaceInvitations}
                resetRedux={props.redux.resetRedux}
                user={props.redux.profile}
                isVersion2={isVersion2}
              />
            )}
          </div>
        </BillingWrapper>
      </ContentWrapperUi>
    </BillingProvider>
  );
}

function BillingWrapper({ children }) {
  return (
    <Col md={10} className="content-wrap">
      <div className="content-block">
        <div className="inner-content-wrap">
          <div className="wd-wrapper">{children}</div>
        </div>
      </div>
    </Col>
  );
}
function BillingTabs({
  organizationCredits,
  checkPermission,
  currentTab,
  customPlan,
  setCurrentTab,
  setCustomPlan,
}) {
  const navigate = useNavigate();
  const onTabCLick = (tab) => {
    if (tab === 'plans') {
      setCustomPlan(null);
    }
    setCurrentTab(tab);
    navigate(`/billing/${tab}`);
  };
  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui">
        <ul className="nav nav-tabs">
          {organizationCredits &&
            organizationCredits &&
            (organizationCredits.subscription || organizationCredits.customer) &&
            checkPermission &&
            !customPlan && (
              <li onClick={() => onTabCLick('info')}>
                <a
                  id={'info'}
                  className={
                    queryString.parse(window.location.search) &&
                    queryString.parse(window.location.search).changePlan === 'true'
                      ? ''
                      : currentTab == 'info'
                        ? 'active'
                        : ''
                  }
                >
                  <FormattedMessage id={'tabs.billinginfo'} />
                </a>
              </li>
            )}
          <li onClick={() => onTabCLick('plans')}>
            <a
              id={'plans'}
              className={
                organizationCredits &&
                organizationCredits &&
                (organizationCredits.subscription || organizationCredits.customer) &&
                checkPermission &&
                !customPlan &&
                !(
                  queryString.parse(window.location.search) &&
                  queryString.parse(window.location.search).changePlan === 'true'
                ) &&
                currentTab !== 'plans'
                  ? ''
                  : 'active'
              }
              /*  onClick={() =>
                customPlan ? (window.location = "/billing") : false
              }*/
            >
              <FormattedMessage id={'billing.upgrade'} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
