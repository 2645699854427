import actions from '../actions';

const initState = { credits: null, remaining: null, userBlocked: false };

export const credits = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_CREDITS:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actions.GET_REMAINING:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actions.IS_USER_BLOCKED:
      state = {
        ...state,
        ...action.payload,
      };
      break;
  }
  return state;
};
