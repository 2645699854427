import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import './FilesHeader.css';
import { PaginationInput } from '../../../../components';
import { BulkEnrichmentContext } from '../../store/bulkEnrichment.store';

const FilesHeader = React.memo(({ currentPage, setCurrentPage }) => {
  const { state } = useContext(BulkEnrichmentContext);

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    if (!state.noData) {
      return (
        <div id="files-header" className="files-header-right">
          <label className="pagination-label">
            <FormattedMessage id="page" /> :
          </label>
          <div className="pagination-container">
            <PaginationInput
              pages={state.totalPages}
              currentPage={currentPage}
              onChange={handlePagination}
            />
          </div>
          <label className="pagination-label">
            <FormattedMessage id="ofPages" values={{ value: state.totalPages }} />
          </label>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="files-header-container">
      <h1>
        <FormattedMessage id="bulkEnrichment.files.bulkEnrichmentFiles" />
      </h1>
      {renderPagination()}
    </div>
  );
});

export default FilesHeader;
