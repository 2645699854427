import React, { useEffect, useState, useRef } from 'react';
import './JoinWorkspace.css';
import JoinWorkspaceNonExistingDomain from './JoinWorkspaceNonExistingDomain/JoinWorkspaceNonExistingDomain';
import JoinWorkspaceExistingDomain from './JoinWorkspaceExistingDomain/JoinWorkspaceExistingDomain';
import {
  getNewInvitations,
  getPublicOrganization,
  acceptInvitation,
} from '../../../../services/api/organization.api';
import { Header, PreLoader } from '../../../../components';
import { toast } from 'react-toastify';

function JoinWorkspace(props) {
  const [userDomain, setUserDomain] = useState('');
  const [publicOrganizations, setPublicOrganizations] = useState([]);
  const [loadingInvitations, setLoadingInvitations] = useState(true);
  const [loadingPublicOrgs, setLoadingPublicOrgs] = useState(true);
  const [hasMultipleDomains, setHasMultipleDomains] = useState(false);

  // Ref to track if redirection is in progress
  const isRedirectingRef = useRef(false);

  useEffect(() => {
    const fetchAndHandleInvitations = async () => {
      try {
        // Fetch new invitations
        const { data: invitationData } = await getNewInvitations();

        if (invitationData.invitations.length > 0) {
          // Sort invitations by descending creation date
          const sortedInvitations = invitationData.invitations.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          );

          // Accept the first sorted invitation
          const { data: acceptData, statusCode } = await acceptInvitation({
            organizationId: sortedInvitations[0].organizationId._id,
            inviter: sortedInvitations[0].inviter,
          });

          if (statusCode === 200) {
            // Set the redirect flag to true
            isRedirectingRef.current = true;
            // Redirect to the welcome page
            window.location = '/welcome';
          } else if (statusCode >= 400) {
            // Display error message if the status code indicates a failure
            toast.error(acceptData.messageTranslated || acceptData.message);
          }
        }
      } catch (error) {
        console.error('Error handling invitations:', error);
      } finally {
        // Only set loadingInvitations to false if not redirecting
        if (!isRedirectingRef.current) {
          setLoadingInvitations(false);
        }
      }
    };

    fetchAndHandleInvitations();

    return () => {
      // Reset the redirect flag and loading state when the component unmounts
      isRedirectingRef.current = false;
      setLoadingInvitations(true);
    };
  }, []);

  useEffect(() => {
    const fetchPublicOrganizations = async () => {
      try {
        // Extract the domain from the user's email
        if (props.redux.profile.email) {
          const domain = props.redux.profile.email.split('@')[1];
          setUserDomain(domain);
        }

        // Fetch public organizations
        const { data: publicOrganizationData, statusCode } = await getPublicOrganization();
        if (statusCode === 200) {
          if (
            publicOrganizationData &&
            publicOrganizationData.organizations &&
            publicOrganizationData.organizations.length > 0
          ) {
            setHasMultipleDomains(true);
            setPublicOrganizations(publicOrganizationData.organizations);
          } else {
            setHasMultipleDomains(false);
            setPublicOrganizations([]);
          }
        }
      } catch (error) {
        console.error('Error fetching public organizations:', error);
      } finally {
        setLoadingPublicOrgs(false);
      }
    };

    fetchPublicOrganizations();

    return () => {
      setPublicOrganizations([]);
      setLoadingPublicOrgs(true);
    };
  }, [props.redux.profile.email]);

  // Combine loading states
  const isLoading = loadingInvitations || loadingPublicOrgs;

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div id="signupflow-joinWk">
      <Header
        currentPage={''}
        profile={props.redux.profile}
        isOnBoardingPage
        scrollable
        organization={props.redux.organization}
      />
      {hasMultipleDomains ? (
        <JoinWorkspaceExistingDomain
          publicOrganizations={publicOrganizations}
          userDomain={userDomain}
        />
      ) : (
        <JoinWorkspaceNonExistingDomain userDomain={userDomain} />
      )}
    </div>
  );
}

export default JoinWorkspace;
