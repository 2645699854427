import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import StarRating from '../../common/StarRating/StarRating';
import { CHROME_STORE } from '../../../../constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PreLoader } from '../../../../components';

const RedirectionPage = ({ isExtensionInstalled, handleOnClick }) => {
  const { showCountdownDelay } = useFlags();
  const [countdown, setCountdown] = useState(5);
  const [preCountdownDelay, setPreCountdownDelay] = useState(5);
  const [isDelayOver, setIsDelayOver] = useState(false);

  // Refs to store timer IDs
  const delayTimerRef = useRef(null);
  const countdownTimerRef = useRef(null);

  // Handle pre-countdown delay
  useEffect(() => {
    if (typeof showCountdownDelay === 'undefined') {
      return; // Wait until we get showCountdownDelay value
    }

    if (showCountdownDelay && preCountdownDelay > 0) {
      delayTimerRef.current = setInterval(() => {
        setPreCountdownDelay((prevDelay) => prevDelay - 1);
      }, 1000);
    } else {
      setIsDelayOver(true); // Delay is over, start the countdown
    }

    return () => {
      clearInterval(delayTimerRef.current);
    };
  }, [showCountdownDelay, preCountdownDelay]);

  // Handle the countdown after the delay is over
  useEffect(() => {
    if (isDelayOver) {
      if (countdown === 0) {
        const redirectUrl = isExtensionInstalled
          ? 'https://www.linkedin.com/in/anne-charlotte-lazou/?synchro=kaspr&isFirstKasprSynchro=true'
          : CHROME_STORE;
        window.location.href = redirectUrl;
      } else {
        countdownTimerRef.current = setInterval(() => {
          setCountdown((prevCount) => prevCount - 1);
        }, 1000);
      }
    }

    return () => {
      clearInterval(countdownTimerRef.current);
    };
  }, [isDelayOver, countdown, isExtensionInstalled]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearInterval(delayTimerRef.current);
      clearInterval(countdownTimerRef.current);
    };
  }, []);

  // If the flag is not yet available, show a loading indicator
  if (typeof showCountdownDelay === 'undefined') {
    return <PreLoader />;
  }

  return (
    <div className="container">
      <div className="container-top">
        <h1 className="onboarding-title">
          {isExtensionInstalled ? (
            <FormattedMessage id="auth.takingYouBackToLinkedin" />
          ) : (
            <FormattedMessage id="auth.timeToInstallOurExtension" />
          )}
        </h1>

        <div className="onboarding-desc">
          <p>
            {isExtensionInstalled ? (
              <FormattedMessage id="auth.extensionInstalledDesc" />
            ) : (
              <FormattedMessage id="auth.extensionNotInstalledDesc" />
            )}
          </p>
        </div>
      </div>

      {!isDelayOver ? (
        isExtensionInstalled ? (
          // Show static button during the delay
          <button
            className="btn btn1 redirection-btn"
            onClick={() => handleOnClick('linkedin_connect')}
          >
            <FormattedMessage id="leads.goToLinkedin" />
          </button>
        ) : (
          <button className="btn btn1 redirection-btn" onClick={() => handleOnClick('plugin')}>
            <FormattedMessage id="auth.downloadExtension" />
          </button>
        )
      ) : (
        // Show countdown after the delay
        <button
          onClick={() => handleOnClick(isExtensionInstalled ? 'linkedin_connect' : 'plugin')}
          className="btn btn1 redirection-btn"
        >
          <FormattedMessage id="auth.redirectingYouIn" /> {countdown}
        </button>
      )}

      <div className="rating-ctn">
        <StarRating rating={4.4} />
        <div className="rating">
          <p>
            <FormattedMessage id="auth.ratingOn" values={{ value: '4,4' }} />
          </p>
          <a
            href="https://www.g2.com/products/kaspr/reviews"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/g2_logo.png" width={20} alt="G2 logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RedirectionPage;
