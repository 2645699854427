import React from 'react';
import './ConnectionModalHeader.css';
import { FormattedMessage } from 'react-intl';
import { integrationsDetailedDescriptions } from '../../../../constants';

const ConnectionModalHeader = ({ props }) => {
  const IntegrationData = integrationsDetailedDescriptions[props.integration];

  return (
    <div className="connection-modal-header">
      <div className="modal-inner-header">
        <div className="profile-img-block">
          <img src={props.imagesrc} alt="" />
        </div>
        <div className="profile-info">
          <p className="username">{IntegrationData.name}</p>
          <div className="tool-description">
            <FormattedMessage id={IntegrationData.quickDescription} />
          </div>
          <div className="icons-container">
            <div className="icons">
              <img src="/assets/images/logo_round.png" width={17} height={17} alt={'logo'} />
              <p>
                <FormattedMessage id={'settings.integration.builtByKaspr'} />
              </p>
            </div>
            <div className="icons">
              <img
                src="/assets/images/green-tick.svg"
                width={17}
                height={17}
                alt={'validate icon'}
              />
              <p>
                <FormattedMessage id={IntegrationData.subscriptionDescription} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionModalHeader;
