const options = [
  {
    key: 1,
    id: 'cancellationflow.yourfeedback.Budget',
    reason: "Doesn't fit my budget",
    openTextArea: false,
  },
  {
    key: 2,
    id: 'cancellationflow.yourfeedback.AlternativeSolution',
    reason: 'Switching to an alternative solution',
    openTextArea: true,
    textAreaQuestionId: 'cancellationflow.solutionYouBeMovingTo',
    text: '',
  },
  {
    key: 3,
    id: 'cancellationflow.yourfeedback.Difficult',
    reason: 'Too difficult to figure out',
    openTextArea: false,
  },
  {
    key: 4,
    id: 'cancellationflow.yourfeedback.SwitchingJobs',
    reason: "I'm switching jobs",
    openTextArea: false,
  },
  {
    key: 5,
    id: 'cancellationflow.yourfeedback.TakingABreak',
    reason: "I'm taking a break",
    openTextArea: false,
  },
  {
    key: 6,
    id: 'cancellationflow.yourfeedback.NotSatisfied',
    reason: 'Not satisfied with the product',
    openTextArea: false,
  },
  {
    key: 7,
    id: 'cancellationflow.yourfeedback.OtherReason',
    reason: 'Other reason',
    openTextArea: true,
    textAreaQuestionId: 'cancellationflow.OtherReasonText',
    text: '',
  },
];

export { options };
