import React, { useReducer, useMemo, useState, useEffect } from 'react';
import { OrganizationReducer, OrganizationInitialState, actions } from './store/Organization.store';
import reduxActions from '../../redux/actions';
import OrganizationProvider from './store/Organization.provider';
import { Header, ContentWrapperUi, SideNav, PreLoader, Permissions } from '../../components';
import Members from './Members/Members';
import Statistics from './Statistics/Statistics';
import Activity from './Activity/Activity';
import CreateJoinOrganization from './CreateJoinOrganization/CreateJoinOrganization';
import { Col } from 'react-bootstrap';
import { getMembers, getOrganization } from '../../services/api/organization.api';
import './Organization.css';
import { getRemainingCredits } from '../../services/api/credits.api';
import { connect } from 'react-redux';
import { getUser } from '../../services/api/auth.api';

import { FormattedMessage } from 'react-intl';
import Settings from './Settings/Settings';
import { ToastContainer } from 'react-toastify';
import { usePermissions } from '../../components/Permissions/permissions.hooks';
import { useNavigate } from 'react-router-dom';

function Organization(props) {
  const [state, dispatch] = useReducer(OrganizationReducer, OrganizationInitialState);

  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  const [selectedTab, setSelectedTab] = useState('members');
  let isVersion2 = props.redux.credits && props.redux.credits.isVersion(2);

  useEffect(() => {
    const org = props.redux.organization;
    if (org.message) {
      window.location = '/workspace/createjoin';
    } else {
      getMembers({ search: '', page: 1 }).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          org.organization.members = data.members;
          org.organization.membersPages = data.pages;
          org.organization.totalMembers = data.totalMembers;
          dispatch({ type: actions.GET_ORGANIZATION, payload: org });
        }
      });
    }
  }, []);
  const [checkPlanActivityPermissions] = usePermissions(
    props.redux.credits &&
      props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan &&
      props.redux.credits.organizationCredits.plan.permissions,
    null,
    'TeamActivity',
  );
  const [checkPlanReportsPermissions] = usePermissions(
    props.redux.credits &&
      props.redux.credits.organizationCredits &&
      props.redux.credits.organizationCredits.plan &&
      props.redux.credits.organizationCredits.plan.permissions,
    null,
    'TeamReports',
  );
  if (!state.organization._id) {
    return <PreLoader />;
  }
  return (
    <OrganizationProvider store={store}>
      <Header
        setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
        navToggle={props.redux.globalReducer.navToggle}
        currentPage={<FormattedMessage id={'sidenav.organization'} />}
        profile={props.redux.profile}
        credits={props.redux.credits}
        remaining={props.redux.credits.remaining}
        organization={props.redux.organization && props.redux.organization}
      />
      <ContentWrapperUi>
        <SideNav navToggle={props.redux.globalReducer.navToggle} />
        <OrganizationWrapper>
          <NavTab
            organization={props.redux.organization}
            profile={props.redux.profile}
            onTabChoose={setSelectedTab}
            selectedTab={selectedTab}
            checkPlanActivityPermissions={checkPlanActivityPermissions}
            checkPlanReportsPermissions={checkPlanReportsPermissions}
            urlSegment={props.urlSegment}
          />
          <div className="tab-content">
            {selectedTab == 'members' && (
              <Members
                profile={props.redux.profile}
                resetRemaining={props.resetRemaining}
                organization={props.redux.organization}
                remainingCredits={props.redux.credits.remaining}
                subOrganizations={
                  props.redux.organization && props.redux.organization.subOrganizations
                }
                subscription={props.redux.credits.organizationCredits.subscription}
                organizationCredits={props.redux.credits.organizationCredits}
                personalCredits={props.redux.credits.personalCredits}
                isVersion2={props.redux.credits && props.redux.credits.isVersion(2)}
              />
            )}
            {selectedTab == 'statistics' && checkPlanReportsPermissions('View') && (
              <Statistics
                organization={props.redux.organization.organization}
                credits={props.redux.credits}
                checkPlanReportsPermissions={checkPlanReportsPermissions}
                checkPlanActivityPermissions={checkPlanActivityPermissions}
                isVersion2={isVersion2}
              />
            )}
            {selectedTab == 'activity' && checkPlanActivityPermissions('View') && (
              <Activity
                organization={props.redux.organization}
                checkPlanActivityPermissions={checkPlanActivityPermissions}
              />
            )}
          </div>
        </OrganizationWrapper>
      </ContentWrapperUi>
      <ToastContainer />
    </OrganizationProvider>
  );
}

function NavTab({
  organization,
  onTabChoose,
  urlSegment,
  checkPlanReportsPermissions,
  checkPlanActivityPermissions,
  selectedTab,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (urlSegment) {
      let tab = document.querySelector(`#${urlSegment}`);
      if (tab) tab.click();
    }
  }, []);
  const onTabCLick = (tab) => {
    navigate(`/workspace/${tab}`);
    onTabChoose(tab);
  };
  const checkPermission = () => {
    return (
      organization.member &&
      (organization.member.type == 'owner' || organization.member.type == 'admin')
    );
  };

  return (
    <div className="workflow-detail-header">
      <div className="cstm-tabs-ui cs-tabs-slider">
        <ul className="nav nav-tabs">
          <li onClick={() => onTabCLick('members')}>
            <a id={'members'} className={selectedTab === 'members' ? 'active' : ''}>
              <FormattedMessage id={'tabs.members'} />
            </a>
          </li>
          {checkPermission() &&
            (checkPlanReportsPermissions('View') ? (
              <li onClick={() => onTabCLick('statistics')}>
                <a id={'statistics'} className={selectedTab === 'statistics' ? 'active' : ''}>
                  <FormattedMessage id={'tabs.statistics'} />
                </a>
              </li>
            ) : (
              <Permissions.CallToAction isPlan={true} withoutIcon={true} toolTipPosition={'bottom'}>
                <li>
                  <a id={'statistics'}>
                    <FormattedMessage id={'tabs.statistics'} />
                  </a>
                </li>
              </Permissions.CallToAction>
            ))}
          {checkPermission() &&
            (checkPlanActivityPermissions('View') ? (
              <li onClick={() => onTabCLick('activity')}>
                <a id={'activity'} className={selectedTab === 'activity' ? 'active' : ''}>
                  <FormattedMessage id={'tabs.activity'} />
                </a>
              </li>
            ) : (
              <Permissions.CallToAction isPlan={true} withoutIcon={true}>
                <li>
                  <a id={'activity'}>
                    <FormattedMessage id={'tabs.activity'} />
                  </a>
                </li>
              </Permissions.CallToAction>
            ))}
          {/*       {checkPermission() && checkPlanPermissions("Edit") && (
            <li onClick={() => onTabCLick("settings")}>
              <a id={"settings"} data-toggle="tab" href="#settings_tab">
                <FormattedMessage id={"sidenav.settings"} />
              </a>
            </li>
          )}*/}
        </ul>
      </div>
    </div>
  );
}

function CreateOrganization(props) {
  const [state, dispatch] = useReducer(OrganizationReducer, OrganizationInitialState);
  const store = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    const org = props.redux.organization;
    if (!org.message) {
      window.location = '/workspace';
    }
  }, []);
  return (
    <OrganizationProvider store={store}>
      <Header
        setNavToggle={() => props.redux.setNavBarToggle(!props.redux.globalReducer.navToggle)}
        navToggle={props.redux.globalReducer.navToggle}
        currentPage={<FormattedMessage id={'sidenav.organization'} />}
        profile={props.redux.profile}
        remaining={props.redux.credits.remaining}
        organization={props.redux.organization && props.redux.organization}
      />
      <ContentWrapperUi>
        <SideNav navToggle={props.redux.globalReducer.navToggle} />
        <CreateJoinOrganization profile={props.redux.profile} />
      </ContentWrapperUi>
    </OrganizationProvider>
  );
}

function OrganizationWrapper({ children }) {
  return (
    <Col md={10} className="content-wrap">
      <div className="content-block">
        <div className="inner-content-wrap">
          <div className="wd-wrapper">{children}</div>
        </div>
      </div>
    </Col>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetRemaining: async () => {
      const { data } = await getRemainingCredits();
      dispatch({
        type: reduxActions.GET_REMAINING,
        payload: { remaining: data },
      });

      const { data: dataOrg } = await getOrganization();
      dispatch({ type: reduxActions.GET_ORGANIZATION, payload: dataOrg });

      const { data: dataUser } = await getUser();
      let user = null;
      if (dataOrg.organization) {
        user = dataOrg.organization.members.find((member) => member.email == dataUser.email);
      }
      dispatch({
        type: reduxActions.GET_USER,
        payload: dataUser,
        permissions: user && user.permissions,
        limit: user && user.limit,
      });
    },
  };
};

Organization = connect(null, mapDispatchToProps)(Organization);
export { Organization, CreateOrganization };
