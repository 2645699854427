import { CREDITS, CURRENCY_MAP } from '../../constants';
import cookie from 'react-cookies';

export function capitalize(string) {
  if (string && string.length > 0) {
    let firstLetter = string[0];
    firstLetter = firstLetter.toUpperCase();
    string = string.replace(string[0], firstLetter);
  }
  return string;
}

export function imageExists(url) {
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = function () {
      resolve(true);
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = url;
  });
}

export function debounce(func, delay = 500) {
  let time;
  return (...args) => {
    clearTimeout(time);
    time = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function download_file(data, filename) {
  let blob = new Blob([data], { type: 'text/csv' });
  let URL = window.URL.createObjectURL(blob);
  let tempLink = document.createElement('a');
  tempLink.href = URL;
  tempLink.setAttribute('download', filename);
  tempLink.click();
}
export function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeString(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
export function formatSubscribeInfo(data) {
  const subscription = data.items.filter((item) => item.addedCredits && !item.creditType);
  const subscriptionNextPeriod = data.nextPeriodItems.filter((item) => item.info == 'Subscription');
  const extraCredits = data.items.filter((item) => item.creditType);

  const extraCreditsToUpgrade = data.nextPeriodItems.filter((item) => item.creditType);
  const vat = data.items.find((item) => item.info === 'vat');
  const totalRenew = data.nextPeriodItems.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.qt * currentItem.unitPrice;
  }, 0);
  const nextBillingAmount = data.nextPeriodItems.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.unitPrice;
  }, 0);
  const subscriptionTotal = subscription.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.qt * currentItem.unitPrice;
  }, 0);
  const total = data.items.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.qt * currentItem.unitPrice;
  }, 0);
  const credits = subscriptionNextPeriod.reduce(
    (accumulator, currentItem) => {
      return {
        workEmailCredits: accumulator.workEmailCredits + currentItem.addedCredits.workEmailCredits,
        personalEmailCredits:
          accumulator.personalEmailCredits + currentItem.addedCredits.personalEmailCredits,
        phoneCredits: accumulator.phoneCredits + currentItem.addedCredits.phoneCredits,
        exportCredits: accumulator.exportCredits + currentItem.addedCredits.exportCredits,
      };
    },
    {
      workEmailCredits: 0,
      personalEmailCredits: 0,
      phoneCredits: 0,
      exportCredits: 0,
    },
  );
  return {
    extraCredits,
    extraCreditsToUpgrade,
    vat,
    totalRenew,
    nextBillingAmount,
    subscriptionTotal,
    total,
    credits,
    isUpgrade: data.isUpgrade,
    automaticRenewal: !!data.nextPeriodItems.length,
    remainingMonths: data.remainingMonths,
  };
}
export function calculateRemaining(remaining, credits, type) {
  let totalRemaining = {};

  if (type) {
    for (let creditType of CREDITS) {
      totalRemaining[creditType] =
        remaining[creditType == 'exportCredits' ? 'organizationRemaining' : type][creditType] || 0;
    }
    totalRemaining.isUnlimited =
      credits &&
      credits.personalCredits &&
      credits.personalCredits.recurrenceCredits &&
      credits.personalCredits.recurrenceCredits['workEmailCredits'].isUnlimited;

    totalRemaining.type = type;
    return totalRemaining;
  }

  for (let creditType of CREDITS) {
    totalRemaining[creditType] =
      (remaining.organizationRemaining[creditType] || 0) +
      (remaining.personalCredits[creditType] || 0);
  }
  totalRemaining.isUnlimited =
    credits &&
    credits.personalCredits &&
    credits.personalCredits.recurrenceCredits &&
    credits.personalCredits.recurrenceCredits['workEmailCredits'].isUnlimited;
  return totalRemaining;
}

export function getCurrency(user, customer) {
  let currency = 'EUR';

  if (user && user.currency) currency = user.currency;
  if (customer && customer.currency) currency = customer.currency;

  return CURRENCY_MAP[currency];
}

function formatSbUtmArray(sbjs_name) {
  let sb_utm = cookie.load(sbjs_name, { path: '/', domain: 'kaspr.io' });
  if (!sb_utm) return;
  const utm_map = {
    sbjs_first_mdm: 'firstUtmMediumC',
    sbjs_first_src: 'firstUtmSourceC',
    sbjs_first_cmp: 'firstUtmCampaignC',
    sbjs_first_cnt: 'firstUtmContentC',
    sbjs_first_trm: 'firstUtmTermC',

    sbjs_current_mdm: 'utmMediumC',
    sbjs_current_src: 'utmSourceC',
    sbjs_current_cmp: 'utmCampaignC',
    sbjs_current_cnt: 'utmContentC',
    sbjs_current_trm: 'utmTermC',
  };

  let utm = {};
  let sb_utm_arr = sb_utm.split('|||');
  sb_utm_arr.forEach((sb) => {
    let sb_split = sb.split('=');
    let index = sbjs_name + '_' + sb_split[0];
    let value = decodeURI(sb_split[1]);
    if (utm_map[index]) {
      utm[utm_map[index]] = value;
    }
  });
  return utm;
}

export function getSbUtm() {
  let utm_first = formatSbUtmArray('sbjs_first');
  let utm_current = formatSbUtmArray('sbjs_current');
  let utm = { ...utm_first, ...utm_current };

  // Attempt to load the 'gclid' cookie, defaulting to an empty string if not found
  let gclid = cookie.load('gclid', { path: '/', domain: 'kaspr.io' });
  if (gclid) {
    gclid = gclid.toLowerCase();
  }

  utm.gclid = gclid === 'none' || gclid === 'null' || !gclid ? '' : gclid;

  // Return the utm object if it has any keys, otherwise return null
  return Object.keys(utm).length ? utm : null;
}

export function isAdmin(organization) {
  return organization.member.type === 'admin' || organization.member.type === 'owner';
}

export function getExtraCreditsFromPlan(plan) {
  const extraCredits = [];
  CREDITS.forEach((credit) => {
    if (plan[credit] && plan[credit].extra) {
      extraCredits.push({ amount: plan[credit].extra, creditType: credit });
    }
  });
  return extraCredits;
}

export function calculateLastActive(timestamp) {
  const now = new Date();
  const lastActiveDate = new Date(timestamp);

  const timeDifference = now - lastActiveDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds < 60) {
    return `active ${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `active ${minutes} minutes ago`;
  } else if (hours < 24) {
    return `active ${hours} hours ago`;
  } else {
    const formattedDate = `${lastActiveDate.getDate()} ${new Intl.DateTimeFormat('en-US', {
      month: 'short',
    }).format(lastActiveDate)} ${lastActiveDate.getFullYear()}`;
    return `active on ${formattedDate}`;
  }
}

export function dataDrive({ to, key, state, from }) {
  if (to) {
    sessionStorage.setItem(key, btoa(JSON.stringify(state)));
    window.location = to;
  } else {
    let data = sessionStorage.getItem(key);
    if (!from) {
      sessionStorage.removeItem(key);
      return null;
    }
    if (document.referrer.search(from) === -1) {
      sessionStorage.removeItem(key);
      return null;
    }

    if (!data) return null;
    data = JSON.parse(atob(data));
    sessionStorage.removeItem(key);
    return data;
  }
}
