import React, { useContext, useEffect, useState } from 'react';
import {
  acceptInvitation,
  declineInvitation,
  getOrganization,
  leaveOrganization,
  updateMemberSettings,
} from '../../../../services/api/organization.api';
import { actions, OrganizationContext } from '../../store/Organization.store';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { ADMIN_SETTINGS } from '../../../../constants';

export const useReceivedInvitations = (invitations, hasOrganization, organizationCredits) => {
  const { state, dispatch } = useContext(OrganizationContext);
  const [loading, setLoading] = useState(false);

  const handleAcceptInvitation = async (invitation) => {
    setLoading(true);
    const { data, statusCode } = await acceptInvitation({
      organizationId: invitation.organizationId._id,
      inviter: invitation.inviter,
    });

    if (statusCode === 200) {
      const { data, statusCode } = await getOrganization();
      if (statusCode === 200) {
        dispatch({ type: actions.GET_ORGANIZATION, payload: data });
        setLoading(false);
        window.location = '/workspace';
      }
    } else {
      toast.error(data.message || <FormattedMessage id="organization.anErrorHasOccured" />);
    }
  };

  const handleDeclineInvitation = async (invitation) => {
    const { statusCode } = await declineInvitation({
      organizationId: invitation.organizationId._id,
      inviter: invitation.inviter,
    });

    if (statusCode === 200) {
      dispatch({
        type: actions.SET_ORG_INVITATIONS,
        payload: {
          organizationInvitations: state.organizationInvitations.filter((el) => el !== invitation),
        },
      });
    }
  };

  const handleAcceptClick = (invitation) => {
    const acceptInvitation = () => {
      dispatch({
        type: actions.SET_SELECTED_INVITATION,
        payload: { selectedInvitation: invitation },
      });
      dispatch({
        type: actions.SET_MOVE_WORKSPACE,
        payload: { moveWorkspace: true },
      });
    };

    if (!hasOrganization) {
      handleAcceptInvitation(invitation);
    } else if (hasOrganization && state.isAloneInWorkspace) {
      acceptInvitation();
    } else if (hasOrganization && !state.isAloneInWorkspace) {
      if (state.isAdmin) {
        acceptInvitation();
      } else if (!state.isAdmin) {
        acceptInvitation();
      }
    }
  };

  useEffect(() => {
    dispatch({
      type: actions.SET_ORG_INVITATIONS,
      payload: { organizationInvitations: invitations },
    });

    const fetchOrganization = async () => {
      const { data, statusCode } = await getOrganization();

      if (statusCode === 200) {
        const currentUserEmail = data.member.email;
        const isAdminOrOwner = data.member.type === 'owner' || data.member.type === 'admin';
        const membersData = data.organization.members.map((member) => ({
          email: member.email,
          type: member.type,
        }));

        const organizationMembers = membersData
          .filter((member) => member.type === 'member')
          .map((member) => ({ email: member.email, checked: false }));

        dispatch({
          type: actions.SET_ORG_MEMBERS,
          payload: { orgMembers: organizationMembers },
        });

        if (isAdminOrOwner) {
          dispatch({
            type: actions.SET_IS_ADMIN,
            payload: { isAdmin: true },
          });
          const otherMembers = membersData.filter((member) => member.email !== currentUserEmail);
          const hasOtherOwnerOrAdmin = otherMembers.some((member) =>
            ['owner', 'admin'].includes(member.type),
          );
          dispatch({
            type: actions.SET_HAS_OTHER_OWNER_OR_ADMIN,
            payload: { hasOtherOwnerOrAdmin: hasOtherOwnerOrAdmin },
          });
        } else {
          dispatch({
            type: actions.SET_IS_ADMIN,
            payload: { isAdmin: false },
          });
        }
        dispatch({
          type: actions.SET_ALONE_IN_WORKSPACE,
          payload: { isAloneInWorkspace: data.organization.totalMembers === 1 },
        });
      }
    };

    fetchOrganization();
  }, [invitations, dispatch]);

  const handleSubmit = async () => {
    const { statusCode: adminStatusCode } = await updateMemberSettings({
      settings: ADMIN_SETTINGS,
      userToUpdate: state.selectedAdmins[0],
    });

    if (adminStatusCode === 200) {
      dispatch({
        type: actions.SET_MOVE_WORKSPACE,
        payload: { moveWorkspace: false },
      });
      const { statusCode } = await leaveOrganization();
      if (statusCode === 200) {
        handleAcceptInvitation(state.selectedInvitation);
      }
    }
  };

  return {
    state,
    loading,
    handleAcceptClick,
    handleDeclineInvitation,
    handleSubmit,
    handleAcceptInvitation,
  };
};
