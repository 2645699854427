import { API_URL } from '../../constants';
import { convertToJsonAndHandleErrors } from '../utils';

export function optOut(form) {
  return fetch(`${API_URL}/optOut/request`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form),
    json: true,
  })
    .then((res) => res.json())
    .catch(console.error);
}

export function confirmOptOut(form) {
  return fetch(`${API_URL}/optOut/confirm`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form),
    json: true,
  })
    .then((res) => res.json())
    .catch(console.error);
}
export function optOutFromEmail(key) {
  return fetch(`${API_URL}/optOut/auto`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ key }),
    json: true,
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
}
