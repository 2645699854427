import messages_en from './en.json';
import messages_fr from './fr.json';
import messages_es from './es.json';
import messages_de from './de.json';
import messages_it from './it.json';

Object.keys(messages_en).forEach((key) => {
  messages_en[key] = messages_en[key] && messages_en[key].replace(/\\n/g, '');
  messages_fr[key] = messages_fr[key] && messages_fr[key].replace(/\\n/g, '');
  messages_es[key] = messages_es[key] && messages_es[key].replace(/\\n/g, '');
  messages_de[key] = messages_de[key] && messages_de[key].replace(/\\n/g, '');
});

export { messages_fr, messages_en, messages_de, messages_es, messages_it };
