import React from 'react';
import { Col } from 'react-bootstrap';
function LeadsContentWrapper({ children, navToggle }) {
  return (
    <Col md={'10'} className={'content-wrap ' + (navToggle ? 'expand' : '')}>
      <div className="content-block">
        <div className="inner-content-wrap">{children}</div>
      </div>
    </Col>
  );
}
export default LeadsContentWrapper;
