import React, { useEffect, useRef, useState } from 'react';
import './InputEmailTagsLight.css';
import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { toast, ToastContainer } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { getMembers } from '../../services/api/organization.api';

export default function InputEmailTagsLight({
  onChange,
  profile,
  items,
  placeholder,
  checkValidity,
  remainingInviations,
  tags,
  setTags,
}) {
  const inputRef = useRef(null);
  const tagsRef = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setTags(items);
  }, [items]);

  const handleKeyUp = async (e) => {
    const targetValue = e.target.value.trim();
    onChange([...tags], e.target.value);
    //13 : enter
    //32 : space
    if ((e.keyCode === 32 || e.keyCode === 'Enter' || e.keyCode === 13) && targetValue) {
      e.preventDefault();
      e.stopPropagation();

      const { data, statusCode } = await getMembers({ search: targetValue });
      if (data.members.length > 0 || data.totalMembers === 1) {
        toast.error(<FormattedMessage id="organizations.emailAddressAlreadyBelongsToAUser" />);
        inputRef.current.value = '';
        return;
      }
      let values = [...tags, { value: targetValue, isValid: checkValidity(targetValue) }];
      if (tags.find((vl) => vl.value === targetValue)) {
        inputRef.current.value = '';
        return;
      }
      setTags(values);
      inputRef.current.value = '';
      onChange(values);
    }
    //8 : delete
    if (e.keyCode === 8 && !e.target.value) {
      let newTags = [...tags];
      newTags.pop();
      if (tags.length > 0) {
        setTags([...newTags]);
        onChange([...newTags]);
      } else {
        setTags([]);
        onChange([]);
      }
    }
  };
  const handleBlur = (e) => {
    if (e.target.value) {
      const targetValue = e.target.value.trim();
      setTags([...tags, { value: targetValue, isValid: checkValidity(targetValue) }]);
      inputRef.current.value = '';
      onChange([...tags, { value: targetValue, isValid: checkValidity(targetValue) }]);
    } else {
      if (tags.length) return;
      setTags([]);
      onChange([]);
    }
  };
  const handleClose = (email) => {
    let newTags = [...tags];
    newTags = newTags.map((email, i) => {
      let item = { ...email };
      if (i < remainingInviations + 1) {
        item.requireLicence = false;
      }
      return item;
    });

    newTags = newTags.filter((tag) => tag.value !== email);
    setTags(newTags);
    onChange(newTags);
  };

  return (
    <div id={'input-tags'} onClick={() => setIsFocus(!isFocus)}>
      <div className={'input-tags-list'} ref={tagsRef}>
        {tags.map((tag, i) => (
          <span
            key={i}
            className={
              'input-tag ' +
              (!tag.isValid ? 'invalid' : tag.requireLicence ? 'licence-required' : '')
            }
          >
            <span className={'tag-value'}>
              {!tag.isValid && <img src="/assets/images/warning-icon.svg" alt="" />} {tag.value}
            </span>
            <span className={'close-tag-2'} onClick={() => handleClose(tag.value)}>
              <img src="/assets/images/close-tag.svg" alt="" />
            </span>
          </span>
        ))}
        <span>
          <input
            ref={inputRef}
            type="text"
            name=""
            placeholder={placeholder}
            onKeyUp={handleKeyUp}
            onBlur={handleBlur}
            autoFocus={isFocus}
          />
        </span>
      </div>
    </div>
  );
  return (
    <div id={'input-tags'}>
      <input
        ref={inputRef}
        type="text"
        name=""
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />
      <div className={'input-all-tags'} ref={tagsRef}>
        {tags.length >= 4 && (
          <span className={'input-tag plus'}>{'+' + (tags.length - tags.slice(3, 7).length)}</span>
        )}
        {tags.length < 4
          ? tags.map((tag, i) => (
              <span key={i} className={'input-tag ' + (!tag.isValid ? 'invalid' : '')}>
                <span className={'tag-value'}>
                  {!tag.isValid && <img src="/assets/images/warning-icon.svg" alt="" />} {tag.value}
                </span>
                <span className={'close-tag-2'} onClick={() => handleClose(tag.value)}>
                  <img src="/assets/images/close-tag.svg" alt="" />
                </span>
              </span>
            ))
          : tags
              .map((tag, i) => (
                <span key={i} className={'input-tag ' + (!tag.isValid ? 'invalid' : '')}>
                  <span className={'tag-value'}>
                    {!tag.isValid && <img src="/assets/images/warning-icon.svg" alt="" />}
                    {tag.value}
                  </span>
                  <span className={'close-tag-2'} onClick={() => handleClose(tag.value)}>
                    <img src="/assets/images/close-tag.svg" alt="" />
                  </span>
                </span>
              ))
              .slice(3, 7)}
      </div>
      <div className="show-all-emails">
        {tags.map((tag) => (
          <div> {tag.value} </div>
        ))}
      </div>
    </div>
  );
}
