import React, { useEffect, useRef, useState } from 'react';
import './ConfirmDeleteLead.css';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
function ConfirmDeleteLead({
  onCancel,
  toggle,
  lead,
  onConfirm,
  deleteLeadErrorMessage,
  onCloseProfileSideModal,
  trackingFrom = '',
}) {
  const [show, setShow] = useState(false);
  const [containerAnimation, setContainerAnimation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      setShow(toggle);
    }, 0);
    setContainerAnimation(toggle);
  }, [toggle]);

  useEffect(() => {
    if (deleteLeadErrorMessage) {
      setErrorMessage(deleteLeadErrorMessage);
      setTimeout(() => {
        onCloseProfileSideModal();
      }, 1000);
    }
  }, [deleteLeadErrorMessage]);

  return (
    <div className={'delete-lead-content'}>
      {deleteLeadErrorMessage ? (
        <div>
          {deleteLeadErrorMessage === 'success' ? (
            <span className={'delete-lead-message'}>
              <i className="fas fa-check-circle" />
              <FormattedMessage id={'leads.leadSuccessfullyDeleted'} />
            </span>
          ) : (
            <span className={'delete-lead-message'} style={{ color: '#ca5372' }}>
              {deleteLeadErrorMessage}{' '}
            </span>
          )}
        </div>
      ) : (
        <>
          <h3>
            <FormattedMessage id={'leads.deleteLead'} />
          </h3>
          <p>
            <FormattedMessage id={'leads.pleaseConfirmARemovalOf'} />{' '}
            <strong className={'text-capitalize'}>{lead.firstName + ' ' + lead.lastName}</strong>
          </p>
          <div className={'delete-lead-btns'}>
            <button
              className={'btn btn2'}
              onClick={onCancel}
              data-target-id={trackingFrom + '-cancelDeleteLead'}
            >
              <FormattedMessage id={'cancel'} />
            </button>
            <button
              className={'btn btn1'}
              onClick={onConfirm}
              data-target-id={trackingFrom + '-confirmDeleteLead'}
            >
              <FormattedMessage id={'confirm'} />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ConfirmDeleteLead;
