import {
  CLIENT_ID_AIRCALL,
  CLIENT_ID_HUBSPOT,
  CLIENT_ID_SALESFORCE,
  CLIENT_ID_ZOHO,
} from '../../../constants';

const integrationsDescriptions = {
  hubspot: 'settings.integration.hubspotText',
  salesforce: 'settings.integration.salesforceText',
  pipedrive: 'settings.integration.pipedriveText',
  lemlist: 'settings.integration.lemlistText',
  sendinblue: 'settings.integration.sendinblueText',
  linkedin: 'settings.integration.linkedinText',
  zapier: 'settings.integration.zapierText',
  gmail: 'settings.integration.gmailText',
  zohocrm: 'settings.integration.zohoCrmText',
};

const integrationUpdatedNames = {
  zohocrm: 'zoho',
  hubspot: 'hubspot',
  salesforce: 'salesforce',
  zapier: 'zapier',
  sendinblue: 'brevo',
  pipedrive: 'pipedrive',
  lemlist: 'lemlist',
  linkedin: 'linkedIn',
};

const services = {
  zohocrm: 'oauth',
  hubspot: 'oauth',
  salesforce: 'oauth',
  zapier: 'zapier',
  sendinblue: 'apikey',
  pipedrive: 'apikey',
  lemlist: 'apikey',
  linkedin: 'linkedin',
};

const integrationsDetailedDescriptions = {
  hubspot: {
    name: 'HubSpot',
    quickDescription: 'settings.integration.quickDescriptionHubspot',
    subscriptionDescription: 'settings.integration.TrialForFree',
    exportAvailable: [
      'settings.integration.profiles',
      'settings.integration.lists',
      'settings.integration.workflows',
    ],
    exportType: ['settings.integration.contacts'],
    help: [
      {
        title: 'settings.integration.howToUseKasprWith',
        en: 'https://help.kaspr.io/en/articles/8837107-how-to-use-kaspr-with-hubspot',
        fr: 'https://help.kaspr.io/fr/articles/8837107-exporter-des-contacts-vers-hubspot',
        es: 'https://help.kaspr.io/es/articles/8837107-como-usar-hubspot-con-kaspr',
        de: 'https://help.kaspr.io/de/articles/8837107-so-integrieren-sie-kaspr-in-hubspot',
      },
    ],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsHubspot',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithHubspot',
      },
    ],
    imageSrc: 'hubspot_card',
  },
  salesforce: {
    name: 'Salesforce',
    quickDescription: 'settings.integration.quickDescriptionSalesforce',
    subscriptionDescription: 'settings.integration.TrialForFree',
    exportAvailable: [
      'settings.integration.profiles',
      'settings.integration.lists',
      'settings.integration.workflows',
    ],
    exportType: ['settings.integration.leads', 'settings.integration.contacts'],
    help: [
      {
        title: 'settings.integration.howToUseKasprWith',
        en: 'https://help.kaspr.io/en/articles/8813676-how-to-use-kaspr-with-salesforce',
        fr: 'https://help.kaspr.io/fr/articles/8813676-exporter-des-contacts-vers-salesforce',
        es: 'https://help.kaspr.io/es/articles/8813676-como-usar-salesforce-con-kaspr',
        de: 'https://help.kaspr.io/de/articles/8813676-kontakte-mit-kaspr-in-salesforce-exportieren',
      },
    ],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsSalesforce',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithSalesforce',
      },
    ],
    imageSrc: 'salesforce_card',
  },
  pipedrive: {
    name: 'Pipedrive',
    quickDescription: 'settings.integration.quickDescriptionPipedrive',
    subscriptionDescription: 'settings.integration.TrialForFree',
    exportAvailable: [
      'settings.integration.profiles',
      'settings.integration.lists',
      'settings.integration.workflows',
    ],
    exportType: ['settings.integration.people'],
    help: [
      {
        title: 'settings.integration.howToUseKasprWith',
        en: 'https://help.kaspr.io/en/articles/8837110-how-to-use-kaspr-with-pipedrive',
        fr: 'https://help.kaspr.io/fr/articles/8837110-comment-integrer-kaspr-a-pipedrive',
        es: 'https://help.kaspr.io/es/articles/8837110-como-usar-pipedrive-con-kaspr',
        de: 'https://help.kaspr.io/de/articles/8837110-so-verwenden-sie-kaspr-mit-pipedrive',
      },
    ],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsPipedrive',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithPipedrive',
      },
    ],
    imageSrc: 'pipedrive_card',
  },
  lemlist: {
    name: 'Lemlist',
    quickDescription: 'settings.integration.quickDescriptionLemlist',
    subscriptionDescription: 'settings.integration.TrialForFree',
    exportAvailable: [
      'settings.integration.profiles',
      'settings.integration.lists',
      'settings.integration.workflows',
    ],
    exportType: ['settings.integration.contacts', 'settings.integration.campaigns'],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsLemlist',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithLemlist',
      },
    ],
    imageSrc: 'lemlist_card',
  },
  zohocrm: {
    name: 'Zoho CRM',
    quickDescription: 'settings.integration.quickDescriptionZohocrm',
    subscriptionDescription: 'settings.integration.TrialForFree',
    exportAvailable: [
      'settings.integration.profiles',
      'settings.integration.lists',
      'settings.integration.workflows',
    ],
    exportType: ['settings.integration.prospects'],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsZohocrm',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithZohocrm',
      },
    ],
    imageSrc: 'zoho_card',
  },
  zapier: {
    name: 'Zapier',
    quickDescription: 'settings.integration.quickDescriptionZapier',
    subscriptionDescription: 'settings.integration.fromTheStarterPlan',
    exportAvailable: ['settings.integration.automaticExport'],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsZapier',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithZapier',
      },
    ],
    imageSrc: 'zapier_card',
  },
  sendinblue: {
    name: 'Brevo',
    quickDescription: 'settings.integration.quickDescriptionBrevo',
    subscriptionDescription: 'settings.integration.TrialForFree',
    exportAvailable: [
      'settings.integration.profiles',
      'settings.integration.lists',
      'settings.integration.workflows',
    ],
    exportType: ['settings.integration.contacts', 'leads.lists'],
    sections: [
      {
        title: 'settings.integration.whatIs',
        text: 'settings.integration.whatIsBrevo',
      },
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithBrevo',
      },
    ],
    imageSrc: 'brevo_card',
  },
  linkedin: {
    name: 'Linkedin',
    quickDescription: 'settings.integration.quickDescriptionLinkedin',
    subscriptionDescription: 'settings.integration.TrialForFree',
    availableOn: [
      'workflow.linkedin',
      'billing.actions.SalesNavigator',
      'billing.actions.Recruiter',
    ],
    featuresAvailable: [
      'settings.integration.profile',
      'settings.integration.list',
      'settings.integration.post',
      'settings.integration.group',
      'settings.integration.event',
    ],
    help: [
      {
        title: 'home.onboarding.discoverContact',
        en: 'https://help.kaspr.io/en/articles/8779106-discover-your-first-lead',
        fr: 'https://help.kaspr.io/fr/articles/8779106-decouvrir-votre-premier-contact',
        es: 'https://help.kaspr.io/es/articles/8779106-descubre-tu-primer-prospecto-con-kaspr',
        de: 'https://help.kaspr.io/de/articles/8779106-ermitteln-sie-ihre-ersten-leads',
      },
      {
        title: 'settings.integration.howToEnrichContactsFromALinkedinList',
        en: ' https://help.kaspr.io/en/articles/8808234-how-to-enrich-contacts-from-a-linkedin-list',
        fr: 'https://help.kaspr.io/fr/articles/8808234-comment-enrichir-les-contacts-a-partir-d-une-liste-linkedin',
        es: 'https://help.kaspr.io/es/articles/8808234-como-enriquecer-perfiles-desde-una-lista-en-linkedin',
        de: 'https://help.kaspr.io/de/articles/8808234-so-reichern-sie-kontakte-aus-einer-linkedin-liste-an',
      },
      {
        title: 'settings.integration.howToEnrichContactsFromSalesNavigator',
        en: ' https://help.kaspr.io/en/articles/8807980-how-to-enrich-contacts-from-sales-navigator',
        fr: 'https://help.kaspr.io/fr/articles/8807980-comment-utiliser-kaspr-sur-sales-navigator',
        es: 'https://help.kaspr.io/es/articles/8807980-como-extraer-contactos-a-partir-de-una-lista-de-leads-en-sales-navigator',
        de: 'https://help.kaspr.io/de/articles/8807980-so-exportieren-sie-kontakte-aus-einer-lead-liste-in-linkedin-sales-navigator',
      },
    ],
    sections: [
      {
        title: 'settings.integration.whyYouShouldUseKasprWith',
        text: 'settings.integration.whyUseKasprWithLinkedin',
      },
    ],
    imageSrc: 'linkedin_card',
  },
};

const oauthVariables = {
  clientId: {
    hubspot: CLIENT_ID_HUBSPOT,
    salesforce: CLIENT_ID_SALESFORCE,
    aircall: CLIENT_ID_AIRCALL,
    zohocrm: CLIENT_ID_ZOHO,
  },
  scopes: {
    hubspot: [
      'oauth',
      'crm.objects.contacts.read',
      'crm.objects.contacts.write',
      'crm.schemas.contacts.read',
      'crm.objects.owners.read',
      'crm.schemas.companies.read',
      'crm.schemas.deals.read',
    ],
    zohocrm: [
      'ZohoCRM.modules.ALL',
      'ZohoCRM.users.ALL',
      'ZohoCRM.org.ALL',
      'ZohoCRM.settings.fields.ALL',
    ],
    salesforce: [],
    aircall: ['public_api'],
  },
  oauthUrl: {
    hubspot: 'https://app.hubspot.com/oauth/authorize',
    salesforce: 'https://login.salesforce.com/services/oauth2/authorize',
    zohocrm: 'https://accounts.zoho.com/oauth/v2/auth',
    aircall: 'https://dashboard.aircall.io/oauth/authorize',
  },
};

export {
  integrationsDescriptions,
  services,
  integrationsDetailedDescriptions,
  oauthVariables,
  integrationUpdatedNames,
};
