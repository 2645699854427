import actions from '../actions';

const initState = {};

export const organization = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_ORGANIZATION:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actions.GET_NEW_INVITATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.GET_SUB_ORGANIZATIONS:
      return {
        ...state,
        subOrganizations: action.payload,
      };
  }
  return state;
};
