import React from 'react';

const format = (date, monthly) => {
  // if (date) return  Intl.DateTimeFormat("us-US").format(new Date(date));
  if (date) {
    const dateToFormat = new Date(date);
    return (
      dateToFormat.getFullYear() +
      '-' +
      (dateToFormat.getMonth() + 1) +
      (!monthly ? '-' + dateToFormat.getDate() : '')
    );
  } else return null;
};

const getStartDate = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - 11, date.getDate()).toISOString();

  /*  let firstDay = new Date(year, month, 1);
  return daysOfWeek()[firstDay.getDay()];*/
};

const getEndDate = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();

  /* let lastDay = new Date(year, month + 1, 0);
  return daysOfWeek()[lastDay.getDay()];*/
};

const daysOfMonth = () => {
  let date = new Date();
  let days = [];
  while (date.getMonth()) {
    days.push({ name: format(date).replace(/\//g, '-'), credits: 0 });
    date.setDate(date.getDate() + 1);
  }
  return days;
};
const getDateInterval = ({ start, end, monthly }) => {
  const dates = [];
  start = new Date(start);
  end = new Date(end);
  if (monthly) {
    while (start.getFullYear() <= end.getFullYear() && start.getMonth() <= end.getMonth()) {
      dates.push({
        name: format(start, monthly).replace(/\//g, '-'),
        credits: 0,
      });
      start.setMonth(start.getMonth() + 1);
    }
  } else {
    while (start.getFullYear() <= end.getFullYear() && start.getMonth() <= end.getMonth()) {
      dates.push({ name: format(start).replace(/\//g, '-'), credits: 0 });
      start.setDate(start.getDate() + 1);
    }
  }
  return dates;
};

const formatDateToString = (date) => {
  if (date) {
    return Intl.DateTimeFormat('us-US', {
      dateStyle: 'short',
      timeStyle: 'medium',
    }).format(new Date(date));
  }
};

const mS = () => {
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
};
const formatNotificationsDate = (date) => {
  const notificationDate = new Date(date);
  const now = new Date();
  let mounths = mS();
  let toDay = now.getDate() === notificationDate.getDate();
  let thisHour = now.getHours() === notificationDate.getHours();
  let thisMinute = now.getMinutes() === notificationDate.getMinutes();

  if (toDay && thisHour && thisMinute) {
    return 'Just now';
  }
  if (toDay && thisHour) {
    return now.getMinutes() - notificationDate.getMinutes() + ' Minutes ago';
  }
  if (toDay) {
    return 'Today ' + notificationDate.getHours() + ':' + notificationDate.getMinutes();
  }
  return notificationDate.getDate() + ' ' + mounths[notificationDate.getMonth()];
};
export {
  format,
  getStartDate,
  getEndDate,
  daysOfMonth,
  getDateInterval,
  formatDateToString,
  formatNotificationsDate,
};
