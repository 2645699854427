import {
  handleSetError,
  handleSetIsCustomValue,
  handleSetMappedProperties,
  handleSetMappedValues,
  handleSetPropertiesAutoComplete,
  handleSetPropertyIndex,
  handleSetShowAutoComplete,
  handleSetShowKasprPropertiesAutoComplete,
} from '../handleContextDispatch/handleContextDispatch';
import { mappingIntegrationProperties } from '../../../../../../services/api/integrations.api';

const onChangeKaspr = (e, i, state, dispatch, intl) => {
  handleSetError('', dispatch);
  const regex = new RegExp(e.target.value, 'i');

  handleSetPropertiesAutoComplete(
    Object.keys(state.kasprProperties).filter((property) => {
      if (property.indexOf('customField-') > -1) return false;
      let translationId = 'leads.properties.' + property;
      let translation = intl.formatMessage({ id: translationId });
      return regex.test(translation);
    }),
    dispatch,
  );

  state.mappedProperties[i] = e.target.value;
};

const onChangeIntegration = (e, i, state, dispatch) => {
  handleSetError('', dispatch);
  const regex = new RegExp(e.target.value, 'i');
  handleSetPropertiesAutoComplete(
    Object.values(state.kasprProperties).filter((prop) => prop.match(regex)),
    dispatch,
  );
  state.mappedValues[i] = e.target.value;
};
const handleOnBlur = (state, dispatch) => {
  for (let i in state.mappedValues) {
    if (state.integrationProperties.indexOf(state.mappedValues[i]) === -1)
      state.mappedValues[i] = '';
  }
  handleSetShowAutoComplete(false, dispatch);
};
const addMapping = (e, state, dispatch) => {
  handleSetError('', dispatch);
  let clone = Object.assign([], state.mappedProperties);
  clone.push('');
  handleSetMappedProperties(clone, dispatch);
  clone = Object.assign([], state.mappedValues);
  clone.push('');
  handleSetMappedValues(clone, dispatch);
  clone = Object.assign([], state.isCustomValue);
  clone.push(false);
  handleSetIsCustomValue(clone, dispatch);
};
const handleDeleteMapping = (e, i, state, dispatch) => {
  handleSetError('', dispatch);
  let clone = Object.assign([], state.mappedProperties);
  clone.splice(i, 1);
  handleSetMappedProperties(clone, dispatch);
  clone = Object.assign([], state.mappedValues);
  clone.splice(i, 1);
  handleSetMappedValues(clone, dispatch);
};
const onSelectItem = (type, i, state, dispatch, intl) => (value) => {
  handleSetError('', dispatch);
  switch (type) {
    case 'kaspr':
      if (state.mappedProperties.indexOf(value) >= 0) {
        handleSetShowAutoComplete(false, dispatch);
        handleSetError(intl.formatMessage({ id: 'leads.properties.propertyUsed' }), dispatch);
        return;
      }
      state.mappedProperties[i] = value;
      break;
    case 'integration':
      if (state.mappedValues.indexOf(value) >= 0) {
        handleSetShowAutoComplete(false, dispatch);
        handleSetError(intl.formatMessage({ id: 'leads.properties.propertyUsed' }), dispatch);
        return;
      }
      state.mappedValues[i] = value;
      break;
  }
};
const enableSearch = (e, i, state, dispatch) => {
  e.stopPropagation();
  state.isCustomValue[i] = false;
  handleSetShowAutoComplete(true, dispatch);
  handleSetShowKasprPropertiesAutoComplete(true, dispatch);
  handleSetPropertyIndex(i, dispatch);
};

const disableSearch = (i, state) => () => {
  state.isCustomValue[i] = true;
};
const handleSubmitMapping = async (
  e,
  integration,
  onHide,
  state,
  dispatch,
  intl,
  salesforceTab,
) => {
  if (e) e.stopPropagation();
  let mapping = {};
  let abort = false;
  Object.keys(state.kasprProperties).map((property) => {
    mapping[property] = '';
  });
  state.mappedProperties.map((property, i) => {
    if (!(property && property.length && state.mappedValues[i] && state.mappedValues[i].length)) {
      handleSetError(intl.formatMessage({ id: 'leads.properties.emptyField' }), dispatch);
      return (abort = true);
    }
    if (!state.isCustomValue[i] && Object.keys(state.kasprProperties).indexOf(property) === -1) {
      handleSetError(
        intl.formatMessage({ id: 'leads.properties.unknownProperty' }) + property,
        dispatch,
      );
      return (abort = true);
    }
    if (state.isCustomValue[i]) property = 'customField-' + property;
    mapping[property] = state.mappedValues[i];
  });
  if (abort) {
    handleSetShowAutoComplete(false, dispatch);
    return;
  }

  let mappingToSave = {};
  let customFields = {};
  for (let m in mapping) {
    if (m.indexOf('customField-') === -1) mappingToSave[m] = mapping[m];
    else customFields[m.replace('customField-', '')] = mapping[m];
  }
  mappingToSave.customFields = customFields;
  let body = {
    mappedProperties: mappingToSave,
    integrationName: integration,
  };
  if (integration === 'salesforce') body.sObject = salesforceTab;
  let { data, statusCode } = await mappingIntegrationProperties(body);

  if (statusCode >= 400)
    return handleSetError(
      data.message === 'Permission denied'
        ? intl.formatMessage({ id: 'permissionDenied' })
        : data && (data.messageTranslated || data.message),
      dispatch,
    );

  let keys = [];
  let values = [];
  let customValues = [];
  for (let property in mapping) {
    if (mapping[property] && mapping[property].length) {
      keys.push(property.replace('customField-', ''));
      values.push(mapping[property]);
      customValues.push(property.indexOf('customField-') > -1);
    }
  }
  customValues.push(false);
  handleSetMappedProperties(keys, dispatch);
  handleSetMappedValues(values, dispatch);
  handleSetIsCustomValue(customValues, dispatch);
  onHide();
  handleSetError('', dispatch);
};

export {
  onChangeKaspr,
  onChangeIntegration,
  handleOnBlur,
  addMapping,
  handleDeleteMapping,
  onSelectItem,
  enableSearch,
  disableSearch,
  handleSubmitMapping,
};
