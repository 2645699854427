import React, { useEffect, useState } from 'react';
import './CompanyInfos.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import ItemsList from '../ItemList/ItemList';
import AddressEdit from './AddressEdit/AddressEdit';
import EditableField from '../EditableField/EditableField';
function CompanyInfos({ companyInfo, intl, onChange }) {
  const [editCompanyToggle, setEdiCompanyToggle] = useState(null);
  const [companyFields, setCompanyFields] = useState(companyInfo);

  useEffect(() => {
    if (companyInfo) {
      setCompanyFields(companyInfo);
    }
  }, [companyInfo]);
  const onFieldsChange = ({ value, property, index }) => {
    let companyFieldsCopy = Object.assign({}, companyInfo);
    if (index === -1) {
      companyFieldsCopy[property] = [...companyFieldsCopy[property], value];
    }
    if (index !== undefined) {
      if (value === null) {
        companyFieldsCopy[property] = companyFieldsCopy[property].filter((item, i) => i !== index);
      }
      companyFieldsCopy[property] = companyFieldsCopy[property].map((field, i) => {
        if (index == i) return value;
        else return field;
      });
    } else {
      companyFieldsCopy[property] = value;
    }
    setCompanyFields(companyFieldsCopy);
    onChange(companyFieldsCopy);
  };
  if (!companyFields) return <div />;
  const getProperties = () => {
    let companyProperties = [
      'name',
      'specialities',
      'industryName',
      'followerCount',
      'staffCount',
      'foundedOn',
      'rawAddresses',
      'description',
    ];
    let fields = companyProperties.map((field) => {
      return {
        property: intl.formatMessage({
          id: 'leads.properties.companyInfo.' + field,
        }),
        propertyWithoutTranslation: field,
        value: companyFields[field],
      };
    });

    return fields;
  };

  const COMPANY_INFOS = getProperties();
  return (
    <Wrapper
      companyName={companyFields.name}
      companyPageUrl={companyFields.companyPageUrl}
      companyDomain={companyFields.domains ? companyFields.domains[0] : ''}
    >
      <div className={'about-contact row'}>
        {COMPANY_INFOS.map((info, i) => (
          <FieldInfos
            key={i}
            title={info.property}
            value={info.value}
            property={info.propertyWithoutTranslation}
            editEditToggle={editCompanyToggle}
            setEdiCompanyToggle={setEdiCompanyToggle}
            onFieldsChange={onFieldsChange}
          />
        ))}
      </div>
    </Wrapper>
  );
}
function FieldInfos({
  title,
  value,
  setEdiCompanyToggle,
  editEditToggle,
  onFieldsChange,
  property,
}) {
  const [moreInfo, setMoreInfo] = useState(null);
  return (
    <div className={Array.isArray(value) || property === 'description' ? 'col-md-12' : 'col-md-6'}>
      <div className="form-group">
        <label>{title}</label>
        {Array.isArray(value) ? (
          <ul className="data-listing">
            {property === 'rawAddresses' ? (
              <AddressEdit
                data={value}
                setEdiCompanyToggle={setEdiCompanyToggle}
                editEditToggle={editEditToggle}
                property={property}
                onChange={onFieldsChange}
              />
            ) : value === undefined ? (
              <div />
            ) : (
              <ItemsList
                data={value}
                moreItems={moreInfo}
                showMoreItems={setMoreInfo}
                setEditLeadToggle={setEdiCompanyToggle}
                editLeadToggle={editEditToggle}
                property={property}
                onChange={onFieldsChange}
              />
            )}
          </ul>
        ) : (
          <EditableField
            toggleValue={property}
            setToggle={setEdiCompanyToggle}
            toggle={editEditToggle}
            editIconStyle={{ top: -13, right: 0 }}
            property={property}
            onChange={onFieldsChange}
            value={value}
            inputType={property == 'staffCount' || property == 'followerCount' ? 'number' : 'text'}
          />
        )}
      </div>
    </div>
  );
}
function Wrapper({ children, companyName, companyPageUrl, companyDomain }) {
  return (
    <div className={'company-infos'}>
      <div className={'head-block'}>
        <hr />
        <div className={'company-name-container'}>
          <p>
            {companyDomain && (
              <span className={'img-container'}>
                <img src={'https://logo.clearbit.com/' + companyDomain} alt="" />
              </span>
            )}{' '}
            {companyName}{' '}
          </p>
          <a href={companyPageUrl} target={'_blank'}>
            <FormattedMessage id={'leads.viewWebsite'} />
          </a>
        </div>
      </div>
      <div className={'about-contact'}>{children}</div>
    </div>
  );
}

export default injectIntl(CompanyInfos);
