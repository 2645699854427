import React from 'react';
import actions from './home.actions';

const HomeContext = React.createContext();

const homeInitialState = {
  toggle: false,
  credits: null,
  leads: null,
  tasks: null,
  remaining: null,
  workflows: null,
  notifications: null,
};

const homeReducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE:
      return {
        ...state,
        toggle: !state.toggle,
      };
    case actions.GET_CREDITS:
      return {
        ...state,
        credits: action.payload,
      };
    case actions.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
      };
    case actions.GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case actions.GET_WORKFLOWS:
      return {
        ...state,
        workflows: action.payload,
      };
    case actions.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case actions.GET_REMAINING:
      return {
        ...state,
        remaining: action.payload,
      };
    default:
      return state;
  }
};

export { HomeContext, homeInitialState, homeReducer, actions };
