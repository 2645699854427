const actions = {
  GET_USER: 'GET_USER',
  NAVBAR_TOGGLE: 'NAVBAR_TOGGLE',
  GET_LEADS: 'GET_LEADS',
  GET_LABELS: 'GET_LABELS',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_CREDITS: 'GET_CREDITS',
  GET_MEMBER: 'GET_MEMBER',
  GET_NEW_INVITATIONS: 'GET_NEW_INVITATIONS',
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  GET_REMAINING: 'GET_REMAINING',
  IS_USER_BLOCKED: 'IS_USER_BLOCKED',
  IS_PLAN_SUSPENDED: 'IS_PLAN_SUSPENDED',
  SET_LANGUAGE: 'SET_LANGUAGE',
  PLAN_PERMISSIONS: 'PLAN_PERMISSIONS',
  MEMBER_PERMISSIONS: 'MEMBER_PERMISSIONS',
  GET_SUB_ORGANIZATIONS: 'GET_SUB_ORGANIZATIONS',
};

export default actions;
