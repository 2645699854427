import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../../components/CustomToolTip/CustomToolTip';

export function LaunchAutomation({ organization }) {
  return (
    <div className={'onboarding-content '}>
      <p>
        <FormattedMessage
          id={'home.onboarding.LaunchAnAutomationDesc'}
          defaultMessage={
            'Unlock the full potential of Kaspr! send automatic invitations, messages and enrich hundreds of contacts with email and phone numbers.'
          }
          values={{ br: <br /> }}
        />
      </p>
      <div className="video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/XwMllD0iUWY"
          title="Launch your first automation"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {!organization ? (
        <CustomToolTip
          element={<FormattedMessage id={'organization.youHavetoCreateAnOrganization'} />}
        >
          <button className="btn btn1" style={{ opacity: 0.5, pointerEvents: 'none' }}>
            {' '}
            <FormattedMessage
              id={'home.onboarding.startAnAutomation'}
              defaultMessage={'Launch an Enrichment Automation'}
            />
          </button>
        </CustomToolTip>
      ) : (
        <a
          className="btn btn1"
          target={'_blank'}
          href={
            'https://www.linkedin.com/search/results/people/?keywords=ceo&origin=SWITCH_SEARCH_VERTICAL&sid=DG('
          }
          rel="noopener noreferrer nofollow"
        >
          <FormattedMessage
            id={'home.onboarding.startAnAutomation'}
            defaultMessage={'Launch an Enrichment Automation'}
          />
        </a>
      )}
    </div>
  );
}
