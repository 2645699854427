// utils.js
const tooltipIds = {
  0: 'organization.freePlanTooltip',
  plan_1: 'organization.starterPlanTooltip',
  plan_2: 'organization.businessPlanTooltip',
  plan_3: 'organization.organizationPlanTooltip',
};

const planIncludes = {
  0: 'billing.plan.freePlanIncludes',
  plan_1: { id: 'billing.plan.everythingInXPlus', value: 'Free' },
  plan_2: { id: 'billing.plan.everythingInXPlus', value: 'Starter' },
  plan_3: { id: 'billing.plan.everythingInXPlus', value: 'Business' },
};

export { tooltipIds, planIncludes };
