import React from 'react';
import { SettingsContext } from './Settings.store';
import { PropTypes } from 'prop-types';

function SettingsProvider({ children, store }) {
  return (
    <SettingsContext.Provider value={store}>
      <main className="settings">{children}</main>
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default SettingsProvider;
