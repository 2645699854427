import React from 'react';
import actions from './billing.actions';

const BillingContext = React.createContext();

const billingInitialState = {
  toggle: false,
  plans: null,
  selectedPlan: null,
  checkoutForm: {
    companyName: '',
    address: '',
    country: 'France',
    state: 'Île-de-France',
    vat: '',
    email: '',
    source: null,
  },
  countryStates: [],
  pack: null,
  profile: null,
  invoices: null,
  organization: null,
  subscribeInfoError: null,
};

const billingReducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE:
      return {
        ...state,
        toggle: !state.toggle,
      };
    case actions.GET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case actions.GET_SUBSCRIPTION_INFOS:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case actions.ON_CHECKOUT_FORM:
      return {
        ...state,
        checkoutForm: action.payload,
      };
    case actions.ON_CREDIT_PACKS:
      return {
        ...state,
        pack: action.payload,
      };
    case actions.GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case actions.GET_PAYMENT_HISTORY:
      return {
        ...state,
        invoices: action.payload,
      };
    case actions.GET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case actions.SET_COUNTRY_STATES:
      return {
        ...state,
        countryStates: action.payload,
      };
    case actions.SUBSCRIPTION_INFOS_ERROR:
      return {
        ...state,
        subscribeInfoError: action.payload,
      };
    default:
      return state;
  }
};

export { BillingContext, billingInitialState, billingReducer, actions };
