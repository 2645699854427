import React from 'react';
import { FormattedMessage } from 'react-intl';
import './BillingNavBar.css';

const BillingNavBar = () => {
  return (
    <div id="billing-nav-bar">
      <a href="/billing">
        <FormattedMessage id="billing.upgradeAndManage" />
      </a>
      <img src="/assets/images/chevron-right.png" alt="chevron right" />
      <p className="customize">
        <FormattedMessage id="billing.customizeYourPlan" />
      </p>
      <img src="/assets/images/chevron-right.png" alt="chevron right" />
      <p>
        <FormattedMessage id="billing.checkout" />
      </p>
    </div>
  );
};

export default BillingNavBar;
