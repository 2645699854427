import React, { useEffect, useState } from 'react';
import { getKasprInvitations } from '../../../services/api/auth.api';
import { FormattedMessage } from 'react-intl';

export function InvitesLeft({ total, accepted, isGold }) {
  const [dots, setDots] = useState([]);
  useEffect(() => {
    let arr = [...Array(total).map(() => 0)];
    arr = arr.map((dot, index) => {
      if (index < accepted) {
        dot = 1;
      }
      return dot;
    });
    setDots(arr);
  }, []);
  return (
    <div className="invites-left">
      <div className="dots">
        {dots.map((dot, i) => (
          <span key={i} style={dot ? { background: isGold ? '#fff' : '#555' } : {}}></span>
        ))}
      </div>
      <small>
        {total - accepted}{' '}
        <FormattedMessage id={'home.onboarding.invitesLeft'} defaultMessage={'Invites left'} />
      </small>
      {/*
      {isGold && <i className="fas fa-medal"></i>}
*/}
    </div>
  );
}
