import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Animation } from '../../../../../components';
import { actions, LeadsContext } from '../../../store/leads.store';
import { toast, ToastContainer } from 'react-toastify';
import { getLeadFeatures, updateLead } from '../../../../../services/api/leads.api';
import { checkPermission } from '../../../../../services/utils';
import './AddToList.css';
function AddToList({
  setSelectToggle,
  selectToggle,
  lead,
  intl,
  style,
  profilePermissions,
  organizationPlanPermissions,
}) {
  const { state, dispatch } = useContext(LeadsContext);
  const memberPermission = checkPermission({
    permissions: profilePermissions,
    name: 'Leads',
    action: 'Export',
  });
  const planPermission = checkPermission({
    permissions: organizationPlanPermissions,
    name: 'Leads',
    action: 'Export',
  });
  const handleAddToLabel = async (label) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    const { data, statusCode } = await updateLead([lead], label.id);
    if (statusCode === 200) {
      //setSelectedLead
      lead.list = label.id ? label.name : intl.formatMessage({ id: 'leads.defaultList' });
      dispatch({ type: actions.SELECT_LEAD, payload: lead });
      const { data: featuresData } = await getLeadFeatures({
        leadId: lead._id,
      });
      dispatch({ type: actions.LEAD_DETAILS, payload: featuresData });
      setSelectToggle(false);
    }
  };
  return (
    <>
      <div className="cstm-dropdown add-to-list " style={{ ...style }}>
        <div className={'custom-title'}>
          <FormattedMessage id={'leads.properties.list'} />
        </div>

        <div className="dropdown">
          <button
            className="dropdown-toggle select-button "
            onClick={() => setSelectToggle('addToList')}
            style={{ minWidth: 'max-content', height: 36, fontSize: 14 }}
            //disabled={planIsLimitedPermission}
          >
            <p style={{ width: 'max-content', fontSize: 14 }}>
              {lead.list ? lead.list : <FormattedMessage id={'leads.defaultList'} />}{' '}
            </p>
          </button>
          <Animation.SlideDown
            className={'dropdown-menu show'}
            toggle={selectToggle == 'addToList'}
            setToggle={setSelectToggle}
          >
            <ul>
              <li
                onClick={() =>
                  handleAddToLabel({
                    name: <FormattedMessage id={'leads.defaultList'} />,
                    id: null,
                  })
                }
              >
                <a
                  href="#"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '10px 0',
                  }}
                >
                  <span className="name_ui">
                    {' '}
                    <FormattedMessage id={'leads.defaultList'} />
                  </span>
                </a>
              </li>
              {state.labels.map((label, i) => (
                <li key={i} onClick={() => handleAddToLabel(label)}>
                  <a
                    href="#"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '10px 0',
                    }}
                  >
                    <span className="name_ui">{label.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </Animation.SlideDown>
        </div>
      </div>
      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </>
  );
}
export default injectIntl(AddToList);
