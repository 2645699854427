import React, { useState } from 'react';
import './JoinWorkspaceNonExistingDomain.css';
import InputField from '../../../../../components/InputField/InputField';
import { createOrganization } from '../../../../../services/api/organization.api';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

export default function JoinWorkspaceNonExistingDomain({ userDomain }) {
  const [workspaceName, setWorkspaceName] = useState(
    `${userDomain.split('.')[0].charAt(0).toUpperCase() + userDomain.split('.')[0].slice(1)}`,
  );
  const [allowAutoJoin, setAllowAutoJoin] = useState(true);

  const handleWorkspaceNameChange = (event) => {
    setWorkspaceName(event.target.value);
  };

  //  Create a new organization
  const handleCreateOrganization = async () => {
    if (workspaceName) {
      const organization = {
        name: workspaceName,
        domains: [userDomain],
        autoJoin: allowAutoJoin,
      };
      const formData = new FormData();

      formData.append('picture', null);
      formData.append('organization', JSON.stringify(organization));
      const { data, statusCode } = await createOrganization(formData);
      if (statusCode === 200) {
        window.location = '/welcome';
      } else {
        toast.error(data.messageTranslated || data.message);
      }
    }
  };

  return (
    <div id="join-wk-no-domain-ctn">
      <h1>
        <FormattedMessage id={'signupWk.chooseANameForYourWk'} />
      </h1>
      <p>
        <FormattedMessage id={'signupWk.chooseANameForYourWkDesc'} />
      </p>
      <div className="domain-input-field">
        <InputField
          label={<FormattedMessage id={'organization.organizationName'} />}
          placeholder={<FormattedMessage id={'signupWk.domain'} />}
          onChange={handleWorkspaceNameChange}
          userDomain={userDomain}
          value={workspaceName}
        />
        <div className="checkbox-wrap">
          <label>
            <input
              type="checkbox"
              className="form-check-input"
              id="domainEmailCheck"
              checked={allowAutoJoin}
              onChange={() => setAllowAutoJoin(!allowAutoJoin)}
            />
            <span className="checkbox-icon" />

            <div className="checkbox-text " style={{ marginLeft: '30px' }}>
              <FormattedMessage
                id="signupWk.allowEmailDomainJoin"
                values={{ userDomain: <span className="email-domain">@{userDomain}</span> }}
              />
            </div>
          </label>
        </div>
      </div>
      <button
        className={`btn1 ${!workspaceName ? 'disabled' : ''}`}
        onClick={handleCreateOrganization}
        disabled={!workspaceName}
      >
        <FormattedMessage id="signupWk.continue" />
      </button>
    </div>
  );
}
