import cookie from 'react-cookies';
import { BASE_URL } from '../../constants';

export const saveAsCookie = (token) => {
  const cookieExpireDate = new Date();
  cookieExpireDate.setTime(cookieExpireDate.getTime() + 39460601000);
  cookie.save('access_token', token, {
    path: '/',
    expires: cookieExpireDate,
    domain: BASE_URL,
  });
};
