import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 !== 0 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <IconContext.Provider value={{ color: 'gold', size: '16px' }}>
      <div id="star-rating">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStars === 1 && <FaStarHalfAlt />}
        {[...Array(emptyStars)].map((_, index) => (
          <FaStar key={index + fullStars + halfStars} style={{ color: '#ddd' }} />
        ))}
      </div>
    </IconContext.Provider>
  );
};

export default StarRating;
