import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Permissions } from '../../../../../components';
import { Col } from 'react-bootstrap';

function ZapierIntegrationCard({
  category,
  name,
  imagesrc,
  account,
  text,
  disabled,
  moreInfoLink,
  apiKeyLink,
  handleConnectionModal,
  setSelectedIntegration,
  checkMemberPermissions,
}) {
  const selectIntegration = () => {
    if (!disabled)
      setSelectedIntegration({
        integration: name,
        category,
        imagesrc,
        account,
        text,
        disabled,
        moreInfoLink,
        apiKeyLink,
      });
  };

  return (
    <Col sm={6} md={6} lg={4}>
      <div
        className={'integrations-card ' + (disabled ? 'disabled-card' : '')}
        onClick={(e) => {
          if (!disabled) {
            selectIntegration();
            handleConnectionModal(e);
          }
        }}
      >
        {/* HEADER*/}
        <div className="top-block">
          <div className="left-ui">
            <label>{category}</label>
            <p className="title username">{name}</p>
          </div>
          <div className="right-ui">
            <figure>
              <img src={imagesrc} alt="" />
            </figure>
          </div>
        </div>

        {/* BODY */}
        <div className="middle-content-ui">
          <p>{text}</p>
        </div>

        {/* FOOTER */}
        {!disabled && (
          <div className="bottom-ui">
            {account ? (
              <div className="connect-btn active">{account}</div>
            ) : !checkMemberPermissions('Sync') ? (
              <Permissions.CallToAction withoutIcon={true} toolTipStyle={{ width: 200 }}>
                <div />
              </Permissions.CallToAction>
            ) : (
              <div />
            )}

            <button className="more-info-ui">
              {' '}
              <FormattedMessage id={'info.moreInfo'} />
            </button>
          </div>
        )}
      </div>
    </Col>
  );
}

export default ZapierIntegrationCard;
