import { number } from 'prop-types';

const discount = 0.25;

export const priceCalc = ({ price, yearly, seats = 1 }) => {
  if (yearly) {
    return price * 12 * seats;
  } else {
    return price * seats;
  }
};
const ANNUAL_PRICES_PER_SEAT = {
  plan_1: 45,
  plan_2: 79,
  plan_3: 99,
  plan_11: 99,
  plan_12: 45,
};
export const pricePlanCalc = ({ price, yearly, perYear, seats = 1, withoutDiscount, planId }) => {
  if (!ANNUAL_PRICES_PER_SEAT[planId]) {
    return price;
  }
  if (planId && planId !== '0') {
    if (yearly) {
      if (perYear) {
        return ANNUAL_PRICES_PER_SEAT[planId] * 12 * seats;
      }
      return ANNUAL_PRICES_PER_SEAT[planId] * seats;
    } else {
      return price * seats;
    }
  } else {
    if (yearly) {
      if (perYear) {
        return (price - (withoutDiscount ? 0 : price * discount)) * 12 * seats;
      }
      return (price - (withoutDiscount ? 0 : price * discount)) * seats;
    } else {
      return price * seats;
    }
  }
};
export const calculateCredits = ({ users, isYearly, credits }) => {
  return (users || 1) * credits * (isYearly ? 12 : 1);
  //return users * credits;
};
export const calculateExtraCreditsPrice = ({ creditType, amount, isAnnual, isUnitPrice }) => {
  const unitPrices = {
    personalEmailCredits: isAnnual
      ? {
          2500: 16,
          5000: 15,
          10000: 14,
          25000: 13,
          50000: 12,
          100000: 11,
          250000: 10,
        }
      : {
          250: 16,
          500: 15,
          1000: 14,
          2500: 13,
          5000: 12,
          10000: 11,
        },
    phoneCredits: isAnnual
      ? {
          2500: 35,
          5000: 30,
          10000: 28,
          25000: 25,
          50000: 22,
          100000: 20,
          250000: 18,
        }
      : {
          250: 35,
          500: 30,
          1000: 28,
          2500: 25,
          5000: 22,
          10000: 20,
        },
    exportCredits: isAnnual
      ? {
          5000: 10,
          10000: 10,
          25000: 8,
          50000: 5,
          100000: 3,
          250000: 2,
          1000000: 1.5,
        }
      : {
          500: 10,
          1000: 10,
          2500: 8,
          5000: 5,
          10000: 3,
          25000: 2,
          100000: 1.5,
        },
  };
  const price = amount && (amount * unitPrices[creditType][amount]) / 100;

  let extraCredits = {};
  let keys = Object.keys(unitPrices[creditType]);
  let totalItems = keys.length;
  keys.unshift('0');
  keys.forEach((key, index) => {
    let amnt = parseInt(key);
    extraCredits[index] = {
      amount: key,
      price: amnt && (amnt * unitPrices[creditType][amnt]) / 100,
    };
  });
  if (isUnitPrice) return unitPrices[creditType][amount];

  return { amount: price, extraCredits, totalItems };
};
