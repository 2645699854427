import React, { useContext, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { createLabel, getLabels, editLabel } from '../../../../services/api/labels.api';
import { actions, LeadsContext } from '../../store/leads.store';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

export default function CreateNewListModal({
  toggle,
  setToggle,
  label,
  tracking = {},
  setCurrentToggle,
}) {
  const { state, dispatch } = useContext(LeadsContext);
  const [labelName, setLabelName] = useState((label && label.name) || '');
  const [creationLoading, setCreationLoading] = useState(false);

  const [error, setError] = useState('');
  const intl = useIntl();
  const handleCreateLabel = async () => {
    if (creationLoading) return;
    setCreationLoading(true);
    const { data, statusCode } = await createLabel(labelName);
    if (statusCode == 200) {
      const labelData = await getLabels();
      dispatch({ type: actions.GET_LABELS, payload: labelData.labels });
      toast.success(
        intl.formatMessage({
          id: 'leads.listSuccessfullyCreated',
          defaultMessage: 'List successfully created',
        }),
      );
      setToggle(false);
      setCurrentToggle('labels');
    } else {
      setError(data.messageTranslated || data.message);
    }
    setCreationLoading(false);
  };

  const handleEditLabel = async () => {
    const { data, statusCode } = await editLabel({
      labelId: label.id,
      name: labelName,
    });
    if (statusCode == 200) {
      getLabels().then((data) => {
        dispatch({ type: actions.GET_LABELS, payload: data.labels });
      });
      setToggle(false);
      setCurrentToggle('labels');
    } else {
      setError(data.messageTranslated || data.message);
    }
  };
  return (
    <Modal show={toggle} onHide={() => setToggle(false)} className="fade create-new-modal">
      <Modal.Header closeButton className="modal-header border-0">
        <div className="modal-inner-header">
          <h4 className="title">
            {label ? (
              <FormattedMessage id={'editList'} />
            ) : (
              <FormattedMessage id={'createNewList'} />
            )}
          </h4>
        </div>
      </Modal.Header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          label ? handleEditLabel() : handleCreateLabel();
        }}
      >
        <Modal.Body className="modal-body p-0">
          <div className="inner-content">
            <div className="form-group">
              <label>
                {label ? (
                  <FormattedMessage id={'editList'} />
                ) : (
                  <FormattedMessage id={'home.leads.enterListNameToSave'} />
                )}
              </label>
              <input
                autoFocus={true}
                type="text"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
              />
            </div>
          </div>
          {error && (
            <div
              className="btn-grp text-center  error  kaspr-form-text-error"
              style={{ top: 7, width: '90%' }}
            >
              {error + ' '}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer border-0">
          <button
            className="btn2 mr14"
            type={'button'}
            onClick={() => setToggle(false)}
            data-tracking-id={tracking.cancel}
          >
            <FormattedMessage id={'cancel'} />
          </button>
          <button
            className="btn1 "
            type={'submit'}
            data-tracking-id={tracking.action}
            style={labelName ? {} : { opacity: 0.5 }}
          >
            {label ? <FormattedMessage id={'edit'} /> : <FormattedMessage id={'create'} />}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
