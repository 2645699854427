import React from 'react';
import { FormattedMessage } from 'react-intl';

function PreLoader({ style }) {
  return (
    <div id="preloader" style={style}>
      <div className="loader-inner-wrapper">
        <img className="loader-icon" src="/assets/images/logo_round.png" alt="" />
        <p className="loader-icon" style={{ color: '#ccc', width: 100 }}>
          <FormattedMessage id={'loading'} />
        </p>
      </div>
    </div>
  );
}
export default PreLoader;
