import React, { useContext, useEffect, useState } from 'react';
import Graphs from './Graphs/Graphs';
import UsageByMember from './UsageByMember/UsageByMember';
import { getActivity, getCreditsHistory, getRemaining } from '../../../services/api/credits.api';
import { PreLoader } from '../../../components';

import { actions, OrganizationContext } from '../store/Organization.store';
import * as Permissions from '../../../components/Permissions/Permissions';
import { usePermissions } from '../../../components/Permissions/permissions.hooks';
import CreditsHistory from './CreditsHistory/CreditsHistory';
export default function Statistics({
  organization,
  checkPlanReportsPermissions,
  checkPlanActivityPermissions,
  isVersion2,
}) {
  const { state, dispatch } = useContext(OrganizationContext);
  useEffect(() => {
    if (checkPlanReportsPermissions('View')) {
      const emails =
        state.organization &&
        state.organization.members &&
        state.organization.members.length &&
        state.organization.members.map((member) => member.email);
      getCreditsHistory({
        monthly: true,
        startDate: organization.createdAt,
        endDate: new Date(),
        creditTypes: !isVersion2 ? ['legacyCredits'] : undefined,
        emails,
      }).then(({ data, statusCode }) => {
        if (statusCode == 200) {
          dispatch({
            type: actions.GET_DATA_CHART,
            payload: {
              dataChart: data.usagesDateCountDocuments,
              usageByMember: data.usagesMemberCountDocuments,
            },
          });
          dispatch({
            type: actions.CHART_FILTERS,
            payload: {
              chartFilters: {
                ...state.chartFilters,
                startDate: organization.createdAt,
                minDate: organization.createdAt,
                endDate: new Date(),
                emails,
              },
            },
          });
        } else {
          dispatch({
            type: actions.GET_DATA_CHART,
            payload: { dataChart: 'not_allowed' },
          });
        }
      });
      getRemaining().then(({ data }) => {
        dispatch({
          type: actions.GET_REMAINING,
          payload: { remaining: data },
        });
      });

      getActivity().then(({ data }) => {
        dispatch({
          type: actions.GET_ACTIVITY,
          payload: { activity: data },
        });
      });
    }
  }, []);
  if (!checkPlanReportsPermissions('View'))
    return (
      <Permissions.View title={'Title'} description={'Description'} img={'statistic-bg.png'} />
    );
  if (state.dataChart == 'not_allowed' || state.remaining == 'not_allowed') {
    return <div />;
  }
  if (!state.dataChart || !state.remaining) return <PreLoader />;

  return (
    <div id="statistics_tab" className="tab-pane active">
      <Graphs
        dataChart={state.dataChart}
        checkPlanReportsPermissions={checkPlanReportsPermissions}
        isVersion2={isVersion2}
      />
      <UsageByMember isVersion2={isVersion2} />
      <CreditsHistory checkPlanActivityPermissions={checkPlanActivityPermissions} />
    </div>
  );
}
