import { FormattedMessage } from 'react-intl';
import React from 'react';
import CurrencyDisplay from '../../../../../components/CurrencyDisplay/CurrencyDisplay';

export default function SubscriptionInfo({ plan, currentPlan, user, organizationCredits }) {
  return (
    <>
      <div className="card-detail-ui order-summary">
        <div className="form-group">
          <span className="line-title">
            {' '}
            <FormattedMessage id={'billing.subscription'} />{' '}
          </span>
          <div className={'fields-wrapper'}>
            <div className={'section-info'}>
              <span className={'key-txt'}>
                <FormattedMessage id={'billing.plan'} />
              </span>{' '}
              <span className={'tag-info-container'}>
                <span className={'value-txt'}>{currentPlan.name}</span>{' '}
                {currentPlan.planId !== plan.planId && (
                  <>
                    {plan.isUpgrade.subscription ? (
                      <span className={'value-txt isUpgrade'}>
                        <img src="/assets/images/upgrade-arrow.svg" alt="" /> {plan.name}
                      </span>
                    ) : (
                      <span className={'value-txt isDowngrade'}>
                        <img src="/assets/images/downgrade-arrow.svg" alt="" /> {plan.name}
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
            <div className={'section-info'}>
              <span className={'key-txt'}>
                <FormattedMessage id={'billing.type'} />
              </span>{' '}
              <span className={'tag-info-container'}>
                <span className={'value-txt'}>
                  {currentPlan.numberOfMonths == 12 ? (
                    <FormattedMessage id={'billing.annual'} />
                  ) : (
                    <FormattedMessage id={'billing.monthly'} />
                  )}
                </span>
                {plan.isUpgrade.subscription !== null &&
                  currentPlan.numberOfMonths !== plan.numberOfMonths && (
                    <>
                      {currentPlan.numberOfMonths < plan.numberOfMonths ? (
                        <span className={'value-txt isUpgrade'}>
                          <img src="/assets/images/upgrade-arrow.svg" alt="" />{' '}
                          {plan.numberOfMonths == 12 ? (
                            <FormattedMessage id={'billing.annual'} />
                          ) : (
                            <FormattedMessage id={'billing.monthly'} />
                          )}
                        </span>
                      ) : (
                        <span className={'value-txt isDowngrade'}>
                          <img src="/assets/images/downgrade-arrow.svg" alt="" />{' '}
                          {plan.numberOfMonths == 12 ? (
                            <FormattedMessage id={'billing.annual'} />
                          ) : (
                            <FormattedMessage id={'billing.monthly'} />
                          )}
                        </span>
                      )}
                    </>
                  )}{' '}
              </span>
            </div>
            <div className={'section-info'}>
              <span className={'key-txt'}>
                <FormattedMessage id={'users'} />
              </span>{' '}
              <span className={'tag-info-container'}>
                {currentPlan.planId !== '0' && (
                  <span className={'value-txt'}>
                    {currentPlan.numberOfSeats} <FormattedMessage id={'billing.users'} />
                  </span>
                )}{' '}
                {plan.isUpgrade.subscription !== null &&
                  (plan.numberOfSeats !== currentPlan.numberOfSeats ||
                    (currentPlan.planId == '0' &&
                      plan.numberOfSeats == currentPlan.numberOfSeats)) && (
                    <>
                      {plan.isUpgrade.subscription ? (
                        <span className={'value-txt isUpgrade'}>
                          <img src="/assets/images/upgrade-arrow.svg" alt="" /> {plan.numberOfSeats}{' '}
                          <FormattedMessage id={'billing.users'} />
                        </span>
                      ) : (
                        <span className={'value-txt isDowngrade'}>
                          <img src="/assets/images/downgrade-arrow.svg" alt="" />{' '}
                          {plan.numberOfSeats} <FormattedMessage id={'billing.users'} />
                        </span>
                      )}
                    </>
                  )}
              </span>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="section-info credits-line-info ">
          <span className={'key-txt'}>
            {' '}
            <FormattedMessage id={'credits'} />{' '}
          </span>
          <span className={'plan-credits-rm '}>
            <span className={'unlimited-tag'}>
              <img src="/assets/images/star-vector.svg" alt="" />
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.b2bCreditsPerYear'
                    : 'billing.b2bCreditsPerMo'
                }
                values={{ value: 'Unlimited' }}
              />
            </span>
            <span>
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.phoneCreditsPerYear'
                    : 'billing.phoneCreditsPerMo'
                }
                values={{ value: plan.credits.phoneCredits }}
              />
            </span>
            <span>
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.personalEmailCreditsPerYear'
                    : 'billing.personalEmailCreditsPerMo'
                }
                values={{ value: plan.credits.personalEmailCredits }}
              />
            </span>
            <span>
              <FormattedMessage
                id={
                  plan.numberOfMonths == 12
                    ? 'billing.exportCreditsPerYear'
                    : 'billing.exportCreditsPerMo'
                }
                values={{ value: plan.credits.exportCredits }}
              />
            </span>
          </span>
        </div>
      </div>
      <div className="inline-info main-line">
        <span>
          <FormattedMessage id={'amount'} />
        </span>
        <span>
          <strong>
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={plan.subscriptionTotal * 0.01}
            />
          </strong>
        </span>
      </div>
      <hr />
    </>
  );
}
