import React, { useContext, useEffect, useState } from 'react';
import './FilesList.css';
import FilesItem from './FilesItem/FilesItem';
import { bulkPagination } from '../../../../services/api/bulkEnrichment.api';
import { BulkEnrichmentContext, actions } from '../../store/bulkEnrichment.store';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

function FilesList({ currentPage, profile, credits }) {
  const { state, dispatch } = useContext(BulkEnrichmentContext);
  const [data, setData] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const body = { page: currentPage, perPage: 10 };

      try {
        const { data, statusCode } = await bulkPagination(body);
        setIsLoading(false);
        if (statusCode === 200 && data.total === 0) {
          dispatch({
            type: actions.SET_NO_DATA,
            payload: true,
          });
        } else if (statusCode === 200 && data.bulkEnrichment) {
          dispatch({
            type: actions.SET_NO_DATA,
            payload: false,
          });
          setData(data.bulkEnrichment);
          dispatch({
            type: actions.SET_TOTAL_PAGES,
            payload: Math.ceil(data.total / data.perPage),
          });
        } else {
          toast.error(data.messageTranslated || data.message);
        }
      } catch (error) {
        toast.error('Error during data fetch:', error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, dispatch, updateTrigger]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <img src="/assets/images/loading.gif" alt="loading" />
      </div>
    );
  }

  if (state.noData) {
    return (
      <div className="no-data-container">
        <FormattedMessage id="bulkEnrichment.files.noData" />
      </div>
    );
  }

  const properties = [
    <FormattedMessage id="bulkEnrichment.files.name" />,
    <FormattedMessage id="bulkEnrichment.files.nbContacts" />,
    <FormattedMessage id="bulkEnrichment.files.nbContactsEnriched" />,
    <FormattedMessage id="bulkEnrichment.files.nbPhone" />,
    <FormattedMessage id="bulkEnrichment.files.nbWorkEmails" />,
    <FormattedMessage id="bulkEnrichment.files.nbDirectEmails" />,
    <FormattedMessage id="bulkEnrichment.files.status" />,
    <FormattedMessage id="bulkEnrichment.files.actions" />,
  ];

  return (
    <div className="table-content">
      <table>
        <thead>
          <tr>
            {properties.map((property, index) => (
              <th key={index}>{property}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <FilesItem
              key={item._id}
              _id={item._id}
              name={item.name}
              error={item.error}
              nbContacts={item.profiles}
              nbContactsEnriched={item.enrichedProfiles}
              nbPhone={item.phones}
              nbWorkEmail={item.workEmails}
              nbDirectEmail={item.directEmails}
              status={item.status}
              download={item.upload}
              setUpdateTrigger={setUpdateTrigger}
              profile={profile}
              credits={credits}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FilesList;
