import React from 'react';
import { injectIntl } from 'react-intl';
import { Animation, Permissions } from '../../../../components';

function PhoneDropDown({
  style,
  setToggle,
  toggle,
  handleAircallToggle,
  handleRingoverToggle,
  checkPlanPermission,
  checkMemberPermission,
}) {
  return (
    <>
      <div className="cstm-dropdown addedby user_filter custom-lead-export" style={{ ...style }}>
        <div className="dropdown">
          <Animation.SlideDown
            className={'dropdown-menu show'}
            toggle={toggle}
            setToggle={setToggle}
            style={{ width: 'max-content' }}
            withoutScroll={true}
          >
            <ul>
              {!checkPlanPermission('Aircall') ? (
                <Permissions.CallToAction
                  withoutIcon={true}
                  isPlan={true}
                  toolTipStyle={{ width: '200px' }}
                >
                  <li>
                    <span
                      style={{
                        height: '50px',
                        width: '50px',
                        marginRight: '20px',
                      }}
                    >
                      <img
                        src="/assets/images/aircall.svg"
                        alt=""
                        style={{ height: '40px', width: '40px' }}
                      />
                    </span>
                    Aircall
                  </li>
                </Permissions.CallToAction>
              ) : (
                <li
                  onClick={() => {
                    handleAircallToggle(true);
                    setToggle(false);
                  }}
                >
                  <span
                    style={{
                      height: '50px',
                      width: '50px',
                      marginRight: '20px',
                    }}
                  >
                    <img
                      src="/assets/images/aircall.svg"
                      alt=""
                      style={{ height: '40px', width: '40px' }}
                    />
                  </span>
                  Aircall
                </li>
              )}
              {!checkPlanPermission('Ringover') ? (
                <Permissions.CallToAction
                  withoutIcon={true}
                  isPlan={true}
                  toolTipStyle={{ width: '200px' }}
                >
                  <li
                    onClick={() => {
                      handleRingoverToggle(true);
                      setToggle(false);
                    }}
                  >
                    <span
                      style={{
                        height: '50px',
                        width: '50px',
                        marginRight: '20px',
                      }}
                    >
                      <img
                        src="/assets/images/ringover.png"
                        alt=""
                        style={{ height: '40px', width: '40px' }}
                      />
                    </span>
                    Ringover
                  </li>
                </Permissions.CallToAction>
              ) : (
                <li
                  onClick={() => {
                    handleRingoverToggle(true);
                    setToggle(false);
                  }}
                >
                  <span
                    style={{
                      height: '50px',
                      width: '50px',
                      marginRight: '20px',
                    }}
                  >
                    <img
                      src="/assets/images/ringover.png"
                      alt=""
                      style={{ height: '40px', width: '40px' }}
                    />
                  </span>
                  Ringover
                </li>
              )}
            </ul>
          </Animation.SlideDown>
        </div>
      </div>
    </>
  );
}

export default injectIntl(PhoneDropDown);
