import actions from '../actions';

const initState = {};

export const profile = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_USER:
      return {
        ...state,
        ...action.payload,
        permissions: action.permissions,
        version2: action.version2,
      };
    default:
      return initState;
  }
};
