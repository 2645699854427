const actions = {
  EDIT_PROFILE_TOGGLE: 'EDIT_PROFILE_TOGGLE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  SET_INTEGRATION_TYPE: 'SET_INTEGRATION_TYPE',
  SET_IS_ZAPIER_MODAL_OPEN: 'SET_IS_ZAPIER_MODAL_OPEN',
  // Mapping modal
  SET_INTEGRATION_PROPERTIES: 'SET_INTEGRATION_PROPERTIES',
  SET_KASPR_PROPERTIES: 'SET_KASPR_PROPERTIES',
  SET_MAPPED_PROPERTIES: 'SET_MAPPED_PROPERTIES',
  SET_MAPPED_VALUES: 'SET_MAPPED_VALUES',
  SET_IS_CUSTOM_VALUE: 'SET_IS_CUSTOM_VALUE',
  SET_PROPERTIES_AUTO_COMPLETE: 'SET_PROPERTIES_AUTO_COMPLETE',
  SET_SHOW_AUTO_COMPLETE: 'SET_SHOW_AUTO_COMPLETE',
  SET_SHOW_KASPR_PROPERTIES_AUTO_COMPLETE: 'SET_SHOW_KASPR_PROPERTIES_AUTO_COMPLETE',
  SET_SALESFORCE_TAB: 'SET_SALESFORCE_TAB',
  SET_PROPERTY_INDEX: 'SET_PROPERTY_INDEX',
  SET_ERROR: 'SET_ERROR',
};

export default actions;
