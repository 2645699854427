import React from 'react';
import { Col } from 'react-bootstrap';
import HomeSectionTable from '../common/HomeSectionTable/HomeSectionTable';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl/lib';
export default function CurrentTasks({ tasks, type }) {
  if (!tasks) return null;
  if (tasks && tasks.tasks.length == 0) {
    return (
      <Col lg={6} md={12}>
        <div
          className=" home-card home-card-center info-card p-20 account-usage-card"
          style={{ minHeight: '300px' }}
        >
          <img src="/assets/images/tasks-image.png" alt="" />
          <h2>
            <FormattedMessage id={'home.youDontHaveAnyTaskYet'} />
          </h2>
          <p>
            <FormattedMessage id={'home.assignTeamTasks'} />
          </p>
          <NavLink to={'/tasks'} className={'btn btn1'}>
            <FormattedMessage id={'home.assignTasks'} />
          </NavLink>
        </div>
      </Col>
    );
  }
  return (
    <Col lg={6} md={12}>
      <div className="home-card info-card p-20 account-usage-card" style={{ minHeight: '274px' }}>
        <h4>
          <FormattedMessage id={'home.currentTasks'} />
        </h4>
        <HomeSectionTable
          tHeadData={['title', 'type', 'due', 'assignee', 'leadId']}
          //tHeadData={["test1", "test2", "test3"]}
          tBodyData={tasks.tasks}
          type={type}
        />
        <div className={'see-more-bottom'}>
          <NavLink to={'/tasks'}>
            <FormattedMessage id={'home.seeMore'} />
          </NavLink>
        </div>
      </div>
    </Col>
  );
}
