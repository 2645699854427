const getMappedNotifSettings = ({ settings, notifications, emails }, categories) => {
  let notificationsMapped = [];
  let emailsMapped = [];
  categories.forEach((category) => {
    notificationsMapped = [
      ...notificationsMapped,
      ...notifications.filter((notif) => notif.category === category),
    ];
    emailsMapped = [...emailsMapped, ...emails.filter((email) => email.category === category)];
  });
  return {
    emails,
    emailsMapped,
    notifications,
    notificationsMapped,
    settings,
  };
};

export { getMappedNotifSettings };
