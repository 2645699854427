import React from 'react';
import { BillingContext } from './billing.store';
import { PropTypes } from 'prop-types';

function BillingProvider({ children, store }) {
  return (
    <BillingContext.Provider value={store}>
      <main className="billing">{children}</main>
    </BillingContext.Provider>
  );
}

BillingProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default BillingProvider;
