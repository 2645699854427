const actions = {
  TOGGLE: 'TOGGLE',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_MEMBERS: 'GET_MEMBERS',
  GET_DATA_CHART: 'GET_DATA_CHART',
  GET_DATA_PIE: 'GET_DATA_PIE',
  CHART_FILTERS: 'CHART_FILTERS',
  GET_USAGE_BY_MEMBER: 'GET_USAGE_BY_MEMBER',
  GET_ACTIVITY: 'GET_ACTIVITY',
  GET_REMAINING: 'GET_REMAINING',
  SET_ORG_INVITATIONS: 'SET_ORG_INVITATIONS',
  SET_LOADING: 'SET_LOADING',
  SET_ALONE_IN_WORKSPACE: 'SET_ALONE_IN_WORKSPACE',
  SET_DELETE_TEAM_MODAL: 'SET_DELETE_TEAM_MODAL',
  SET_MOVE_WORKSPACE: 'SET_MOVE_WORKSPACE',
  SET_IS_MOVING: 'SET_IS_MOVING',
  SET_SELECTED_INVITATION: 'SET_SELECTED_INVITATION',
  SET_IS_ADMIN: 'SET_IS_ADMIN',
  SET_HAS_OTHER_OWNER_OR_ADMIN: 'SET_HAS_OTHER_OWNER_OR_ADMIN',
  SET_SELECT_ADMINS_TOGGLE: 'SET_SELECT_ADMINS_TOGGLE',
  SET_SELECTED_ADMINS: 'SET_SELECTED_ADMINS',
  SET_ORG_MEMBERS: 'SET_ORG_MEMBERS',
};

export default actions;
