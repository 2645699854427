import React from 'react';
import { LeadsContext } from './leads.store';
import { PropTypes } from 'prop-types';

function LeadsProvider({ children, store }) {
  return (
    <LeadsContext.Provider value={store}>
      <main id="leads">{children}</main>
    </LeadsContext.Provider>
  );
}

LeadsProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LeadsProvider;
