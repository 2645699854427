/* global chrome */
import React, { useEffect, useState } from 'react';
import { validatePluginInstallation } from '../../../services/api/auth.api';
import { CHROME_STORE, EXTENSION_ID, IS_PROD } from '../../../constants';
import { ToastContainer } from 'react-toastify';
import { injectIntl, useIntl } from 'react-intl';
import './Onboarding.css';
import OnboardingFooter from '../common/OnboardingFooter/OnboardingFooter';
import RedirectionPage from './RedirectionPage/RedirectionPage';

function OnBoardingSteps(props) {
  const [steps, setSteps] = useState([]);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    if (props.profile && props.profile.steps) {
      setSteps(props.profile.steps);
    }
    if (window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(
        EXTENSION_ID,
        {
          action: 'isKasprExtensionInstalled',
        },
        async (response, err) => {
          if (!response) {
            setIsExtensionInstalled(false);
          } else {
            const allSteps = props.profile.steps.map((step) => {
              if (step.id === 'pluginInstallation') {
                if (step.done) {
                  return step;
                }
                step.done = true;
                validatePluginInstallation();
              }
              return step;
            });
            setSteps(allSteps);
            setIsExtensionInstalled(true);
          }
        },
      );
    }
  }, [props.profile]);

  const handleOnClick = async (type) => {
    switch (type) {
      case 'linkedin_connect':
        isExtensionInstalled
          ? window.open(
              'https://www.linkedin.com/in/anne-charlotte-lazou/?synchro=kaspr&isFirstKasprSynchro=true',
            )
          : (window.location.href = CHROME_STORE);
        break;
      case 'plugin':
        window.location.href = CHROME_STORE;
        break;
    }
  };

  const handleSkipSteps = async () => {
    window.location = '/home';
  };
  const handleProceed = async () => {
    window.location = '/home';
  };

  return (
    <div id="onboarding-ctn">
      {' '}
      <OnBoardWrapper
        handleSkipSteps={handleSkipSteps}
        handleProceed={handleProceed}
        nextStep={props.profile && props.profile.nextStep}
        intl={props.intl}
      >
        <RedirectionPage
          isExtensionInstalled={isExtensionInstalled}
          handleOnClick={handleOnClick}
        />
        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </OnBoardWrapper>
      <OnboardingFooter />
    </div>
  );
}

function OnBoardWrapper({ children }) {
  return (
    <div className="onboard-content-ui">
      <div className="container-fluid">{children}</div>
    </div>
  );
}

OnBoardingSteps = injectIntl(OnBoardingSteps);
export { OnBoardingSteps };
