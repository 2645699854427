import React, { useContext, useEffect, useRef, useState } from 'react';
import { AttachIntegration, NumberField, TextArea, TimerField } from './Tools';
import { actions, WorkflowContext } from '../../store/workflow.store';
import { NavLink } from 'react-router-dom';
import MappingModal from '../../../Settings/Integration/Modals/MappingModal/MappingModal';
import {
  createWorkflow,
  getTemplates,
  getWorkflows,
  updateBlock,
} from '../../../../services/api/workflows.api';
import { DASHBOARD_URL } from '../../../../constants';
import { InfoToolTip, ModalMessage, Permissions, Tooltip } from '../../../../components';
import allIntegrations from '../allIntegrations';
import { getAccounts } from '../../../../services/api/integrations.api';
import { checkPermission, dfs } from '../../../../services/utils';
import { ToastContainer, toast } from 'react-toastify';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { reversDfs } from '../../../../services/utils/dfs';
import ConnectionModal from '../../../Settings/Integration/Modals/ConnectionModal/ConnectionModal';
import { usePermissions } from '../../../../components/Permissions/permissions.hooks';
import IntegrationsDropdown from '../../CreateWorkflow/IntegrationsDropdown/IntegrationsDropdown';
import IntgDropdown from './IntgDropdown/IntgDropdown';

export default function TemplateSettings({
  active,
  handleSettingsToggle,
  blockOptions,
  profile,
  organizationCredits,
  integrations,
  setIsMappingModalOpen,
  setIsConnectionModalOpen,
  setBlockOptions,
  setIsBlockAttached,
  currentWorkflow,
  setIsWkAttached,
  isWkAttached,
}) {
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    organizationCredits && organizationCredits.plan && organizationCredits.plan.permissions,
    profile.permissions,
    'Workflow',
  );

  const SETTINGS_TYPE = {
    'wokflow-attach': (
      <AttachBlock
        blockOptions={blockOptions}
        handleSettingsToggle={handleSettingsToggle}
        profile={profile}
        checkPlanPermissions={checkPlanPermissions}
        checkMemberPermissions={checkMemberPermissions}
        integrations={integrations}
        setIsMappingModalOpen={setIsMappingModalOpen}
        setIsConnectionModalOpen={setIsConnectionModalOpen}
        setIsBlockAttached={setIsBlockAttached}
        currentWorkflow={currentWorkflow}
        setIsWkAttached={setIsWkAttached}
        isWkAttached={isWkAttached}
      />
    ),
    'linkedin-enrich': (
      <EnrichConfig
        blockOptions={blockOptions}
        handleSettingsToggle={handleSettingsToggle}
        checkPlanPermissions={checkPlanPermissions}
        checkMemberPermissions={checkMemberPermissions}
        integrations={integrations}
        setIsMappingModalOpen={setIsMappingModalOpen}
        setIsConnectionModalOpen={setIsConnectionModalOpen}
        setBlockOptions={setBlockOptions}
        profile={profile}
        setIsBlockAttached={setIsBlockAttached}
        currentWorkflow={currentWorkflow}
        setIsWkAttached={setIsWkAttached}
        isWkAttached={isWkAttached}
      />
    ),
    integration: (
      <IntegrationConfig
        blockOptions={blockOptions}
        handleSettingsToggle={handleSettingsToggle}
        checkPlanPermissions={checkPlanPermissions}
        checkMemberPermissions={checkMemberPermissions}
      />
    ),
    'attached-block': (
      <AttachedBock
        blockOptions={blockOptions}
        handleSettingsToggle={handleSettingsToggle}
        profile={profile}
        checkPlanPermissions={checkPlanPermissions}
        checkMemberPermissions={checkMemberPermissions}
      />
    ),
  };

  return (
    <div
      style={{ paddingLeft: 0, width: '100%' }}
      className={'workflow-setting ' + (blockOptions ? 'active' : '')}
    >
      {blockOptions && (
        <div className="setting-inner-wrap">
          {/*CONFIG BLOCKs*/}
          {blockOptions.attached
            ? SETTINGS_TYPE[blockOptions.attached]
            : blockOptions && blockOptions.action.match('integration')
              ? SETTINGS_TYPE['integration']
              : SETTINGS_TYPE[blockOptions.action]}
        </div>
      )}
    </div>
  );
}

// ENRICHMENT

function EnrichConfig({
  handleSettingsToggle,
  blockOptions,
  checkPlanPermissions,
  checkMemberPermissions,
  integrations,
  setIsMappingModalOpen,
  setIsConnectionModalOpen,
  profile,
  setIsBlockAttached,
  currentWorkflow,
  setIsWkAttached,
  isWkAttached,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(WorkflowContext);

  useEffect(() => {
    if (!blockOptions.options.dataToGet) {
      blockOptions.options.dataToGet = {
        directEmails: true,
        phones: true,
        workEmails: true,
      };
    }
  }, []);

  const handleOnChange = (checked, type) => {
    let template = state.selectedTemplate;
    template.blocks = state.selectedTemplate.blocks.map((block) => {
      if (block.id === blockOptions.id) {
        if (['workEmails', 'phones', 'directEmails'].indexOf(type) > -1) {
          block.options.dataToGet = { ...block.options.dataToGet };
          block.options.dataToGet[type] = checked;
        } else {
          block.options[type] = checked;
        }
      }
      return block;
    });

    dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });

    const currentConfig = { ...state.enrichConfig };

    if (type === 'isEmailProRequired' || type === 'isPhoneRequired') {
      currentConfig[type] = checked;
    } else if (type === 'workEmails' || type === 'phones' || type === 'directEmails') {
      if (!currentConfig.dataToGet) {
        currentConfig.dataToGet = {};
      }
      currentConfig.dataToGet[type] = checked;
    }

    dispatch({
      type: actions.SET_ENRICH_CONFIG,
      payload: currentConfig,
    });
  };

  return (
    <div style={{ paddingBottom: isOpen ? '22vh' : '0' }}>
      <div className="setting-heading">
        <h4 style={{ fontWeight: 500, fontSize: '20px', color: '#0d1021' }}>
          <FormattedMessage id={'workflow.enrichment'} />
        </h4>
      </div>
      <div id="enrichment" className="enrichment-flow">
        <p className="sub-title">
          {' '}
          <FormattedMessage id={'workflow.settings.enrichmentOptions'} />
          <span>
            <InfoToolTip
              style={{ width: 16, marginLeft: 5, marginTop: -2 }}
              text={<FormattedMessage id={'workflow.settings.enrichmentOptionsToolTip'} />}
            />
          </span>
        </p>
        <ul>
          <li>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  checked={blockOptions.options.isEmailProRequired}
                  onChange={(e) => handleOnChange(e.target.checked, 'isEmailProRequired')}
                />
                <span className="checkbox-icon"></span>
                <span className="checkbox-text">
                  <FormattedMessage id={'workflow.settings.emailProRequired'} />
                </span>
              </label>
            </div>
          </li>
          <li>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  checked={blockOptions.options.isPhoneRequired}
                  onChange={(e) => handleOnChange(e.target.checked, 'isPhoneRequired')}
                />
                <span className="checkbox-icon"></span>
                <span className="checkbox-text">
                  <FormattedMessage id={'workflow.settings.mobileRequired'} />
                </span>
              </label>
            </div>
          </li>
        </ul>
      </div>
      <div id="enrichment" className="enrichment-flow">
        <p className="sub-title">
          <FormattedMessage id={'workflow.settings.requestedData'} />{' '}
          <span>
            <InfoToolTip
              style={{ width: 16, marginLeft: 1, marginTop: -2 }}
              text={<FormattedMessage id={'workflow.settings.requestedDataToolTip'} />}
            />
          </span>
        </p>
        <ul>
          <li>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  checked={
                    blockOptions.options.dataToGet && blockOptions.options.dataToGet.workEmails
                  }
                  onChange={(e) => handleOnChange(e.target.checked, 'workEmails')}
                />
                <span className="checkbox-icon"></span>
                <span className="checkbox-text">
                  <FormattedMessage id="workflow.settings.b2bEmails" />
                </span>
              </label>
            </div>
          </li>
          <li>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  checked={blockOptions.options.dataToGet && blockOptions.options.dataToGet.phones}
                  onChange={(e) => handleOnChange(e.target.checked, 'phones')}
                />
                <span className="checkbox-icon"></span>
                <span className="checkbox-text">
                  <FormattedMessage id="workflow.settings.phoneNumbers" />
                </span>
              </label>
            </div>
          </li>
          <li></li>
          <li>
            <div className="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  checked={
                    blockOptions.options.dataToGet && blockOptions.options.dataToGet.directEmails
                  }
                  onChange={(e) => handleOnChange(e.target.checked, 'directEmails')}
                />
                <span className="checkbox-icon"></span>
                <span className="checkbox-text">
                  <FormattedMessage id="workflow.settings.directEmails" />
                </span>
              </label>
            </div>
          </li>
          <li></li>
        </ul>
      </div>
      <div id="wk-integrations">
        <h1 style={{ fontSize: '20px', fontWeight: 500, marginBottom: '10px' }}>
          <FormattedMessage id="integrations" />
        </h1>
        <AttachBlock
          blockOptions={blockOptions}
          handleSettingsToggle={handleSettingsToggle}
          profile={profile}
          checkPlanPermissions={checkPlanPermissions}
          checkMemberPermissions={checkMemberPermissions}
          integrations={integrations}
          setIsMappingModalOpen={setIsMappingModalOpen}
          setIsConnectionModalOpen={setIsConnectionModalOpen}
          setIsBlockAttached={setIsBlockAttached}
          currentWorkflow={currentWorkflow}
          setIsWkAttached={setIsWkAttached}
          isWkAttached={isWkAttached}
        />
      </div>
    </div>
  );
}

function IntegrationConfig({ handleSettingsToggle, blockOptions, checkMemberPermissions }) {
  const [account, setAccount] = useState(null);
  const [showMappingModal, setMappingModal] = useState(false);
  const handleMappingModal = () => {
    setMappingModal(!showMappingModal);
  };
  useEffect(() => {
    const loadData = async () => {
      let { data, statusCode } = await getAccounts();
      if (data && data.accounts) setAccount(data.accounts[blockOptions.name]);
    };
    loadData();
  }, []);

  return (
    <div>
      <div>
        <div className="setting-heading">
          <label>
            <FormattedMessage id={'workflow.settings.step'} />
          </label>
          <h4>{blockOptions.name === 'zohocrm' ? 'Zoho' : blockOptions.name}</h4>
        </div>
        <p className="msg">
          <FormattedMessage id={'workflow.settings.yourConnectedWith'} />{' '}
          <strong>{account && account[blockOptions.name + 'Email']}</strong>
        </p>
        <div className="spacer-block"></div>
        <div className="configure-block">
          <p className="sub-heading">
            <FormattedMessage id={'workflow.settings.settingTheExportTo'} />{' '}
            {blockOptions.name === 'zohocrm' ? 'Zoho' : blockOptions.name}
          </p>
          <p className="desc">
            <FormattedMessage id={'workflow.settings.setupYour_'} />{' '}
            {blockOptions.name === 'zohocrm' ? 'Zoho' : blockOptions.name}{' '}
            <FormattedMessage id={'workflow.settings.propertiesBelow'} />
          </p>
          <a
            href="#"
            className="btn1 configure-btn"
            data-toggle="modal"
            data-target="#configrations-modal"
            onClick={handleMappingModal}
          >
            <FormattedMessage id={'workflow.settings.configure'} />
          </a>
        </div>
        <div className="button-blocks">
          <a href="#" className="btn1" onClick={() => handleSettingsToggle()}>
            <FormattedMessage id={'workflow.settings.saveThisStep'} />
          </a>
        </div>
      </div>

      {blockOptions.name == 'zapier' && account ? (
        <ConnectionModal
          integration={blockOptions.name}
          category={'Integration'}
          imagesrc={'/assets/images/zapier.png'}
          show={showMappingModal}
          onHide={handleMappingModal}
          account={account.zapierEmail}
          checkMemberPermissions={checkMemberPermissions}
        />
      ) : (
        <MappingModal
          integration={blockOptions.name}
          category={''}
          imagesrc={'/assets/images/' + blockOptions.name + '.png'}
          show={showMappingModal}
          onHide={handleMappingModal}
          account={account}
          setIsLoading={() => null}
        />
      )}
    </div>
  );
}

function AttachedBock({
  handleSettingsToggle,
  blockOptions,
  checkPlanPermissions,
  checkMemberPermissions,
}) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [blockToDelete, setBlockToDelete] = useState(false);
  const handleGoToWorkflow = () => {
    window.open(DASHBOARD_URL + '/workflow/' + blockOptions.workflow);
  };
  const handleDeleteWorkflow = async () => {
    let template = state.selectedTemplate;
    template.blocks = state.selectedTemplate.blocks.map((block) => {
      if (block.options && block.options.workflowId === blockOptions.workflow) {
        block.options.workflowId = null;
        if (block._id) {
          updateBlock(block).then(({ data, statusCode }) => {
            if (statusCode === 200) {
              handleSettingsToggle();
            }
          });
        }
      }
      return block;
    });
    dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });
    handleSettingsToggle();
  };
  return (
    <div>
      <div>
        <div>
          <div className="setting-heading">
            <a href="#" className="close-btn" onClick={handleSettingsToggle}>
              &times;
            </a>
            <h4>
              <FormattedMessage id={'workflow.settings.attachment'} />
            </h4>
            <p>
              {blockOptions.action == 'linkedin-enrich' ? (
                blockOptions.id == '10' ? (
                  <FormattedMessage id={'workflow.settings.thisActionEnrich'} />
                ) : (
                  <FormattedMessage id={'workflow.settings.thisActionEnrich'} />
                )
              ) : (
                <FormattedMessage
                  id={'workflow.settings.thisActionAllowsYouToSetIntegProperties'}
                />
              )}
            </p>
          </div>
          <div id="enrichment" className="enrichment-flow">
            <p className="sub-title">
              <FormattedMessage id={'workflow.settings.attachmentOptions'} />
            </p>
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className={'btn1'} style={{ width: 100 }} onClick={handleGoToWorkflow}>
                  <FormattedMessage id={'workflow.settings.configure'} />
                </button>
                {!checkPlanPermissions('Edit') || !checkMemberPermissions('Edit') ? (
                  <Permissions.CallToAction
                    isButton={true}
                    isPlan={!checkPlanPermissions('Edit')}
                    toolTipStyle={{ width: 200, left: '25%' }}
                    buttonStyle={{ width: 100, background: 'darkred' }}
                  >
                    <FormattedMessage id={'delete'} />
                  </Permissions.CallToAction>
                ) : (
                  <button
                    className={'btn1'}
                    style={{ width: 100, background: 'darkred' }}
                    onClick={setBlockToDelete}
                  >
                    <FormattedMessage id={'delete'} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {blockToDelete && (
        <ModalMessage
          title={<FormattedMessage id={'workflow.settings.removeAttachment'} />}
          textElement={
            <p>
              <strong>
                <FormattedMessage
                  id={'workflow.settings.areYouSureYouWantToRemoveThisAttachment'}
                />{' '}
              </strong>
              <br />
              <FormattedMessage id={'workflow.settings.pleaseClickToRemove'} />
            </p>
          }
          action={<FormattedMessage id={'workflow.settings.confirmRemove'} />}
          toggle={blockToDelete}
          setToggle={() => setBlockToDelete(false)}
          handleAction={handleDeleteWorkflow}
        />
      )}
    </div>
  );
}

function AttachBlock({
  handleSettingsToggle,
  profile,
  blockOptions,
  integrations,
  setIsMappingModalOpen,
  setIsConnectionModalOpen,
  setIsBlockAttached,
  currentWorkflow,
  setIsWkAttached,
  isWkAttached,
}) {
  const { state, dispatch } = useContext(WorkflowContext);
  const [workflows, setWorkflows] = useState([]);
  const [workflowToAttach, selectWorkflowToAttach] = useState([]);
  const [workflowTab, setWorkflowTab] = useState('existing');
  const workflowNameInputRef = useRef();
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [enrichConfig, setEnrichConfig] = useState({
    isPhoneRequired: false,
    isEmailProRequired: false,
    workflowName: '',
    dataToGet: { workEmails: true, phones: true, directEmails: true },
  });

  useEffect(() => {
    if (state.selectedTemplate.type == 'enrichment') {
      getWorkflows({
        filters: {
          all: true,
          type: ['integration'],
        },
      }).then(({ data, statusCode }) => {
        if (statusCode === 200) {
          const integrationWorkflows = data.workflows.filter((wk) => wk.type === 'integration');
          if (integrationWorkflows.length == 0) setWorkflowTab('new');
          setWorkflows(integrationWorkflows);
        }
      });
    } else {
      getWorkflows({
        filters: {
          all: true,
          type: ['enrichment'],
        },
      }).then(({ data, statusCode }) => {
        if (statusCode === 200) {
          const enrichWorkflows = data.workflows.filter((wk) => wk.type === 'enrichment');
          if (enrichWorkflows.length == 0) setWorkflowTab('new');
          setWorkflows(enrichWorkflows);
        }
      });
    }
  }, []);

  const handleAttachBlock = async (workflowToAttach, workflowType) => {
    let workflowToAttachCopy = { ...workflowToAttach[0] };
    if (workflowType === 'new') {
      workflowToAttachCopy = await handleCreateWorkflow(workflowToAttach[0]);
      if (!workflowToAttachCopy) return;
    }
    if (blockOptions._id || !workflowToAttachCopy._id) {
      if (
        checkPermission({
          permissions: profile.permissions,
          name: 'Workflow',
          action: 'Edit',
        })
      ) {
        if (!workflowToAttachCopy._id) return;
        if (blockOptions.options) {
          blockOptions.options.workflowId = workflowToAttachCopy._id;
          blockOptions.options.name = workflowToAttachCopy.name;
        } else {
          blockOptions.options = {
            workflowId: workflowToAttachCopy._id,
            name: workflowToAttachCopy.name,
          };
        }
        const { data, statusCode } = await updateBlock(blockOptions);
        if (statusCode === 200) {
          console.log(data);
        }
      } else {
        toast.error(<FormattedMessage id={'permissions.noAccess'} />);
      }
    } else {
      let template = state.selectedTemplate;
      template.blocks = state.selectedTemplate.blocks.map((block) => {
        if (block.id === '2') {
          block.options.workflowId = workflowToAttachCopy._id;
        }
        return block;
      });
      dispatch({ type: actions.SELECTED_TEMPLATE, payload: template });
    }
  };

  const handleCreateWorkflow = async (intg) => {
    const { data } = await getTemplates();
    const myTemplate = data.templates.find(
      (template) => template.type == 'enrichment' || template.type === 'integration',
    );

    if (intg) {
      myTemplate.type = 'integration';
      myTemplate.blocks = [
        {
          id: 1,
          name: intg.name,
          action: 'integration-' + intg.name,
          type: 'flow_action',
          options: { email: '' },
        },
      ];
    }
    if (myTemplate.type == 'enrichment') {
      if (workflowNameInputRef.current) {
        if (!enrichConfig.workflowName) {
          return (workflowNameInputRef.current.style.borderColor = 'red');
        } else {
          workflowNameInputRef.current.style.borderColor = 'inherit';
        }
      }
      myTemplate.blocks = myTemplate.blocks.map((block) => {
        if (block.id == '1') {
          block.options.addLead = true;
          block.options.isEmailProRequired = enrichConfig.isEmailProRequired;
          block.options.isPhoneRequired = enrichConfig.isPhoneRequired;
          block.options.dataToGet = enrichConfig.dataToGet;
        }
        return block;
      });
    }
    if (
      checkPermission({
        permissions: profile.permissions,
        name: 'Workflow',
        action: 'Edit',
      })
    ) {
      myTemplate.name = enrichConfig.workflowName || (intg && intg.name);
      const { data, statusCode } = await createWorkflow(myTemplate);

      if (statusCode === 200) {
        return data.workflow;
      }
    } else {
      toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
  };

  const handleOnEnrichConfig = async (value, type) => {
    let newConfig = { ...enrichConfig };
    newConfig[type] = value;
    setEnrichConfig(newConfig);
    dispatch({
      type: actions.SET_ENRICH_CONFIG,
      payload: newConfig,
    });
  };

  return (
    <div>
      <div className="workflow-tab-list">
        <IntgDropdown
          existingWk={workflows}
          integrations={integrations}
          selectWorkflowToAttach={selectWorkflowToAttach}
          setSelectedIntegration={setSelectedIntegration}
          defaultName={<FormattedMessage id={'settings.integration.selectAnIntegration'} />}
          setIsMappingModalOpen={setIsMappingModalOpen}
          setIsConnectionModalOpen={setIsConnectionModalOpen}
          setIsBlockAttached={setIsBlockAttached}
          handleAttachBlock={handleAttachBlock}
          currentWorkflow={currentWorkflow}
          setIsWkAttached={setIsWkAttached}
          isWkAttached={isWkAttached}
        />

        {state.selectedTemplate.type !== 'enrichment' && (
          <div className={'custom-enrichment-attach'}>
            {' '}
            <div>
              <input
                type="text"
                placeholder={'Workflow name'}
                autoFocus
                onChange={(e) => handleOnEnrichConfig(e.target.value, 'workflowName')}
                value={enrichConfig.workflowName}
                ref={workflowNameInputRef}
              />
            </div>
            <ul>
              <li>
                <div className="checkbox-wrap">
                  <label>
                    <input
                      type="checkbox"
                      checked={blockOptions.options.isEmailProRequired}
                      onChange={(e) => handleOnEnrichConfig(e.target.checked, 'isEmailProRequired')}
                    />
                    <span className="checkbox-icon"></span>
                    <span className="checkbox-text">
                      <FormattedMessage id={'workflow.settings.emailProRequired'} />
                    </span>
                  </label>
                </div>
              </li>
              <li>
                <div className="checkbox-wrap">
                  <label>
                    <input
                      type="checkbox"
                      checked={blockOptions.options.isPhoneRequired}
                      onChange={(e) => handleOnEnrichConfig(e.target.checked, 'isPhoneRequired')}
                    />
                    <span className="checkbox-icon"></span>
                    <span className="checkbox-text">
                      <FormattedMessage id={'workflow.settings.mobileRequired'} />
                    </span>
                  </label>
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
