import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Animation } from '../../../../../components';
import { checkPlanPermissions } from '../../../../../services/utils/checkPermissions';
import Calendar from 'react-calendar';
import '../AddTaskModal/AddTaskModal.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMembers } from '../../../../../services/api/organization.api';
import { actions } from '../../../store/leads.store';
import { debounce } from '../../../../../services/utils/tools';
function AddTaskSection({
  organization,
  setToggle,
  onCreate,
  isModal,
  lead,
  task,
  trackingFrom = {},
}) {
  //toggles
  const [typeToggle, setTypeToggle] = useState(false);
  const [assignedToToggle, setAssignedToToggle] = useState(false);
  const [calendarToggle, setCalendarToggle] = useState(false);
  const [noteToggle, setNoteToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  // state
  const [title, setTitle] = useState('');
  const [taskType, setTaskType] = useState('reminder');
  const [assignedTo, setAssignedTo] = useState(organization.organization.members[0]);

  const [note, setNote] = useState('');
  const [date, setDate] = useState(null);
  const [time, setTime] = useState('00:00');
  const [error, setError] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    if (task) {
      if (task.description) {
        setNoteToggle(true);
        setNote(task.description);
      }
      setTitle(task.title);
      setTaskType(task.type);
      setAssignedTo(task.assignee.memberId);
      setDate(new Date(task.due));
      if (task.due) {
        let time = intl
          .formatTime(task.due, {
            hours: 'numeric',
            minutes: 'numeric',
            hour12: false,
          })
          .split(' ')[0];
        let hour = time.split(':')[0] === '24' ? '00' : time.split(':')[0];
        let minute = time.split(':')[1];
        setTime(('0' + hour).slice(-2) + ':' + ('0' + minute).slice(-2));
      }
    }
  }, []);
  const handleSelectTaskType = (type) => {
    setTaskType(type);
    setTypeToggle(false);
  };
  const handleOnCreate = async () => {
    if (!title) return setError('title');
    if (!assignedTo) return setError('assignee');
    if (!taskType) return setError('task_type');
    if (!date) return setError('date');
    setLoading(true);
    let due = new Date(date);
    let splittedTime = time && time.split(':');
    if (splittedTime[0]) due.setHours(splittedTime[0]);
    if (splittedTime[1]) due.setMinutes(splittedTime[1]);

    const dataForm = {
      title,
      description: note,
      due: due,
      assigneeMemberId: assignedTo._id,
      type: taskType,
      isCompleted: false,
      clientDate: new Date(),
      leadId: (task && task.leadId && task.leadId._id) || lead._id,
      _id: task ? task._id : '',
    };
    const status = await onCreate(dataForm);
    if (status) setLoading(false);
  };
  return (
    <div className="  add_task_modal" id="add-task-modal">
      <div className="modal-content">
        {isModal ? (
          <Modal.Header
            className="modal-header border-0"
            closeButton={() => setToggle(false)}
            data-tracking-id={trackingFrom + '-closeTaskModal'}
          >
            <div className="modal-inner-header">
              {task ? (
                <h4 className="title">
                  <FormattedMessage id={'leads.tasks.editTask'} />
                </h4>
              ) : (
                <h4 className="title">
                  <FormattedMessage id={'leads.tasks.addTask'} />
                </h4>
              )}
              {lead && (
                <p className="sm-title">
                  <FormattedMessage id={'leads.associatedTo'} />{' '}
                  <strong>{lead.firstName + ' ' + lead.lastName} </strong>
                </p>
              )}
            </div>
          </Modal.Header>
        ) : (
          <div className="modal-inner-header">
            <h4 className="title">
              <FormattedMessage id={'leads.tasks.addTask'} />
            </h4>
          </div>
        )}

        <div className="">
          <div className="inner-content" style={{ margin: 0 }}>
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="s-title">
                      <FormattedMessage id={'leads.tasks.title'} />
                    </label>{' '}
                    <input
                      type="text"
                      className="form-control"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setError(null);
                      }}
                      style={error === 'title' ? { borderColor: 'red' } : {}}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="add-note-blk">
                    {!noteToggle ? (
                      <button className="addnote-btn" onClick={() => setNoteToggle(true)}>
                        <span className="ic-note"></span>
                        <FormattedMessage id={'leads.tasks.addNote'} />
                      </button>
                    ) : (
                      <div>
                        <textarea
                          placeholder={intl.formatMessage({
                            id: 'leads.tasks.typeYourNoteHere',
                          })}
                          style={{
                            width: '100%',
                            height: 70,
                            outline: 'none',
                            borderRadius: 10,
                            borderColor: '#D5D7E4',
                            padding: 10,
                          }}
                          className={'textarea-box'}
                          autoFocus={true}
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <SelectAssignedTo
                    toggle={assignedToToggle}
                    setToggle={(val) => {
                      setAssignedToToggle(val);
                      setError(null);
                    }}
                    onChange={(val) => {
                      setAssignedTo(val);
                      setAssignedToToggle(false);
                    }}
                    value={assignedTo}
                    error={error === 'assignee'}
                    members={organization && organization.organization.members}
                  />
                </div>
                <div className="col-md-12">
                  <SelectTaskType
                    toggle={typeToggle}
                    setToggle={(val) => {
                      setTypeToggle(val);
                      setError(null);
                    }}
                    value={taskType}
                    onChange={(val) => {
                      handleSelectTaskType(val);
                    }}
                    error={error === 'task_type'}
                  />
                </div>
                <div className="col-md-6">
                  <SelectDate
                    toggle={calendarToggle}
                    setToggle={setCalendarToggle}
                    onChange={(val) => {
                      setDate(val);
                      setError(null);
                    }}
                    date={date}
                    error={error === 'date'}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="s-title">
                      <FormattedMessage id={'leads.tasks.time'} />
                    </label>
                    <div className=" cstm-dropdown " style={{ width: '100%' }}>
                      <input
                        type="time"
                        className="form-control shadow-sm"
                        placeholder="Choose time"
                        value={time}
                        style={{ width: '100%', background: 'white' }}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer border-0">
          <button className="btn2 mr14" onClick={() => setToggle(false)}>
            <FormattedMessage id={'cancel'} />
          </button>
          <button
            className="btn1 "
            onClick={!loading ? handleOnCreate : () => null}
            data-tracking-id={trackingFrom + (task ? 'editTask' : '-createTask')}
          >
            {task ? <FormattedMessage id={'edit'} /> : <FormattedMessage id={'create'} />}
          </button>
        </div>
      </div>
    </div>
  );
}
function SelectAssignedTo({ setToggle, toggle, onChange, value, members, error }) {
  const [currentMembers, setCurrentMembers] = useState(members);

  const handleSearchMember = debounce(async (search) => {
    const { data, statusCode } = await getMembers({ search });
    if (statusCode == 200) {
      setCurrentMembers(data.members);
    }
  });
  return (
    <div className="form-group">
      <label className="s-title">
        <FormattedMessage id={'leads.tasks.assignedTo'} />
      </label>
      <div
        className="cstm-dropdown user_filter selectpicker  cstm-selectepicker"
        style={{ width: '100%' }}
      >
        <div
          className="btn dropdown-toggle bs-placeholder btn-light"
          onClick={() => setToggle(true)}
          style={{
            maxWidth: '100%',
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            padding: '5px 10px',
            borderRadius: 4,
            ...(error ? { borderColor: 'red' } : {}),
          }}
        >
          <span>{value.firstName + ' ' + value.lastName}</span>
        </div>
        <div className="dropdown">
          <Animation.SlideDown
            className={'dropdown-menu show '}
            toggle={toggle}
            setToggle={setToggle}
            style={{ minWidth: '100%', left: 0 }}
          >
            <div style={{ padding: 10 }}>
              <div className="cstm-dropdown  " style={{ width: '100%' }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search member"
                  onChange={(e) => handleSearchMember(e.target.value)}
                  style={{
                    boxShadow: 'none',
                  }}
                />
              </div>
              <ul>
                {currentMembers && currentMembers.length > 0 ? (
                  currentMembers.map((member, i) => (
                    <li key={i} onClick={() => onChange(member)}>
                      {' '}
                      <span className="name_tag light_red" style={{ textTransform: 'uppercase' }}>
                        {(member.firstName ? member.firstName[0] : '') +
                          (member.lastName ? member.lastName[0] : '')}
                      </span>
                      <span> {member.firstName + ' ' + member.lastName}</span>{' '}
                    </li>
                  ))
                ) : (
                  <li style={{ textAlign: 'center' }}>
                    <a href="#">
                      <span className="name_ui">
                        <FormattedMessage id={'workflow.noResultsFound'} />
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </Animation.SlideDown>
        </div>
      </div>
    </div>
  );
}
function SelectTaskType({ setToggle, toggle, onChange, value, error }) {
  return (
    <div className="form-group">
      <label className="s-title">
        <FormattedMessage id={'leads.tasks.taskType'} />
      </label>
      <div className="cstm-dropdown selectpicker  cstm-selectepicker" style={{ width: '100%' }}>
        <div
          className="btn dropdown-toggle bs-placeholder btn-light"
          onClick={() => setToggle(true)}
          style={{
            maxWidth: '100%',
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            padding: '5px 10px',
            borderRadius: 4,
            ...(error ? { borderColor: 'red' } : {}),
          }}
        >
          <span className={'capital ic-' + value}>
            <FormattedMessage id={'leads.tasks.' + value} />
          </span>
        </div>
        <div className="dropdown">
          <Animation.SlideDown
            className={'dropdown-menu show '}
            toggle={toggle}
            setToggle={setToggle}
            style={{ minWidth: '100%', left: 0 }}
          >
            <div style={{ padding: 10 }}>
              <ul>
                <li onClick={() => onChange('reminder')}>
                  <span className="ic-reminder">
                    {' '}
                    <FormattedMessage id={'leads.tasks.reminder'} />{' '}
                  </span>
                </li>
                <li onClick={() => onChange('todo')}>
                  <span className="ic-todo">
                    <FormattedMessage id={'leads.tasks.todo'} />
                  </span>
                </li>
                <li onClick={() => onChange('call')}>
                  <span className="ic-call">
                    <FormattedMessage id={'leads.tasks.call'} />
                  </span>
                </li>
                <li onClick={() => onChange('email')}>
                  <span className="ic-email">
                    <FormattedMessage id={'leads.tasks.email'} />
                  </span>
                </li>
              </ul>
            </div>
          </Animation.SlideDown>
        </div>
      </div>
    </div>
  );
}
function SelectDate({ setToggle, toggle, onChange, date, error }) {
  const intl = useIntl();
  return (
    <div className="form-group">
      <label className="s-title">
        <FormattedMessage id={'leads.tasks.dueDate'} />
      </label>
      <div className="datepicker-ui cstm-dropdown " style={{ width: '100%' }}>
        <input
          type="text"
          className="form-control"
          placeholder="Choose date"
          id="datepicker2"
          defaultValue={
            date &&
            intl.formatDate(date, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          }
          readOnly={true}
          onClick={() => setToggle(true)}
          style={{ width: '100%', ...(error ? { borderColor: 'red' } : {}) }}
        />
      </div>

      <Animation.FadeIn
        toggle={toggle}
        setToggle={setToggle}
        style={{ left: 0, position: 'absolute', bottom: 400 }}
        className={'dropdown-menu show '}
      >
        <div className={'date-picker-container'} style={{ right: 'auto', left: 0 }}>
          <Calendar
            onChange={(value) => {
              onChange(value);
              setToggle(false);
            }}
            value={date}
            className={'date-picker'}
            minDate={new Date()}
          />
        </div>
      </Animation.FadeIn>
    </div>
  );
}
export default AddTaskSection;
