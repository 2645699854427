import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ItemsList from '../../Common/ItemList/ItemList';
import EditableField from '../../Common/EditableField/EditableField';
import { capitalize } from '../../../../../services/utils/tools';
function ContactFields({
  profile,
  setEditLeadToggle,
  editLeadToggle,
  moreEmails,
  showMoreEmails,
  morePhones,
  showMorePhones,
  onFieldsChange,
  leadToModify,
}) {
  return (
    <div className="about-contact">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.firstName'} />
            </label>
            <EditableField
              value={capitalize(leadToModify.firstName)}
              toggleValue={'firstName'}
              setToggle={setEditLeadToggle}
              toggle={editLeadToggle}
              editIconStyle={{ top: -13, right: 0 }}
              property={'firstName'}
              onChange={onFieldsChange}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.lastName'} />
            </label>
            <EditableField
              value={capitalize(leadToModify.lastName)}
              toggleValue={'lastName'}
              setToggle={setEditLeadToggle}
              toggle={editLeadToggle}
              editIconStyle={{ top: -13, right: 0 }}
              property={'lastName'}
              onChange={onFieldsChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.job'} />
            </label>
            <EditableField
              value={leadToModify.job}
              toggleValue={'job'}
              setToggle={setEditLeadToggle}
              toggle={editLeadToggle}
              editIconStyle={{ top: -13, right: 0 }}
              property={'job'}
              onChange={onFieldsChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.currentProEmails'} />
            </label>
            <div className="email-list">
              <ItemsList
                data={leadToModify.currentProEmails}
                moreItems={moreEmails}
                showMoreItems={showMoreEmails}
                setEditLeadToggle={setEditLeadToggle}
                editLeadToggle={editLeadToggle}
                property={'currentProEmails'}
                onChange={onFieldsChange}
                inputType={'email'}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.currentPersonalEmails'} />
            </label>
            <div className="email-list">
              <ItemsList
                data={leadToModify.currentPersonalEmails}
                moreItems={moreEmails}
                showMoreItems={showMoreEmails}
                setEditLeadToggle={setEditLeadToggle}
                editLeadToggle={editLeadToggle}
                property={'currentPersonalEmails'}
                onChange={onFieldsChange}
                inputType={'email'}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.phones'} />
            </label>
            <div className="email-list">
              <ItemsList
                data={leadToModify.phones}
                moreItems={morePhones}
                showMoreItems={showMorePhones}
                setEditLeadToggle={setEditLeadToggle}
                editLeadToggle={editLeadToggle}
                property={'phones'}
                onChange={onFieldsChange}
                inputType={'number'}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <FormattedMessage id={'leads.properties.addedBy'} />
            </label>
            <p>{profile.addedBy.email} </p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              {' '}
              <FormattedMessage id={'leads.addedOn'} />
            </label>
            <p>{Intl.DateTimeFormat('fr-FR').format(new Date(profile.clientDate))} </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactFields;
