export default {
  specialities: [],
  domains: [],
  industries: [],
  acquirerCompany: null,
  addresses: [],
  companyPageUrl: '',
  description: '',
  followerCount: null,
  foundedOn: '',
  industryName: '',
  name: '',
  rankForTopCompanies: null,
  staffCount: null,
  staffInterval: '',
  type: '',
  universalName: '',
  mainAddress: {
    country: '',
    city: '',
    headquarter: true,
    postalCode: null,
    description: '',
    line1: null,
  },
  rawAddresses: [],
};
