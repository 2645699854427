import React from 'react';
import { Col } from 'react-bootstrap';
import HomeSectionTable from '../common/HomeSectionTable/HomeSectionTable';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
export default function LatestLeads({ leads }) {
  const intl = useIntl();
  if (leads && leads.leads && leads.leads.length == 0) {
    return (
      <Col lg={6} md={12}>
        <div
          className=" home-card home-card-center info-card p-20 account-usage-card"
          style={{ minHeight: '300px' }}
        >
          <img src="/assets/images/tasks-image.png" alt="" />
          <h2>
            <FormattedMessage id={'home.leads.youDontHaveAnyLeadYet'} />
          </h2>
          <p>
            <FormattedMessage id={'home.leads.youCanAddNewLeadsFromLinkedin'} />
          </p>
          <a
            href={'https://www.linkedin.com/in/anne-charlotte-lazou/'}
            target={'_blank'}
            className={'btn btn1'}
            rel="noopener noreferrer nofollow"
          >
            <FormattedMessage id={'home.leads.addLeads'} />
          </a>
        </div>
      </Col>
    );
  }
  return (
    <Col lg={6} md={12}>
      <div className="home-card info-card p-20 account-usage-card" style={{ minHeight: '300px' }}>
        <h4>
          {' '}
          <FormattedMessage id={'home.leads.latestLeads'} />{' '}
        </h4>
        <HomeSectionTable
          tHeadData={['name', 'linkedin', 'location', 'email']}
          tBodyData={leads.leads}
        />
        <div className={'see-more-bottom'}>
          <NavLink to={'/leads'}>
            <FormattedMessage id={'home.seeMore'} />{' '}
          </NavLink>
        </div>
      </div>
    </Col>
  );
}
