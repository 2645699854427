import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { integrationAuthConnect } from '../../../../services/api/integrations.api';
import { FormattedMessage, injectIntl } from 'react-intl';

function ApiIntegration(props) {
  let [apiKey, setApiKey] = useState('');
  let [error, setError] = useState(null);

  const handleSubmit = async () => {
    setError(props.intl.formatMessage({ id: 'loading' }));
    let { data, statusCode } = await integrationAuthConnect({
      apiKey,
      integrationName: props.integration,
    });
    if (statusCode === 200) {
      setError(null);
      props.confirmConnect(data[props.integration + 'Email']);
      props.onHide();
      props.handleMappingModal();
    } else {
      if (data && (data.messageTranslated || data.message)) {
        setError(data.messageTranslated || data.message);
      } else {
        setError(props.intl.formatMessage({ id: 'invalidKeyApi' }));
      }
    }
  };

  return (
    <Modal show={props.show} className=" fade api-integration-wrapper" id="api-integration-modal">
      <Modal.Header className="border-0">
        <button
          type="button"
          className="close"
          onClick={() => {
            setError(null);
            props.onHide();
          }}
        >
          <img src="/assets/images/ic-cross.png" alt="" />
        </button>
        <div className="modal-inner-header">
          <div className="profile-info">
            <div className="title">
              {' '}
              <img
                src={'/assets/images/' + props.imagesrc}
                alt=""
                style={{ marginRight: 10, width: 30 }}
              />
              <div className={'capital'}>{props.integration}</div>{' '}
              <FormattedMessage id={'settings.apiIntegration'} />
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="inner-description">
          <div>
            <FormattedMessage
              id={'settings.theApiKeyAllowsYouTo_'}
              values={{
                value: <div className={'capital'}> {props.integration}</div>,
              }}
            />
            <a href={props.apiKeyLink} target="_blank" className="text-btn">
              <FormattedMessage id={'settings.clickHere'} />
            </a>
          </div>
          <div className="form-group">
            <label>
              {' '}
              <FormattedMessage id={'settings.apiKey'} />
            </label>
            <input
              type="text"
              id="contact_email"
              name="email"
              className="invalid"
              placeholder=" "
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
          {error && (
            <div
              className="text-center  error  kaspr-form-text-error"
              style={{ top: 10, width: '90%' }}
            >
              {error + ' '}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <div className="right-ui">
          <a href="#" className="btn1 ripple-btn" onClick={handleSubmit}>
            <FormattedMessage id={'settings.submit'} />
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(ApiIntegration);
