import React, { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../../../../components';
import { injectIntl } from 'react-intl';

function CheckBox({ onChange, element, required, id, inputValidationId, intl }) {
  const [idValidation, setIdValidation] = useState('');
  useEffect(() => {
    if (inputValidationId) {
      setIdValidation(inputValidationId);
    }
  }, [inputValidationId]);
  let handeOnChange = (e) => {
    if (e.target.checked) {
      setIdValidation('');
    } else {
      setIdValidation(id);
    }
    onChange(e.target.checked);
  };
  return (
    <div className="checkbox-wrap">
      <label>
        <input type="checkbox" value="" onChange={handeOnChange} id={id} />
        {required && idValidation === id && (
          <div style={{ position: 'relative', left: -180, top: 30 }}>
            <OverlayTrigger
              placement="bottom"
              show={true}
              overlay={(props) =>
                Tooltip({
                  ...props,
                  data: intl.formatMessage({ id: 'auth.thisFieldIsMandatory' }),
                })
              }
            >
              <div />
            </OverlayTrigger>
          </div>
        )}
        <span className="checkbox-icon"></span>
        {element}
      </label>
    </div>
  );
}
export default injectIntl(CheckBox);
