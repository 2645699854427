import React, { useContext, useState, useEffect, useRef } from 'react';
import './DropdownLeadList.css';
import { BulkEnrichmentContext } from '../../store/bulkEnrichment.store';
import actions from '../../store/bulkEnrichment.actions';

function DropdownLeadList({ defaultName }) {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(BulkEnrichmentContext);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (state.labels.length > 1) {
      setIsOpen(!isOpen);
    }
  };
  const selectOption = (label) => {
    dispatch({
      type: actions.SET_SELECTED_LABEL,
      payload: [label],
    });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div id="dropdown-lead-list" className="dropdown-container" ref={dropdownRef}>
      <div className={`${isOpen ? `button  is-open` : `button`}`} onClick={toggleDropdown}>
        <p className="ellipsis">
          {state.selectedLabel[0].name || state.selectedLabel.name || defaultName}
        </p>
        {state.labels.length > 1 && (
          <img height={'auto'} src={'/assets/images/ic-arrow-bottom.png'} alt={'arrow down'} />
        )}
      </div>
      {isOpen && (
        <ul>
          {state.labels.map((label, index) => (
            <li
              key={index}
              onClick={() => selectOption(label)}
              className="ellipsis"
              title={label.name}
            >
              {label.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default DropdownLeadList;
