const initState = {};

export const labels = (state = initState, action) => {
  let labels;
  switch (action.type) {
    case 'GET_LABELS':
      labels = action.labels.labels;
      state = {
        ...state,
        labels,
      };
      break;
    case 'ADD_LABEL':
      const _newLabel = [];

      _newLabel.push(action.newLabel);
      state = {
        ...state,
        labels: [...state.labels, ..._newLabel],
      };
      break;
    case 'UPDATE_LABEL':
      let index = state.labels.findIndex((label) => label.id == action.label.id);
      labels = state.labels;
      labels[index] = action.label;
      state = {
        ...state,
        labels,
      };
      break;
    case 'DELETE_LABEL':
      if (state.labels) {
        labels = state.labels.filter((label) => label.id !== action.labelId);
        state = {
          ...state,
          labels,
        };
      }

      break;
  }

  return state;
};
