import React from 'react';
import { AuthContext } from './auth.store';
import { PropTypes } from 'prop-types';

function AuthProvider({ children, store }) {
  return (
    <AuthContext.Provider value={store}>
      <main id="auth">{children}</main>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  store: PropTypes.shape({
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthProvider;
