import { API_URL } from '../../constants';
import { convertToJsonAndHandleErrors, getToken } from '../utils';

export const getTemplates = () => {
  return fetch(`${API_URL}/workflows/templates`, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getWorkflows = (options) => {
  //  let {organizationId, filters, page = 0} = req.body;
  return fetch(`${API_URL}/workflows/all`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(options),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const getWorkflow = (workflowId) => {
  //  let {organizationId, filters, page = 0} = req.body;
  return fetch(`${API_URL}/workflows/one/` + workflowId, {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const createWorkflow = (workflow) => {
  return fetch(`${API_URL}/workflows/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ workflow }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateBlock = (block) => {
  return fetch(`${API_URL}/workflows/blocks`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify({ block }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteBlock = (automation) => {
  return fetch(`${API_URL}/workflows/blocks`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify({
      workflowId: automation.workflow,
      blockId: automation._id,
    }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getPerformance = (workflow) => {
  return fetch(`${API_URL}/workflows/performance`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify({ workflow }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const getProfiles = (workflowOptions) => {
  return fetch(`${API_URL}/workflows/profiles`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify(workflowOptions),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};
export const deleteProfiles = (ids) => {
  return fetch(`${API_URL}/workflows/profiles`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify({ ids }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { statusCode, data };
    });
};

export const deleteWorkflow = (_id) => {
  //  let {organizationId, filters, page = 0} = req.body;
  return fetch(`${API_URL}/workflows`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ _id }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const updateWorkflow = (workflow) => {
  //  let {organizationId, filters, page = 0} = req.body;
  return fetch(`${API_URL}/workflows`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify({ workflow }),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const updateWorkflowActivationState = (workflow) => {
  return fetch(`${API_URL}/workflows/state`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(workflow),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
export const getFlowInputs = (workflow) => {
  return fetch(`${API_URL}/workflows/flowinputs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(workflow),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};

export const exportCsv = (workflow) => {
  return fetch(`${API_URL}/workflows/profiles/export`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(workflow),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const exportExcel = (workflow) => {
  return fetch(`${API_URL}/workflows/profiles/exportExcel`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(workflow),
  }).then(async (res) => {
    const data = await res.blob();
    const statusCode = res.status;
    return { statusCode, data };
  });
};

export const workflowRelaunch = (workflow) => {
  return fetch(`${API_URL}/workflows/relaunch`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      json: true,
      Authorization: getToken(),
    },
    body: JSON.stringify(workflow),
  })
    .then(convertToJsonAndHandleErrors)
    .then(({ data, statusCode }) => {
      return { data, statusCode };
    });
};
