import React from 'react';
import actions from './workflow.actions';

const WorkflowContext = React.createContext();

const workflowInitialState = {
  templates: [],
  workflows: null,
  selectedTemplate: null,
  selectedWorkflow: null,
  error: null,
  blockConfigs: [],
  inviteConfig: { options: {} },
  messageConfig: { options: {} },
  timerConfig: { options: { checkDuration: {} } },
  performance: null,
  profiles: null,
  flowInputs: null,
  search: '',
  workflowsFilters: {},
  selectedIntegration: false,
  workflowsDetails: {},
  selectedIntgDropdown: [],
  enrichConfig: {
    isPhoneRequired: false,
    isEmailProRequired: false,
    workflowName: '',
    dataToGet: { workEmails: true, phones: true, directEmails: true },
  },
};

const workflowReducer = (state, action) => {
  switch (action.type) {
    case actions.GET_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case actions.SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    case actions.GET_WORKFLOWS:
      return {
        ...state,
        workflows: action.payload,
      };
    case actions.SELECT_WORKFLOW:
      return {
        ...state,
        selectedWorkflow: action.payload,
      };
    case actions.GET_PERFORMANCE:
      return {
        ...state,
        performance: action.payload,
      };
    case actions.GET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
      };
    case actions.GET_AUTOMATIONS:
      return {
        ...state,
        flowInputs: action.payload,
      };
    case actions.SEARCH_PROFILE:
      return {
        ...state,
        search: action.payload,
      };
    case actions.WORKFLOWS_FILTERS:
      return {
        ...state,
        workflowsFilters: action.payload,
      };
    case actions.SET_SELECTED_INTEGRATION:
      return {
        ...state,
        selectedIntegration: action.payload,
      };

    case actions.SET_WORKFLOWS_DETAILS:
      return {
        ...state,
        workflowsDetails: action.payload,
      };

    case actions.SELECTED_INTG_DROPDOWN:
      return {
        ...state,
        selectedIntgDropdown: action.payload,
      };

    case actions.SET_ENRICH_CONFIG:
      return {
        ...state,
        enrichConfig: action.payload,
      };

    default:
      return state;
  }
};

export { WorkflowContext, workflowInitialState, workflowReducer, actions };
