import React, { useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SalesforceTabs from './SalesforceTabs/SalesforceTabs';
import { getIntegrationProperties } from '../../../../../services/api/integrations.api';
import { FormattedMessage, injectIntl } from 'react-intl';
import MappingTableRow from './MappingTableRow/MappingTableRow';
import { toast, ToastContainer } from 'react-toastify';
import './MappingModal.css';
import { settingsReducer, settingsInitialState } from '../../../store/Settings.store';
import {
  handleSetMappedValues,
  handleSetKasprProperties,
  handleSetMappedProperties,
  handleSetIntegrationProperties,
  handleSetShowKasprPropertiesAutoComplete,
  handleSetIsCustomValue,
  handleSetPropertiesAutoComplete,
  handleSetPropertyIndex,
  handleSetShowAutoComplete,
} from './handleContextDispatch/handleContextDispatch';
import {
  onChangeKaspr,
  onChangeIntegration,
  handleOnBlur,
  addMapping,
  handleDeleteMapping,
  onSelectItem,
  enableSearch,
  disableSearch,
  handleSubmitMapping,
} from './MappingUtils/MappingUtils';

function MappingModal({ intl, show, onHide, integration, isLoading, setIsLoading }) {
  const [state, dispatch] = useReducer(settingsReducer, settingsInitialState);
  const [salesforceTab, setSalesforceTab] = useState('lead');

  useEffect(() => {
    if (['linkedin', 'zapier'].indexOf(integration) > -1) return;
    let body = {
      integrationName: integration,
    };
    if (integration === 'salesforce') body.sObject = salesforceTab;
    setIsLoading(true);
    getIntegrationProperties(body).then(({ data, statusCode }) => {
      if (statusCode >= 400) {
        setIsLoading(false);
        toast.error(data.messageTranslated || data.message);
        return;
      }
      let properties = data && data.properties && data.properties.kasprProperties;
      if (properties && properties.customFields) {
        for (let i in properties.customFields)
          properties['customField-' + i] = properties.customFields[i];
        delete properties.customFields;
        data.properties.kasprProperties = properties;
      }
      if (integration === 'zohocrm') {
        handleSetIntegrationProperties(
          data && data.properties && data.properties['zohoCrmProperties'],
          dispatch,
        );
      } else {
        handleSetIntegrationProperties(
          data && data.properties && data.properties[integration + 'Properties'],
          dispatch,
        );
      }

      handleSetKasprProperties(
        data && data.properties && data.properties.kasprProperties,
        dispatch,
      );
      handleSetPropertiesAutoComplete(
        Object.keys(data && data.properties && data.properties.kasprProperties),
        dispatch,
      );
      let mapping = data && data.properties && data.properties.kasprProperties;

      let keys = [];
      let values = [];
      let customValues = [];
      for (let property in mapping) {
        if (mapping[property] && mapping[property].length) {
          keys.push(property.replace('customField-', ''));
          values.push(mapping[property]);
          customValues.push(property.indexOf('customField-') > -1);
        }
      }
      handleSetMappedProperties(keys, dispatch);
      handleSetMappedValues(values, dispatch);
      handleSetIsCustomValue(customValues, dispatch);
      setIsLoading(false);
    });
  }, [integration, salesforceTab]);

  if (!(state.kasprProperties && state.integrationProperties)) {
    return (
      <div>
        <ToastContainer position="bottom-right" style={{ top: 100 }} />
      </div>
    );
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="fade configration-modal-wrapper"
      id="mapping-modal"
    >
      <div className="modal-content modal-container">
        <Modal.Header closeButton className=" border-0">
          <div className="modal-inner-header">
            <h4 className="title">
              <FormattedMessage id={'leads.properties.propertiesConfig'} />
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="configration-inner-ui">
            {integration === 'salesforce' && (
              <SalesforceTabs salesforceTab={salesforceTab} setSalesforceTab={setSalesforceTab} />
            )}
            {!isLoading && (
              <table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id={'leads.properties.mappingProperties'}
                        values={{
                          var: 'Kaspr',
                        }}
                      />
                    </th>
                    <th className="space"></th>
                    <th className="username">
                      {' '}
                      <FormattedMessage
                        id={'leads.properties.mappingProperties'}
                        values={{
                          var:
                            integration === 'zohocrm'
                              ? 'Zoho'
                              : integration[0].toUpperCase() + integration.slice(1),
                        }}
                      />{' '}
                    </th>
                    <th>
                      <div className="break"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.mappedProperties.map((kasprProperty, i) => (
                    <MappingTableRow
                      kasprProperty={kasprProperty}
                      i={i}
                      state={state}
                      dispatch={dispatch}
                      intl={intl}
                      enableSearch={enableSearch}
                      handleOnBlur={handleOnBlur}
                      onChangeKaspr={onChangeKaspr}
                      handleSetPropertiesAutoComplete={handleSetPropertiesAutoComplete}
                      handleSetPropertyIndex={handleSetPropertyIndex}
                      handleSetShowKasprPropertiesAutoComplete={
                        handleSetShowKasprPropertiesAutoComplete
                      }
                      handleSetShowAutoComplete={handleSetShowAutoComplete}
                      onSelectItem={onSelectItem}
                      disableSearch={disableSearch}
                      onChangeIntegration={onChangeIntegration}
                      integration={integration}
                      handleDeleteMapping={handleDeleteMapping}
                    />
                  ))}
                  <tr>
                    <div className="add-new-field">
                      <div
                        className="right-ui add-new-field-button"
                        onClick={(e) => addMapping(e, state, dispatch)}
                      >
                        <div className="quantity-btn-ui new-field-container">
                          <button type="button" id="add2" className="quty-btn add">
                            <span className="icon-ic_add">
                              <img src="/assets/images/ic-add.svg" alt="" />
                            </span>
                          </button>
                        </div>
                        <FormattedMessage id={'leads.properties.addMapping'} />
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>
            )}
            {state.error && (
              <div
                className="text-center  error  kaspr-form-text-error"
                style={{ top: 10, width: '90%' }}
              >
                {state.error + ' '}
              </div>
            )}
          </div>
          {isLoading && (
            <div className="configration-inner-ui loading">
              <img src={'/assets/images/loading.gif'} alt={'loading'} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer border-0">
          <a
            href="#"
            className="btn1 btn-md"
            onClick={(e) =>
              handleSubmitMapping(e, integration, onHide, state, dispatch, intl, salesforceTab)
            }
          >
            <FormattedMessage id={'leads.validateProperties'} />
          </a>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default injectIntl(MappingModal);
