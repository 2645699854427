import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../index';

function PaginationInput({ min = 1, pages, currentPage, onChange, lock, intl }) {
  const [value, setValue] = useState(currentPage || 1);
  useEffect(() => {
    setValue(currentPage);
  }, [currentPage]);
  const increment = () => {
    if (value === pages || lock) return;
    if (value < pages) {
      setValue(value + 1);
      onChange(value + 1);
    } else {
      onChange(value);
      setValue(value);
    }
  };
  const decrement = () => {
    if (value === 1 || lock) return;
    setValue(value - 1);
    if (value > min) {
      setValue(value - 1);
      onChange(value - 1);
    } else {
      onChange(value);
      setValue(value);
    }
  };
  const handleOnBlur = () => {
    if (value <= min || !value) {
      setValue(currentPage);
      onChange(currentPage);
    }
  };
  return (
    <div
      className="modify-user-block"
      style={{
        width: 'max-content',
        margin: 0,
        padding: 0,
        transform: 'scale(0.8)',
      }}
    >
      <div className="right-ui">
        <div className="quantity-btn-ui">
          {value <= min ? (
            <button
              type="button"
              id="sub2"
              className="quty-btn sub"
              onClick={decrement}
              style={value <= min ? { background: '#fff', filter: 'blur(3px)' } : {}}
            >
              <span className="icon-i_minus">
                <img style={{ width: 7 }} src="/assets/images/ic_arrow_left.png" alt="Prev" />
              </span>
            </button>
          ) : (
            <button
              type="button"
              id="sub2"
              className="quty-btn sub"
              onClick={decrement}
              style={value <= min ? { background: '#fff', filter: 'blur(3px)' } : {}}
            >
              <span className="icon-i_minus">
                <img style={{ width: 7 }} src="/assets/images/ic_arrow_left.png" alt="Prev" />
              </span>
            </button>
          )}
          <input
            type="number"
            id="2"
            value={value}
            onChange={
              lock
                ? () => false
                : (e) => {
                    if (parseInt(e.target.value) <= pages || !e.target.value) {
                      if (e.target.value >= min) {
                        onChange(parseInt(e.target.value));
                      }
                      setValue(parseInt(e.target.value));
                    } else {
                      setValue(pages);
                      onChange(pages);
                    }
                  }
            }
            min="1"
            max={pages}
            onBlur={handleOnBlur}
          />

          <button
            type="button"
            id="add2"
            className="quty-btn add"
            onClick={increment}
            style={value >= pages ? { background: '#fff', filter: 'blur(3px)' } : {}}
          >
            <span className="icon-ic_add">
              {lock ? (
                <OverlayTrigger
                  key={'plan-permission'}
                  placement={'top'}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data: 'You have to upgrade your plan to be able to use this feature',
                    })
                  }
                >
                  <img
                    src="assets/images/lockBlack.png"
                    style={{ width: 15, marginRight: 7 }}
                    alt=""
                  />
                </OverlayTrigger>
              ) : (
                <img
                  style={{ transform: 'rotate(180deg)', width: 7 }}
                  src="/assets/images/ic_arrow_left.png"
                  alt="Prev"
                />
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(PaginationInput);
