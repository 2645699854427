import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
let language = navigator.language.split(/[-_]/)[0];
language = language !== 'fr' && language !== 'en' ? 'en' : language;
export default function CheckDevice({ location }) {
  return (
    <FormWrapper>
      {location.state && !location.state.isKasprUser ? (
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ color: '#877BEB', marginBottom: 50 }}>
            You can register on a computer to get contact information and start using Kaspr.
          </h2>
          <a href={'https://www.kaspr.io/talk-to-sales'} className={'btn1'}>
            Book a demo
          </a>
        </div>
      ) : (
        <h2 style={{ color: '#877BEB', marginBottom: 50 }}>
          To log in make sure you are on a computer to start searching contact details.
        </h2>
      )}
      <Footer />
    </FormWrapper>
  );
}

function Footer() {
  return (
    <footer>
      <div className="container">
        <ul className="footer-list">
          <li>©2021 Kaspr</li>
          <li>·</li>
          <li>
            <p>
              <a target="_blank" href="https://www.kaspr.io/privacy-policy">
                Privacy
              </a>{' '}
              &amp;{' '}
              <a target="_blank" href="https://kaspr.io/terms/">
                Terms
              </a>
            </p>
          </li>
        </ul>
      </div>
    </footer>
  );
}
function FormWrapper({ children }) {
  return (
    <section className="signup-wrapper">
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <div
              className="white-bg padding40 border-radius-12 mx-auto mt-5"
              style={{ maxWidth: '450px' }}
            >
              <div className="form-heading-grp text-center">
                {/*<h4 className="h4">Welcome back</h4>*/}
                <h4 className="h4">Welcome</h4>
                <p>It’s great to see you.</p>
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
