const actions = {
  TOGGLE: 'TOGGLE',
  GET_CREDITS: 'GET_CREDITS',
  GET_LEADS: 'GET_LEADS',
  GET_TASKS: 'GET_TASKS',
  GET_WORKFLOWS: 'GET_WORKFLOWS',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
};

export default actions;
