import React from 'react';
import { Col } from 'react-bootstrap';

function BulkEnrichmentWrapper({ children }) {
  return (
    <Col md={10} className="content-wrap">
      <div className="content-block">
        <div className="inner-content-wrap">
          <div className="wd-wrapper">{children}</div>
        </div>
      </div>
    </Col>
  );
}

export default BulkEnrichmentWrapper;
