import { Tooltip } from '../index';
import { OverlayTrigger } from 'react-bootstrap';
import React from 'react';

export default function InfoToolTip({ text, position, containerStyle, style }) {
  return (
    <OverlayTrigger
      key={'i_info'}
      placement={position || 'top'}
      overlay={(props) =>
        Tooltip({
          ...props,
          data: text,
          container_style: containerStyle,
        })
      }
    >
      <img style={style} src="/assets/images/icon-info.svg" alt="" />
    </OverlayTrigger>
  );
}
