import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import HomeSectionTable from '../common/HomeSectionTable/HomeSectionTable';
import { NavLink } from 'react-router-dom';
import { clickNotification } from '../../../services/api/notifications.api';
import { formatNotificationsDate } from '../../../services/utils/formatDate';
import { FormattedMessage } from 'react-intl';
export default function HomeNotifications({ notifications }) {
  const [toggle, setToggle] = useState(false);
  const [notificationsElements, setNotificationsElement] = useState([]);
  useEffect(() => {
    let regex = /{{(.*?)}}/g;
    if (!notifications) return setNotificationsElement([]);
    let notifs;
    try {
      notifs = notifications.notifications.map((notif) => {
        const variables = notif.template.message.match(regex);
        if (variables && variables.length > 0) {
          variables.forEach((variable, i) => {
            let currentVar = variables[i];
            currentVar = currentVar.replace('{{', '');
            currentVar = currentVar.replace('}}', '');
            let dataVar = notif.data[currentVar];

            if (dataVar) {
              let htmTagStart = dataVar.link
                ? "<a class='username' href=" + dataVar.link + ' >'
                : '<strong>';
              let htmlEndTag = dataVar.link ? '</a>' : '</strong>';
              notif.template.message = notif.template.message.replace(
                variables[i],
                htmTagStart +
                  (dataVar.value &&
                    dataVar.value.length &&
                    dataVar.value[0].toUpperCase() + dataVar.value.slice(1)) +
                  htmlEndTag,
              );
            }
          });
        }
        return notif;
      });
    } catch (err) {
      notifs = [];
    }
    setNotificationsElement(notifs);
  }, [notifications]);
  const getIcon = (type) => {
    switch (type) {
      case 'KasprInvitation':
        return 'ic-message.png';
      case 'NewMemberInvitation':
        return 'ic-message.png';
      case 'Invitation':
        return 'ic-message.png';
      case 'NewMemberJoinReq':
        return 'ic-message.png';
      case 'GmailReply':
        return 'ic-message.png';
      case 'JoinReq':
        return 'ic-message.png';
      case 'SuccessfulPayment':
        return 'ic-user-check.png';
      case 'Admin':
        return 'ic-gift.png';
      case 'preLeadToExplore':
        return 'Marketing.svg';
      default:
        return 'ic-box.png';
    }
  };
  const handleNotificationClick = async (notif, i) => {
    if (!notif.id) return;
    let { data, statusCode } = await clickNotification(notif.id);
    if (statusCode === 200) {
      let notifs = notificationsElements;
      notifs[i].isClicked = true;
      setNotificationsElement(notifs);
    }
  };
  if (!notifications || (notifications && !notifications.notifications)) return null;
  if (notifications.notifications.length == 0) {
    return (
      <Col lg={6} md={12}>
        <div
          className=" home-card home-card-center info-card p-20 account-usage-card"
          style={{ minHeight: '300px' }}
        >
          <img src="/assets/images/tasks-image.png" alt="" />
          <h2>
            <FormattedMessage id={'home.yourNotificationHub'} />
          </h2>
          <p>
            <FormattedMessage id={'home.noUpdateYet'} />
          </p>
        </div>
      </Col>
    );
  }
  return (
    <Col lg={6} md={12}>
      <div className="home-card info-card p-20 account-usage-card" style={{ minHeight: '274px' }}>
        <div style={{ padding: 10 }}>
          <h4 style={{ marginBottom: 10 }}>
            <FormattedMessage id={'home.notifications'} />
          </h4>
          <ul className="notification-list">
            <div>
              {notificationsElements.map((notif, i) => (
                <li key={i}>
                  <div
                    className={'notification-msg-container'}
                    onClick={() => handleNotificationClick(notif, i)}
                  >
                    <span className={'image-container '}>
                      <img src={'/assets/images/' + getIcon(notif.template.name)} alt="" />
                    </span>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: notif.template.message,
                      }}
                    />
                  </div>
                  <small>{formatNotificationsDate(notif.dateAdded)} </small>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </Col>
  );
}
