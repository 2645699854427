import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../../../../../../components';
import ListDrop from '../../MappingProperties/ListDrop';
import React from 'react';

const MappingTableRow = ({
  kasprProperty,
  i,
  state,
  dispatch,
  intl,
  enableSearch,
  handleOnBlur,
  onChangeKaspr,
  handleSetPropertiesAutoComplete,
  handleSetPropertyIndex,
  handleSetShowKasprPropertiesAutoComplete,
  handleSetShowAutoComplete,
  onSelectItem,
  disableSearch,
  onChangeIntegration,
  integration,
  handleDeleteMapping,
}) => {
  return (
    <tr className="tbody-top">
      <td className="properties-column">
        <div className="form-group column">
          <a
            className="search-icon"
            onClick={(e) => enableSearch(e, i, state, dispatch)}
            onBlur={() => handleOnBlur(state, dispatch)}
          >
            <img src="/assets/images/search-icon.svg" alt="" />
          </a>
          {state.isCustomValue[i] ? (
            <OverlayTrigger
              placement="top"
              overlay={(propsOverlay) =>
                Tooltip({
                  ...propsOverlay,
                  data: intl.formatMessage({
                    id: 'leads.properties.switchKasprProperty',
                  }),
                })
              }
            >
              <input
                type="text"
                placeholder={
                  state.isCustomValue[i]
                    ? intl.formatMessage({
                        id: 'leads.properties.writeProperty',
                      })
                    : '...'
                }
                value={kasprProperty.replace('customField-', '')}
                onChange={(e) => onChangeKaspr(e, i, state, dispatch, intl)}
                onBlur={() => handleOnBlur(state, dispatch)}
                autoComplete={'off'}
              />
            </OverlayTrigger>
          ) : (
            <input
              type="text"
              placeholder={
                state.isCustomValue[i]
                  ? intl.formatMessage({
                      id: 'leads.properties.writeProperty',
                    })
                  : '...'
              }
              value={
                kasprProperty.indexOf('customField-') === -1 &&
                Object.keys(state.kasprProperties).indexOf(kasprProperty) === -1
                  ? kasprProperty
                  : intl.formatMessage({
                      id: 'leads.properties.' + kasprProperty,
                    })
              }
              onFocus={() => {
                handleSetPropertiesAutoComplete(Object.keys(state.kasprProperties), dispatch);
                handleSetPropertyIndex(i, dispatch);
                handleSetShowKasprPropertiesAutoComplete(true, dispatch);
                handleSetShowAutoComplete(true, dispatch);
              }}
              onChange={(e) => onChangeKaspr(e, i, state, dispatch, intl)}
              onBlur={() => handleOnBlur(state, dispatch)}
              autoComplete={'off'}
            />
          )}
          {state.showAutoComplete &&
            state.showKasprPropertiesAutoComplete &&
            i === state.propertyIndex &&
            !state.isCustomValue[i] && (
              <div className="form-group autocomplete">
                <form>
                  <div className={'two-div'} style={{ display: 'flex' }}>
                    <ListDrop
                      type={'kaspr'}
                      title={''}
                      defaultLabel={''}
                      properties={state.propertiesAutoComplete}
                      onSelectItem={onSelectItem('kaspr', i, state, dispatch, intl)}
                      disableSearch={disableSearch(i, state)}
                      style={{ border: '2px solid red' }}
                      value={state.mappedProperties[i] || null}
                      dropDownStyle={{
                        width: '139%',
                        marginTop: 5,
                      }}
                      formInStyle={{ width: '60%' }}
                    />
                  </div>
                </form>
              </div>
            )}
        </div>
      </td>
      <td>
        <div className="middle-arrow">
          <span className="ic-config">
            <img src="/assets/images/ic-config.svg" alt="" />
          </span>
        </div>
      </td>
      <td className="properties-column">
        <div className="form-group column-left">
          <span className="search-icon">
            <img src="/assets/images/search-icon.svg" alt="" />
          </span>
          <input
            type="text"
            placeholder={'...'}
            value={state.mappedValues[i]}
            onFocus={() => {
              handleSetPropertyIndex(i, dispatch);
              handleSetShowKasprPropertiesAutoComplete(false, dispatch);
              handleSetShowAutoComplete(true, dispatch);
              handleSetPropertiesAutoComplete(state.integrationProperties, dispatch);
            }}
            onChange={(e) => onChangeIntegration(e, i, state, dispatch)}
            onBlur={() => handleOnBlur(state, dispatch)}
            autoComplete={'off'}
          />
          {state.showAutoComplete &&
            !state.showKasprPropertiesAutoComplete &&
            i === state.propertyIndex && (
              <div className="form-group autocomplete-right">
                <form className="" onSubmit={(e) => {}}>
                  <div className={'two-div'} style={{ display: 'flex' }}>
                    <ListDrop
                      type={integration}
                      title={''}
                      defaultLabel={'choose property'}
                      properties={state.propertiesAutoComplete}
                      onSelectItem={onSelectItem('integration', i, state, dispatch, intl)}
                      style={{ border: '2px solid red' }}
                      value={state.mappedProperties[i] || null}
                      dropDownStyle={{
                        width: '138%',
                        marginTop: 5,
                      }}
                      formInStyle={{
                        width: '60%',
                      }}
                    />
                  </div>
                </form>
              </div>
            )}
        </div>
      </td>
      <td style={{ left: '30px' }}>
        <div className="button-ui delete-account">
          <div className="delete-button">
            <img
              src="/assets/images/ic_delete_g.png"
              alt="delete button"
              onClick={(e) => handleDeleteMapping(e, i, state, dispatch)}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default MappingTableRow;
