import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import RingoverIframe from './RingoverIframe';

function Ringover({ handleToggle, phones, leadId }) {
  return (
    <div style={{ paddingLeft: '35px' }}>
      <button className="close" onClick={() => handleToggle(false)}>
        <img src="/assets/images/ic-cross.png" alt="" />
      </button>
      <h4 className="title" style={{ fontSize: '20px' }}>
        <FormattedMessage id={'leads.callUsingRingover'} />
      </h4>
      <RingoverIframe phones={phones} leadId={leadId} />
    </div>
  );
}

export default Ringover;
