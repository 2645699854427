import React, { useState } from 'react';
import './Files.css';
import FilesHeader from './FilesHeader/FilesHeader';
import FilesList from './FilesList/FilesList';
import { usePermissions } from '../../../components/Permissions/permissions.hooks';
import { Permissions } from '../../../components';

const Files = ({ profile, credits }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [checkPlanPermissionsWk, checkMemberPermissionsWk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    'Workflow',
  );
  const [checkPlanPermissionsBulk, checkMemberPermissionsBulk] = usePermissions(
    credits &&
      credits.organizationCredits &&
      credits.organizationCredits.plan &&
      credits.organizationCredits.plan.permissions,
    profile && profile.permissions,
    'BulkEnrichment',
  );

  if (!checkPlanPermissionsBulk('View') || !checkMemberPermissionsWk('View'))
    return <Permissions.View title="permissions.denied" description="permissions.noAccess" />;

  return (
    <div id="files" className="files-container">
      <FilesHeader currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <FilesList currentPage={currentPage} profile={profile} credits={credits} />
    </div>
  );
};

export default Files;
