import React, { useEffect } from 'react';
import changePos from './changePos';
import { injectIntl } from 'react-intl';

function VerticalSlider({ intl }) {
  useEffect(() => {
    let interval = null;
    interval = setInterval(changePos, 5000);
    return () => clearInterval(interval);
  });
  return (
    <div className="verticalSlider">
      <div className="list-container">
        <ul className="list">
          <li className="tab-0">
            <div className="inner-item">
              <figure>
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
              </figure>
              <h5 className="title">{intl.formatMessage({ id: 'auth.firstReview.title' })}</h5>
              <p className="desc">{intl.formatMessage({ id: 'auth.firstReview.description' })}</p>
              <div className="profile-ui">
                <div className="img-ui">
                  <img src="https://randomuser.me/api/portraits/men/19.jpg" alt="" />
                </div>
                <div className="content-ui">
                  <p>Nathaniel Trojman</p>
                  <span>CEO at 26 Academy</span>
                </div>
              </div>
            </div>
          </li>
          <li className="tab-1">
            <div className="inner-item">
              <figure>
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
              </figure>
              <h5 className="title"> {intl.formatMessage({ id: 'auth.secondReview.title' })}</h5>
              <p className="desc">{intl.formatMessage({ id: 'auth.secondReview.description' })}</p>
              <div className="profile-ui">
                <div className="img-ui">
                  <img src="https://randomuser.me/api/portraits/women/19.jpg" alt="" />
                </div>
                <div className="content-ui">
                  <p>Elie Bisimwa</p>
                  <span>Team Leader chez GetQuanty</span>
                </div>
              </div>
            </div>
          </li>
          <li className="tab-2">
            <div className="inner-item">
              <figure>
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
              </figure>
              <h5 className="title"> {intl.formatMessage({ id: 'auth.thirdReview.title' })}</h5>
              <p className="desc">{intl.formatMessage({ id: 'auth.thirdReview.description' })}</p>
              <div className="profile-ui">
                <div className="img-ui">
                  <img src="https://randomuser.me/api/portraits/men/32.jpg" alt="" />
                </div>
                <div className="content-ui">
                  <p>François de Froment</p>
                  <span>Responsable de la croissance chez Agicap</span>
                </div>
              </div>
            </div>
          </li>
          <li className="tab-3">
            <div className="inner-item">
              <figure>
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
              </figure>
              <h5 className="title">{intl.formatMessage({ id: 'auth.forthReview.title' })} </h5>
              <p className="desc">{intl.formatMessage({ id: 'auth.forthReview.description' })}</p>
              <div className="profile-ui">
                <div className="img-ui">
                  <img src="https://randomuser.me/api/portraits/women/4.jpg" alt="" />
                </div>
                <div className="content-ui">
                  <p>Mateusz Jaworski</p>
                  <span>SDR Manager at Cloud Direct</span>
                </div>
              </div>
            </div>
          </li>
          <li className="tab-4">
            <div className="inner-item">
              <figure>
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
                <img src="/assets/images/ic-star.png" alt="" />
              </figure>
              <h5 className="title">{intl.formatMessage({ id: 'auth.thirdReview.title' })}</h5>
              <p className="desc">{intl.formatMessage({ id: 'auth.thirdReview.description' })}</p>
              <div className="profile-ui">
                <div className="img-ui">
                  <img src="https://randomuser.me/api/portraits/men/32.jpg" alt="" />
                </div>
                <div className="content-ui">
                  <p>François de Froment</p>
                  <span>Head of Growth at Agicap"</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default injectIntl(VerticalSlider);
