import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ReferenceLine, CartesianGrid } from 'recharts';
import HistoryChartFilters from '../common/HistoryChartFilters/HistoryChartFilters';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrganizationContext } from '../../store/Organization.store';
import { CREDIT_COLORS, CREDITS } from '../../../../constants';

/*=========== CREDITS HISTORY ===========*/
function CreditsHistory({ data, screenSize, checkPlanReportsPermissions, isVersion2 }) {
  const [startDateToggle, setStartDateToggle] = useState(false);
  const [endDateToggle, setEndDateToggle] = useState(false);
  const [creditTypeColor, setCreditsTypeColor] = useState(CREDIT_COLORS['workEmailCredits'].color);
  const intl = useIntl();
  const { state } = useContext(OrganizationContext);
  useEffect(() => {
    if (state.chartFilters.creditType) {
      if (CREDIT_COLORS[state.chartFilters.creditType]) {
        setCreditsTypeColor(CREDIT_COLORS[state.chartFilters.creditType].color);
      }
    }
  }, [state.chartFilters.creditType]);
  const blockRef = useRef();
  const [widthSize, setWidthSize] = useState(800);
  useEffect(() => {
    if (blockRef.current) {
      setWidthSize(blockRef.current.offsetWidth - 100);
    }
  }, [blockRef.current, screenSize]);

  const CREDIT_TYPES = CREDITS;

  return (
    <GraphBlockWrapper type={'history'} blockRef={blockRef}>
      <HistoryChartFilters
        setStartDateToggle={setStartDateToggle}
        startDateToggle={startDateToggle}
        setEndDateToggle={setEndDateToggle}
        endDateToggle={endDateToggle}
        checkPlanPermissions={checkPlanReportsPermissions}
        isVersion2={isVersion2}
      />
      <AreaChart
        width={widthSize}
        height={350}
        data={data}
        margin={{ top: 40, right: 10, left: 10, bottom: 0 }}
      >
        <defs>
          {CREDIT_TYPES.map((credit) => (
            <linearGradient id={credit} x1="0" y1="0" x2="1" y2="1">
              <stop offset="1%" stopColor={CREDIT_COLORS[credit].color} stopOpacity={0.5} />
              <stop offset="95%" stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        {CREDIT_TYPES.map((credit) => {
          if (credit !== 'legacyCredits' && !isVersion2) {
            return null;
          }
          return (
            <Area
              type="monotone"
              dataKey={credit}
              stroke={CREDIT_COLORS[credit].color}
              fillOpacity={1}
              strokeWidth={1}
              fill={'url(#' + credit + ')'}
              dot={{
                stroke: CREDIT_COLORS[credit].color,
                fill: '#fff',
                strokeWidth: 2,
                r: 2,
              }}
              animationBegin={200}
            />
          );
        })}
        <XAxis dataKey="name" tickLine={false} minTickGap={20} tickSize={3} />
        <YAxis type="number" tickLine={false} allowDecimals={false} includeHidden />
        <CartesianGrid strokeDasharray={'3 3'} />
        <Tooltip
          contentStyle={{ background: '#333', color: '#fff' }}
          labelStyle={{ fontSize: '13px' }}
          itemStyle={{ color: '#fff', fontSize: '15px' }}
          wrapperStyle={{
            width: 'max-width',
            height: 'max-height',
            textAlign: 'center',
          }}
          active={true}
          formatter={(value, name) => [value, intl.formatMessage({ id: 'billing.' + name })]}
        />
        <ReferenceLine x="G" stroke="blue" label="G " strokeDasharray="1 4" />
      </AreaChart>
    </GraphBlockWrapper>
  );
}

export default function Graphs({ dataChart, checkPlanReportsPermissions, isVersion2 }) {
  const [screenSize, setScreenSize] = useState(null);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <div className="row statistics-inner-block">
      <CreditsHistory
        data={dataChart}
        screenSize={screenSize}
        checkPlanReportsPermissions={checkPlanReportsPermissions}
        isVersion2={isVersion2}
      />
    </div>
  );
}

function GraphBlockWrapper({ type, children, title, blockRef }) {
  return (
    <div
      className={
        'credits-history ' + (type === 'history' ? ' history-width col-md-11' : 'col-md-3')
      }
      ref={blockRef}
    >
      <div className="info-card">
        <div className="info-card-content graph-block" style={{ paddingTop: 0 }}>
          {children}
        </div>
      </div>
    </div>
  );
}
