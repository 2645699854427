import React, { useContext, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ModalMessage, Permissions, Tooltip } from '../../../../../components';
import { deleteTask, searchTask, updateTask } from '../../../../../services/api/leads.api';
import { actions, LeadsContext } from '../../../store/leads.store';
import AddTaskModal from '../AddTaskModal/AddTaskModal';
import { OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
function TasksList({ tasks, organization, memberPermission, planPermission }) {
  const [taskToDelete, setTaskToDelete] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(false);
  const { state, dispatch } = useContext(LeadsContext);
  const intl = useIntl();
  const handleActions = async ({ type, task, isCompleted }) => {
    if (!memberPermission) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }
    if (!planPermission) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    switch (type) {
      case 'delete':
        setTaskToDelete(task);
        break;
      case 'edit':
        setTaskToEdit(task);
        break;
      case 'completeTask':
        task.isCompleted = isCompleted;
        const { data, statusCode } = await updateTask(task);
        if (statusCode == 200) {
          setTaskToEdit(null);
          const { statusCode: tasksStatus, data: tasksData } = await searchTask();
          if (tasksStatus == 200) {
            dispatch({ type: actions.GET_TASKS, payload: tasksData });
          }
        }
        break;
    }
  };
  const handleDeleteTask = async () => {
    const { data, statusCode } = await deleteTask({ _id: taskToDelete._id });
    if (statusCode == 200) {
      setTaskToDelete(null);
      const { statusCode: tasksStatus, data: tasksData } = await searchTask();
      if (tasksStatus == 200) {
        dispatch({ type: actions.GET_TASKS, payload: tasksData });
      }
    }
  };
  const handleEditTask = async (task) => {
    const { data, statusCode } = await updateTask(task);
    if (statusCode == 200) {
      setTaskToEdit(null);
      const { statusCode: tasksStatus, data: tasksData } = await searchTask();
      if (tasksStatus == 200) {
        dispatch({ type: actions.GET_TASKS, payload: tasksData });
      }
    }
    return true;
  };
  return (
    <div className="table-content task-table">
      <table width="100%">
        <thead>
          <tr>
            <th>
              <FormattedMessage id={'leads.tasks.status'} />
            </th>
            <th>
              <FormattedMessage id={'leads.tasks.title'} />
            </th>
            <th>
              <FormattedMessage id={'leads.tasks.type'} />
            </th>
            <th>
              <FormattedMessage id={'leads.tasks.assignedTo'} />
            </th>
            <th>
              <FormattedMessage id={'leads.tasks.dueOn'} />
            </th>

            <th>
              <FormattedMessage id={'leads.tasks.attachedTo'} />
            </th>
            <th>
              <FormattedMessage id={'leads.note'} />
            </th>
            <th>
              <FormattedMessage id={'leads.tasks.actions'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, i) => (
            <TasksItem
              key={i}
              task={task}
              onActionsClick={handleActions}
              planPermission={planPermission}
              memberPermission={memberPermission}
            />
          ))}
        </tbody>
      </table>
      {taskToDelete && (
        <ModalMessage
          toggle={!!taskToDelete}
          setToggle={setTaskToDelete}
          title={intl.formatMessage({ id: 'leads.tasks.deleteTask' })}
          textElement={intl.formatMessage({
            id: 'leads.tasks.areYouSureYouWantToDeleteThisTask',
          })}
          action={intl.formatMessage({ id: 'confirm' })}
          handleAction={handleDeleteTask}
        />
      )}
      {taskToEdit && (
        <AddTaskModal
          show={!!taskToEdit}
          setToggle={setTaskToEdit}
          task={taskToEdit}
          organization={organization}
          onCreate={handleEditTask}
        />
      )}
    </div>
  );
}

function TasksItem({ task, onActionsClick, planPermission, memberPermission }) {
  const intl = useIntl();
  const CheckDate = ({ date, isCompleted }) => {
    let timestamp = new Date(date).getTime();

    if (Date.now() > timestamp && !isCompleted) {
      return (
        <div className={'due-tag overdue'}>
          <FormattedMessage id={'leads.tasks.overdue'} /> {' - '}
          <FormattedDate value={task.due} year={'numeric'} month={'short'} day={'numeric'} />
        </div>
      );
    } else {
      return (
        <FormattedDate
          value={task.due}
          year={'numeric'}
          month={'short'}
          day={'numeric'}
          hour={'numeric'}
          minute={'numeric'}
        />
      );
    }
  };
  return (
    <tr className={'cstm-selectepicker task-overview'} style={{ cursor: 'default' }}>
      {/*
      <td>{task.isCompleted ? "done" : "pending"} </td>
*/}
      <td>
        <figure className="status-ui">
          {!planPermission || !memberPermission ? (
            <Permissions.CallToAction
              isPlan={!planPermission}
              withoutIcon={true}
              toolTipStyle={{ width: 200, top: -5, left: '-100%' }}
              toolTipPosition={'right'}
            >
              {task.isCompleted ? (
                <span
                  className="completed"
                  onClick={() =>
                    onActionsClick({
                      type: 'completeTask',
                      task,
                      isCompleted: false,
                    })
                  }
                ></span>
              ) : (
                <span
                  className="ic-status"
                  onClick={() =>
                    onActionsClick({
                      type: 'completeTask',
                      task,
                      isCompleted: true,
                    })
                  }
                ></span>
              )}
            </Permissions.CallToAction>
          ) : task.isCompleted ? (
            <OverlayTrigger
              key={'completed-task'}
              placement={'top'}
              overlay={(props) =>
                Tooltip({
                  ...props,
                  data: intl.formatMessage({
                    id: 'leads.tasks.markTaskAsNotCompleted',
                  }),
                })
              }
            >
              <span
                className="completed"
                onClick={() =>
                  onActionsClick({
                    type: 'completeTask',
                    task,
                    isCompleted: false,
                  })
                }
              ></span>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              key={'complete-task'}
              placement={'top'}
              overlay={(props) =>
                Tooltip({
                  ...props,
                  data: intl.formatMessage({
                    id: 'leads.tasks.markTaskAsCompleted',
                  }),
                })
              }
            >
              <span
                className="ic-status"
                onClick={() =>
                  onActionsClick({
                    type: 'completeTask',
                    task,
                    isCompleted: true,
                  })
                }
              ></span>
            </OverlayTrigger>
          )}
        </figure>
      </td>
      <td>{task.title} </td>
      <td className={'type-tag'}>
        <span className={' text-uppercase ' + task.type + '-tag '}>
          {<FormattedMessage id={'leads.tasks.' + task.type} />}
        </span>
      </td>
      <td>
        <div>
          {task.assignee && task.assignee.memberId
            ? task.assignee.memberId.firstName + ' ' + task.assignee.memberId.lastName
            : '--'}
        </div>{' '}
        <div>
          <span>{task.assignee && task.assignee.email}</span>{' '}
        </div>{' '}
      </td>
      <td>
        <CheckDate date={task.due} isCompleted={task.isCompleted} />
      </td>

      <td>
        <div className={'username view-profile'}>
          <a
            onClick={(e) => e.stopPropagation()}
            href={'http://linkedin.com/in/' + task.leadId.linkedin}
            target={'_blank'}
            rel="noopener noreferrer nofollow"
          >
            {task.leadId.firstName + ' ' + task.leadId.lastName}{' '}
            <img src="/assets/images/link_icon.svg" alt="" />
          </a>{' '}
        </div>
        <p>
          <span>{task.leadId.currentProEmail || task.leadId.currentPersonalEmail}</span>
        </p>
      </td>
      <td>
        {task.description ? (
          <div>
            {task.description.length > 20 ? (
              <OverlayTrigger
                key={'item-desc'}
                placement={'top'}
                overlay={(props) =>
                  Tooltip({
                    ...props,
                    data: <div style={{ fontSize: 15, textAlign: 'left' }}>{task.description}</div>,
                  })
                }
              >
                <div>{task.description.substring(0, 20) + '...'}</div>
              </OverlayTrigger>
            ) : (
              task.description
            )}
          </div>
        ) : (
          <div>--</div>
        )}
      </td>

      <td className={'edit-delete-container'}>
        {!planPermission || !memberPermission ? (
          <Permissions.CallToAction
            isPlan={!planPermission}
            withoutIcon={true}
            toolTipStyle={{ width: 200, top: -10 }}
            toolTipPosition={'left'}
          >
            <span
              onClick={() => onActionsClick({ type: 'edit', task })}
              style={{ cursor: 'pointer' }}
              className={'edit-delete'}
            >
              <img src="/assets/images/ic_edit_g.png" alt="" />
            </span>
          </Permissions.CallToAction>
        ) : (
          <span
            onClick={() => onActionsClick({ type: 'edit', task })}
            style={{ cursor: 'pointer' }}
            className={'edit-delete'}
          >
            <img src="/assets/images/ic_edit_g.png" alt="" />
          </span>
        )}

        {!planPermission || !memberPermission ? (
          <Permissions.CallToAction
            isPlan={!planPermission}
            withoutIcon={true}
            toolTipStyle={{ width: 200, top: -10 }}
            toolTipPosition={'left'}
          >
            <span
              onClick={() => onActionsClick({ type: 'delete', task })}
              style={{ cursor: 'pointer' }}
              className={'edit-delete'}
            >
              <img src="/assets/images/icon-delete.svg" alt="" />
            </span>
          </Permissions.CallToAction>
        ) : (
          <span
            onClick={() => onActionsClick({ type: 'delete', task })}
            style={{ cursor: 'pointer' }}
            className={'edit-delete'}
          >
            <img src="/assets/images/icon-delete.svg" alt="" />
          </span>
        )}

        {/*<span
          onClick={() => onActionsClick({ type: "delete", task })}
          style={{ cursor: "pointer" }}
          className={'edit-delete'}
        >
          <img src="/assets/images/icon-delete.svg" alt="" />
        </span>*/}
      </td>
    </tr>
  );
}
export default TasksList;
