import React from 'react';
import IntegrationHeader from './IntegrationHeader/IntegrationHeader';
import AvailableIntegrations from './AvailableIntegrations/AvailableIntegrations';

export default function Integration({ profile }) {
  return (
    <div id="integration">
      <IntegrationHeader />
      <AvailableIntegrations profile={profile} />
    </div>
  );
}
