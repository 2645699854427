import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalMessage } from '../../../../components';
import './ChooseAdmins.css';
import { getOrgAdmins } from '../../../../services/api/organization.api';
export default function ChooseAdmins({
  setToggle,
  onChange,
  onSubmit,
  selectedAdmins,
  selectedPlan,
  error,
  setError,
  setOrgAdmins,
  orgAdmins,
  isUnpaid,
}) {
  /*
  const [error, setError] = useState(error);
*/

  const onChooseAdmins = (e) => {
    let currentAdmins = [...orgAdmins];
    const numberOfSelectedAdmins = currentAdmins.filter((admin) => admin.checked).length;
    currentAdmins = orgAdmins.map((admin) => {
      if (admin.email == e.target.id) {
        admin.checked = numberOfSelectedAdmins < selectedPlan.maxAdmins ? e.target.checked : false;
      }
      return admin;
    });
    setOrgAdmins(currentAdmins);
    onChange(currentAdmins.filter((admin) => admin.checked).map((admin) => admin.email));
  };
  const handleSubmit = () => {
    if (!selectedAdmins || (selectedAdmins && selectedAdmins.length == 0))
      return setError('You have to choose one or more admins ...');
    onSubmit();
  };
  return (
    <ModalMessage
      toggle={true}
      setToggle={() => {
        setToggle(false);
      }}
      title={'Select admin(s)'}
      textElement={
        <div className={'choose-admins-container'}>
          <br />
          <strong>
            You can choose{' '}
            <span style={{ color: 'darkred' }}>{selectedPlan.maxAdmins} ADMIN(S) </span> for this
            plan
          </strong>
          <div className={'admins-list'}>
            {orgAdmins.map((admin) => (
              <div className="checkbox-wrap">
                <label htmlFor={admin.email}>
                  <input
                    type="checkbox"
                    id={admin.email}
                    onChange={onChooseAdmins}
                    checked={admin.checked}
                  />
                  <span className="checkbox-icon" />
                  <span className="checkbox-text">{admin.email}</span>
                </label>
              </div>
            ))}
          </div>

          <div
            style={{
              color: 'red',
              fontSize: 15,
              textAlign: 'center',
              marginTop: 20,
            }}
          >
            {error}
          </div>
        </div>
      }
      action={<FormattedMessage id={'billing.proceed'} />}
      disabled={{
        yes: !!!orgAdmins.filter((admin) => admin.checked).length,
      }}
      handleAction={handleSubmit}
    />
  );
}
